import { Component, EventEmitter, inject, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, RouterLinkActive, RouterLink } from "@angular/router";
import { KeycloakEvent, KeycloakEventType } from 'keycloak-angular';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { lmsMenuItem } from 'src/app/core/models/menuitem.model';
//import { SwitchModeService } from 'src/app/core/services/switch-mode.service';
import { UserService } from 'src/app/core/services/lmsuser.service';
import { PostcodesService } from 'src/app/core/services/postcodes.service';
import packageJson from 'package.json';
import { LMSPageTitleService } from 'src/app/core/services/lmspage-headertitle.service';
import { KeycloakProfile } from 'keycloak-js';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { ApiloaderComponent } from '../shared/loaders/apiloader/apiloader.component';
import { MdbPopoverModule } from 'mdb-angular-ui-kit/popover';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css'],
    standalone: true,
    imports: [MdbCollapseModule, NgIf, NgFor, RouterLinkActive, RouterLink, MdbDropdownModule, MdbPopoverModule, NgClass, ApiloaderComponent, AsyncPipe]
})
export class NavbarComponent implements OnInit,OnDestroy {
  private userService: UserService = inject(UserService);
  private authService: AuthenticationService = inject(AuthenticationService);
  isDark = false;
  public version: string = packageJson.version;
  environmentName: string;
  options: FormGroup;

  private OnUserEvent = this.userService.UserhasEvent$;
  public isLoggedIn = this.userService.isUserLoggedin$;
  userMenuList$ = this.userService.menusControl$;

  menus: lmsMenuItem[] = [];
  menuItems: any[];

  allMenus: any;
  allSubMenus: any;
  useractioncolor = 3;
  usertoken: any;
  DisplayedUsername = this.userService.userDisplayName || '';
  userBranchName = this.userService.userBranchName || '';

  @Output() onLogginChanged = new EventEmitter<boolean>();

  constructor(private router: Router,
    fb: FormBuilder,
    //private storageService: StorageService,
    private postcodeService: PostcodesService,
    private lmsPageTitleService: LMSPageTitleService) {

      // this.userService = inject(UserService);
      this.isLoggedIn.subscribe(loggedin =>{
        if(this.onLogginChanged!!){
          this.onLogginChanged.emit(loggedin);
        }
      })

      this.OnUserEvent.subscribe((kevent:KeycloakEvent)=>{
        if(kevent.type == KeycloakEventType.OnAuthSuccess) {
          this.userService.getLMSUserProfile(kevent.args as KeycloakProfile);
          this.useractioncolor = 4;
        }
        if(kevent.type == KeycloakEventType.OnReady) {
          this.useractioncolor = 5;
          this.usertoken = `Token: ${localStorage.getItem('expires_at')||''}`;
          //this.getLMSUserProfile(evt.args as KeycloakProfile);
        }
        if(kevent.type == KeycloakEventType.OnAuthRefreshSuccess) {
          this.useractioncolor = 3;
          this.usertoken = `Token refreshed: ${localStorage.getItem('expires_at')||''}`;
          //this.authService.login();
        }
        if(kevent.type == KeycloakEventType.OnTokenExpired) {
          this.useractioncolor = 6;
          //this.global_utility.warning('User session expired. Token to be refreshed, please wait.', 'User token expired');
          //this.getLMSUserProfile(evt.args as KeycloakProfile);
        }
        if(kevent.type == KeycloakEventType.OnActionUpdate) {
          this.useractioncolor = 7;
          //this.global_utility.warning('User action Update.', 'User action update');
          //this.getLMSUserProfile(evt.args as KeycloakProfile);
        }
      })


    this.options = fb.group({
      'fixed': true,
      'top': 0,
      'bottom': 0,
    });
  }

  subscriptions: Subscription[] = [];
  ngOnInit(): void {

    // const subs = this.lmsPageTitleService.PageTitleText.subscribe(
    //   pagename => this.PageName = pagename);

    // this.subscriptions.push(subs);



    this.environmentName = environment.name;
    //If User is not logged in then do Login
    //********  OR
    //Current User and user in Session is different - UnAUth
    if (!this.userService.isUserLoggedinValue) {
      //this.userService.getLMSUserProfile();
      //this.userService.onLMSUserProfile();
    }
    //this.userService.onLMSUserProfile();

    //this.userService.onLMSUserProfile();
  }

  navigate(route: string) {
    console.log(route);
    this.router.navigate([route]);
  }


  changeMode() {
    this.isDark = !this.isDark;

    //this.switchMode.handleSwitchMode(this.isDark);
  }

  logout() {
    this.router.navigate(['/']).then(() => {
      this.userService.logOutUser();
    });
  }

  get userDisplay() {
    this.DisplayedUsername = this.userService.userDisplayName;
    return this.DisplayedUsername;
    //return `${this.userService.userDisplayName || this.DisplayedUsername}`;
  }

  onUserMenusValue = () => {
    this.menuItems = this.userService.CurrentUserMenus;
  };

  hasSubmenu(item: any) {
    return item.submenus && item.submenus.length > 0;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => {
      x.unsubscribe();
    });
  }
}
