import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'zaRcurrency',
    standalone: true
})

export class ZARcurrencyPipe implements PipeTransform {
  transform(
    value: string | number | 0,
    currencyCode: string = 'R ',
    digitsInfo: string = '1.2-2',
    locale: string = 'za',
  ): string {
    return formatCurrency(
      Number(value),
      locale,
      getCurrencySymbol(currencyCode, 'wide'),
      currencyCode,
      digitsInfo,
    );
  }
}
