<div class="d-flex d-md-flex flex-row flex-grow-1">
  <div class="card me-auto ms-auto" [style.width.px]="730">
    <div class="card-body pt-1 py-2">


    <div *ngIf="currentFile" class="progress">
        <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
            attr.aria-valuenow="{{ progress }}" aria-valuemin="0" aria-valuemax="100"
            [ngStyle]="{ width: progress + '%' }">
            {{ progress }}%
        </div>
    </div>

    <label class="btn btn-default">
        <input type="file" (change)="selectFile($event)" />
    </label>


    <button mat-raised-button class="btn btn-outline-primary me-2" [disabled]="!selectedFiles" (click)="upload()">
        Upload
    </button>

    <div class="alert alert-light" role="alert">{{ message }}</div>

    <div class="card">
        <div class="card-header">List of Files</div>
        <ul class="list-group list-group-flush">
            <li *ngFor="let file of fileInfos | async" class="list-group-item">
                <a href="{{ file.url }}">{{ file.name }}</a>
            </li>
        </ul>
    </div>
      <div class="card-footer center-screen">
      <button mat-raised-button class="btn btn-primary btn-rounded me-2" mdbRipple color="primary"
        (click)="onDismiss()">Close</button>
      </div>
    </div>
  </div>
</div>
