<div matDialogTitle class="dialog-title">

  <app-page-cardhead [PageName]="'Loan Audit Trail'" align="end" [PageHeadWidth]="800"></app-page-cardhead>

  <button class="btn  pull-right" aria-label="close dialog" mat-dialog-close >
    <i style="color:red;" color="warn" class="fas fa-fw fa-close"></i>
</button>
</div>
   <div fxLayout="row" class="ms-auto my-1 p-md-2 p-lg-3 p-1" fxLayoutAlign="start start" fxLayoutGap="10px">
    <div *ngIf="isauditLoading" class="fade show my-2" style="text-align: center; height: 30px">
      <i class="spinner-grow spinner-grow-sm"></i>
      <span class="me-2">Loading quote logs, please wait!</span>
    </div>
    <ng-container *ngIf="!isauditLoading">
    <table  mat-table [dataSource]="table_data_source!" class="table table-striped  table-hover table-sm mb-1 caption-top" color="primary">
          <ng-container  *ngFor="let column of table_config.columns; index as i" [matColumnDef]="column.key">
            <th mat-header-cell *matHeaderCellDef [ngClass]="{'numeric-col': !!column.numeric}">{{column.heading}}</th>
            <td mat-cell *matCellDef="let table_row"
            [ngClass]="{'numeric-col': !!column.numeric}"
            [ngClass]="{'dataval-col': column.key === 'newData' || column.key === 'previousData'}"
            [ngClass]="{'date-col': !!column.datecol}">

              <ng-container  *ngIf="!!column.datecol" >
                <span class="date-col" style="width: 180px;">
                    <div class="d-flex align-items-center flex-lg-wrap">
                      <p class="text-muted font-weight-bold">{{table_row[column.key] | date: 'yyyy-MM-dd HH:mm:ss'}}</p>
                    </div>
                    <div class="">{{table_row['userName']}}</div>
                    <div class="font-weight-bold d-flex justify-content-end align-content-end">{{table_row['action']}}</div>
                </span>
              </ng-container>

              <ng-container *ngIf="column.key === 'newData'">
                <!-- <span class="dataval-col"><pre><div [innerHTML]="table_row[column.key]"></div></pre></span> -->
                <div class="note note-primary d-flex p-2">
                  <span>
                    <div class="font-weight-bold">New: </div>
                    {{table_row[column.key]}}
                  </span>
                  </div>
              </ng-container>
              <ng-container *ngIf="column.key === 'previousData'">
                <!-- <span class="dataval-col"><pre><div [innerHTML]="table_row[column.key]"></div></pre></span> -->
                <div class="note note-warning d-flex p-1" >
                  <span>
                  <div class="font-weight-bold">Previous: </div>
                    {{table_row[column.key]}}
                </span>
                </div>
              </ng-container>

            </td>
          </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayed_columns" class="table-center-header"></tr>
        <tr mat-row *matRowDef="let row; columns: displayed_columns; let i = index;">
        </tr>
      </table>
      <div class="lms-pager mt-2">
        <div *ngIf="pager.pages && pager.pages?.length && pager.pages?.length > 1" class="btn-toolbar" role="toolbar" aria-label="LMS Pager group">
          <div class="btn-group btn-group-sm" role="group" aria-label="Move Previous group">
            <button type="button" class="btn btn-primary  rounded-start" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5" (click)="setPage(1)" [disabled]="pager.currentPage === 1">First</button>
            <button type="button" class="btn btn-primary  rounded-0" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5"(click)="setPage(pager.currentPage - 1)" [disabled]="pager.currentPage === 1">Previous</button>
          </div>
          <div class="btn-group btn-group-sm" role="group" aria-label="PageNumbers">
            <button *ngFor="let page of pager.pages" type="button" data-mdb-ripple-color="#7a7676" style="background-color:#edf2fa; color: #7a7676;" [ngClass]="{active:pager.currentPage === page}" (click)="setPage(page)" class="btn btn-outline-secondary page-item number-item  rounded-0">{{page}}</button>
          </div>
          <div class="btn-group btn-group-sm" role="group" aria-label="Move Next group">
            <button type="button" class="btn btn-primary rounded-0" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5" (click)="setPage(pager.currentPage + 1)" [disabled]="pager.currentPage === pager.totalPages">Next</button>
            <button type="button" class="btn btn-primary rounded-end" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5" (click)="setPage(pager.totalPages)" [disabled]="pager.currentPage === pager.totalPages">Last</button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
