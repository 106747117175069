import { Component, Inject, OnInit } from '@angular/core';
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MatButtonModule } from '@angular/material/button';
import { NgFor, NgIf } from '@angular/common';
import { PageCardheadComponent } from '../shared/ui/pageheader-card/pageheader-card.component';

@Component({
    selector: 'app-offer-values',
    templateUrl: './offer-values.component.html',
    styleUrls: ['./offer-values.component.css'],
    standalone: true,
    imports: [PageCardheadComponent, NgFor, NgIf, MatButtonModule, MdbRippleModule]
})
export class OfferValuesComponent implements OnInit {
  compuscanOffers: any;

  constructor(public modalRef: MdbModalRef<OfferValuesComponent>) { }

  ngOnInit(): void {
  }

  close() {
    this.modalRef.close();
  }
}
