<app-page-cardhead [PageName]="'Money Laundering Scorecard'" [PageHeadWidth]=600></app-page-cardhead>

<form [formGroup]="editRiskScoreForm" novalidate>
  <div class="center-screen">
    <table class="table table-striped table-light table-hover table-sm mb-3 caption-top" style="width:400px">
      <caption>Edit Risk Score</caption>
      <tbody>
        <tr align="left">
          <td style="width: 25%;">Parameter Type</td>
          <td style="width: 25%;">
            <input formControlName="parameterType" style="width: 230px; height: 26px;"
              [(ngModel)]="riskScoreModel.parameterType" required>
            <div *ngIf="validateRiskScoreValues.parameterType.errors?.required && submitted" class="error-label">
              Parameter Type is required
            </div>
          </td>
        </tr>
        <tr align="left">
          <td style="width: 25%;">Description</td>
          <td style="width: 25%;">
            <!--  (keypress)="($event.charCode <= 93 && $event.charCode >= 65 || $event.charCode == 32) || ($event.charCode <= 122 && $event.charCode >= 97)" -->
            <input formControlName="description" style="width: 230px;" [(ngModel)]="riskScoreModel.description"
              (keypress)="omit_special_char($event)"
              required>
            <div *ngIf="validateRiskScoreValues.description.errors?.required && submitted" class="error-label">
              Description is required
            </div>
          </td>

        </tr>
        <tr align="left">
          <td>Score</td>
          <td>
            <!-- (keypress)="($event.charCode >= 48 && $event.charCode < 58) || ($event.charCode >= 44 && $event.charCode < 45) || ($event.charCode >= 46 && $event.charCode < 47)" -->
            <input formControlName="score" type="number" style="width: 230px;" [(ngModel)]="riskScoreModel.score"
              required>
            <div *ngIf="validateRiskScoreValues.score.errors?.required && submitted" class="error-label">
              Score is required
            </div>
          </td>


          <!-- <td>Managed Engine ID</td>
                    <td>
                        <input formControlName="manageEngineId" style="width: 230px;"
                            placeholder="Manage Engine ID *"
                            [(ngModel)]="riskScoreModel.manageEngineId"
                            (keypress)="($event.charCode >= 48 && $event.charCode < 58)" required>
                        <div *ngIf="validateRiskScoreValues.manageEngineId.errors?.required && submitted"
                             class="error-label">
                            Managed Engine ID is required
                        </div>
                    </td> -->
        </tr>
      </tbody>
    </table>
    <br>
    <button mat-raised-button class="btn btn-primary btn-rounded" mdbRipple color="primary" (click)="close()">Close</button>
    &nbsp;
    <button mat-raised-button class="btn btn-primary btn-rounded" mdbRipple color="primary" (click)="saveRiskScore()">Save</button>
  </div>
</form>
