/**
* LMS ClientApp Date adapter
*/
import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable({
  providedIn: 'root',
})
export class AppDateAdapter extends NativeDateAdapter {

  override format(date: Date, displayFormat: Object): string {

    if (displayFormat === 'input') {

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${year}-${month}-${day}`;
    }

    return date.toDateString();
  }
}

export const LMS_DATE_FORMATS =
{
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};


// @Injectable()
// export class NgbDateDayjsAdapter extends NgbDateAdapter<dayjs.Dayjs> {
//  fromModel(date: dayjs.Dayjs | null): NgbDateStruct | null {
//    if (date && dayjs.isDayjs(date) && date.isValid()) {
//      return { year: date.year(), month: date.month() + 1, day: date.date() };
//    }
//    return null;
//  }

//  toModel(date: NgbDateStruct | null): dayjs.Dayjs | null {
//    return date ? dayjs(`${date.year}-${date.month}-${date.day}`) : null;
//  }
// }
