/* eslint-disable no-console */
import 'hammerjs';
import { enableProdMode, APP_INITIALIZER, LOCALE_ID, importProvidersFrom } from "@angular/core";
import { DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData } from '@angular/common';
import localeZa from '@angular/common/locales/en-ZA';

import 'zone.js';
import { environment } from "./environments/environment";
import { AppComponent } from './app/app.component';
import { NgxPaginationModule } from 'ngx-pagination';

import { ToastrModule } from 'ngx-toastr';
import { MaterialModule } from 'src/app/material.module';
import { MdbUiKitModule } from 'src/app/mdb-ui-kit.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { KeycloakAngularModule } from 'keycloak-angular';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LMSSharedUiModule } from 'src/app/shared/ui/shared-ui.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
registerLocaleData(localeZa, 'za');

import { QuoteStoreService, ClientDetailsService } from '@lmsrestServices';
//import { LMSDialogHandlerService } from './app/shared/lmsdialog-handler.service';
//import { DragDropModule } from '@angular/cdk/drag-drop';
import { MenusModule } from './app/modules/menus/menus.module';
//import { NgxCaptureModule } from 'ngx-capture';
import { RatesAndFeesModule } from './app/modules/rates-and-fees/rates-and-fees.module';
import { ClientSearchModule } from 'src/app/client-search/client-search.module';
import { RegionsAdminModule } from 'src/app/modules/regions-admin/regions-admin.module';
import { BranchEmployersModule } from 'src/app/features/branch-employers/branch-employers.module';
import { AppDateAdapter, LMS_DATE_FORMATS } from 'src/app/core/constants/datepicker-adapter';
import { DateAdapter, MAT_NATIVE_DATE_FORMATS, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { LoaderInterceptor } from 'src/app/core/interceptors/http.interceptor';
import { HttpErrorInterceptor } from 'src/app/core/interceptors/http-error.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { initializeKeycloak } from 'src/app/core/init/keycloak-init.factory';
import { ApiloaderService } from 'src/app/shared/loaders/apiloader/apiloader.service';
import { LMSDialogHandlerService } from './app/shared/lmsdialog-handler.service';

const MATERIAL_IMPORTS = [
  BrowserAnimationsModule,
  MatToolbarModule,
  MatDialogModule,
  MatIconModule,
  MatButtonModule,
  MatStepperModule
];

if (environment.production) {
  enableProdMode();
}

/*
* Angular official Documentation Reference:

  NB: Please read before changing this module
  https://v15.angular.io/guide/standalone-migration#switch-to-standalone-bootstrapping-api
*/

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, AppRoutingModule, ...MATERIAL_IMPORTS,
      KeycloakAngularModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
          registrationStrategy: 'registerWhenStable:20000',
          enabled: true
      }), FormsModule, ReactiveFormsModule,
      MdbUiKitModule,
      MaterialModule.forRoot(),
      LMSSharedUiModule.forRoot(),
      ToastrModule.forRoot({
          timeOut: 5000,
          positionClass: 'toast-top-center',
          preventDuplicates: true,
          maxOpened: 5,
          newestOnTop: true
      }), NgxPaginationModule,
      LMSDialogHandlerService,
      BranchEmployersModule,
      RegionsAdminModule,
      // RegionalManagerModule,
      ClientSearchModule,
      RatesAndFeesModule,
      MenusModule
    ),
    { provide: ApiloaderService, useClass: ApiloaderService },
    {
        provide: APP_INITIALIZER,
        useFactory: initializeKeycloak,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpErrorInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: LoaderInterceptor,
        multi: true,
        deps: [ApiloaderService]
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true, disableClose: true } },
    { provide: LOCALE_ID, useValue: 'za' },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: 'yyyy-MM-dd' } },
    { provide: MAT_NATIVE_DATE_FORMATS, useValue: LMS_DATE_FORMATS },
    { provide: MAT_DATE_FORMATS, useValue: LMS_DATE_FORMATS },
    QuoteStoreService,
    ClientDetailsService,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
  .catch(err => console.error(err));
