import { ChangeDetectionStrategy, Component, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { LMSPageHeadComponent } from 'src/app/core/guard/LMSPageHeader.guard';
import { LMSPageTitleService } from 'src/app/core/services/lmspage-headertitle.service';

@Component({
  selector: 'app-page-cardhead',
  templateUrl: './pageheader-card.component.html',
  styleUrls: ['./pageheader-card.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports:[

    ]
})
export class PageCardheadComponent implements OnInit {
  @Input() PageName!: string;

  @Input() PageHeadWidth = 650;
  @Input() ribbonIdx = true;

  PageHeadLess: number;

  ngOnInit(): void {
    this.PageHeadLess = this.PageHeadWidth - 35;
  }
}
