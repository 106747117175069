import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BiometricsSerivce {
  biometricsParameters: any;

  HttpParamsOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) {
  }


  getBiometricsParameters() {
    return this.biometricsParameters;
  }

  setBiometricsParameters(data: any) {
    this.biometricsParameters = data;
  }

  enrollClientFinger(data: any) {
    return this.http
      .post<any>(environment.apiBioUrl + '/biometric/api/enroll-finger', data)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }

  verifyClientFinger(data: any) {
    return this.http
      .post<any>(environment.apiBioUrl + '/biometric/api/verify-finger', data)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }

  verifyUserFinger(data: any) {
    return this.http
      .post<any>(environment.apiBioUrl + '/biometric/user-api/verify-finger', data)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }


  enrolledFingersDetails(data: any) {
    return this.http
      .post<any>(environment.apiBioUrl + '/biometric/api/enrolledfinger-details', data)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }

  enrolledSingleFingersDetails(data: any) {
    return this.http
      .post<any>(environment.apiBioUrl + '/biometric/api/enrolledSinglefinger-details', data)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }

  enrolledConsultantSingleFingersDetails(data: any){
    return this.http
      .post<any>(environment.apiBioUrl + '/biometric/user-api/enrolledConsultantSinglefinger-details', data)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }

  disableFinger(data: any) {
    return this.http
      .post<any>(environment.apiBioUrl + '/biometric/api/disable-finger', data)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }

  enrollUserFinger(data: any) {
    return this.http
      .post<any>(environment.apiBioUrl + '/biometric/user-api/enroll-finger', data)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }

  enrolledUserFingersDetails(data: any) {
    return this.http
      .post<any>(environment.apiBioUrl + '/biometric/user-api/enrolledfinger-details', data)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }


  disableUserFinger(data: any) {
    return this.http
      .post<any>(environment.apiBioUrl + '/biometric/user-api/disable-finger', data)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }


  disableAllClientFingers(data: any) {
    return this.http
      .post<any>(environment.apiBioUrl + '/biometric/api/disable-allFingers', data)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }

  disableAllUserFingers(data: any) {
    return this.http
      .post<any>(environment.apiBioUrl + '/biometric/user-api/disable-allFingers', data)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }

  saveBiometrics() {
    this.http.
      get<any>(environment.apiBioUrl + '/biometric/api/is-available').subscribe(data => {
        return data;
      });
  }

  skipBiometrics(data: any) {
    return this.http
      .post<any>(environment.apiBioUrl + '/biometric/api/skip-biometrics', data)
      .pipe(
        map((responseData: any) => {
          return responseData;
        })
      );
  }



}


