import { Component, OnInit } from "@angular/core";
import { ApiloaderService } from "src/app/shared/loaders/apiloader/apiloader.service";

@Component({
  selector: "app-apiloader",
  templateUrl: "./apiloader.component.html",
  styleUrls: ["./apiloader.component.css"],
  standalone: true
})
export class ApiloaderComponent implements OnInit {
  loading: boolean;
  isOffline: boolean;
  apiOffline: boolean;

  constructor(private loaderService: ApiloaderService) {
    this.loaderService.isLoading.subscribe((v) => {
      // console.log(`loadingsub:${v}`);
      this.loading = v;
    });

    this.loaderService.isOffline.subscribe((ofl) => {
      this.isOffline = ofl;
    });

    this.loaderService.isAPIOffline.subscribe((ofl) => {
      this.apiOffline = ofl;
    });
  }

  ngOnInit() { }
}
