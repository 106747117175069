import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from "mdb-angular-ui-kit/modal";

import { LMSSharedUiModule } from "@lmsSharedUI";
import { AppUtilityService } from "src/app/app-utility.service";
import { ClientShowModel } from "src/app/core/models/client-show.model";
import { LoanDocModel } from 'src/app/core/models/loanDoc.model';
import { ClientDetailsService } from 'src/app/core/rest-services/client-details.service';
import { QuoteStoreService } from 'src/app/core/rest-services/quote-store.service';
import { LMSDialogHandlerService } from 'src/app/shared/lmsdialog-handler.service';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-client-documents',
  templateUrl: './client-documents.component.html',
  styleUrls: ['./client-documents.component.css'],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    PdfViewerModule,
    LMSSharedUiModule
],
  standalone: true
})
export class ClientDocumentsComponent implements OnInit {
  lstDocuments: Array<any> = [];
  quoteId: string;
  loanRef: string;
  loanDoc: LoanDocModel = new LoanDocModel();
  curr: any;
  downloadObject: any = {};
  finalFileName: any;
  downloadResults: any = {};
  isReadOnly = false;
  isDocumentLoading = false;
  clientShow: ClientShowModel;
  //PAGINATOR CODE
  // @Input() items: Array<any>;
  // @Output() changePage = new EventEmitter<any>(true);
  // @Input() initialPage = 1;
  // @Input() pageSize = 15;
  // @Input() maxPages = 15;

  // pager: any = {};

  constructor(
    public quoteStoreService: QuoteStoreService,
    public global_utility: AppUtilityService,
    public lmsDialogHandler:LMSDialogHandlerService,
    public dialogRef: MdbModalRef<ClientDocumentsComponent>,
    private clientDetailsService: ClientDetailsService
  ) { }

  ngOnInit(): void {
    // if(this.readOnly && this.loanRef) {
    //   this.listDocsByLoanRef();
    //   return;
    // }
    this.isDocumentLoading = true;
    this.listClientDocs();
  }

  // listDocsByLoanRef() {
  //   if(this.readOnly && this.loanRef){
  //     this.loanDoc.quoteId = '';
  //     this.loanDoc.loanReference = this.loanRef;
  //   }
  //   this.clientDetailsService.listClientDocsByLoanReference(this.loanDoc)
  //   .subscribe((docList: any) => {
  //       this.lstDocuments = docList;
  //   });
  // }

  listClientDocs() {
    console.log("listClientDocs " + this.quoteId);
    this.loanDoc.quoteId = `Q${this.quoteId}`;

    this.clientDetailsService.listClientDocs(this.loanDoc).subscribe({
      next: (res: any) => {
        this.lstDocuments = res;
        this.isDocumentLoading = false;
      },
      //     for (let doc of this.lstDocuments) {
      //       doc.documentType = doc.documentType.replaceAll("_", " ");
      //     }

      //     this.items = this.lstDocuments;
      //     if (this.items && this.items.length) {
      //       this.setPage(this.initialPage);
      //     }

      //     this.getDocChecklist();
      //     this.spinDialogRef.close();
    });
  }

  onDownloadPdf(data: any) {
    this.curr = JSON.stringify(data);
    this.finalFileName = this.curr.split('"').join('');
    console.log("Curr " + this.finalFileName);

    this.downloadObject.fileName = this.finalFileName,
      this.downloadObject.bucketName = "",
      this.downloadObject.userName = "",
      this.downloadObject.originalFileName = "";

    this.clientDetailsService.download(this.downloadObject).subscribe((res: any) => {
      if(res && res.errorType == 'DOCUMENT_DOWNLOAD_ERROR'){
        this.global_utility.handle_returnMessage(res.message);
        return;
      }

      this.downloadResults = res;
      const base64String = this.downloadResults.documentContent;

      this.openPdfViewer(base64String, this.downloadResults.originalFilename, this.downloadResults.contentType);
    });
  }

  openPdfViewer(base64String: any, fileName: string, contentType: string) {
    const source = `data:` + contentType + `;base64,` + `${base64String}`;
    this.lmsDialogHandler.openDocumentViewer(source, fileName);
  }

  // downloadPdf(base64String: any, fileName: string, contentType: string) {
  //   const source = `data:` + contentType + `;base64,` + `${base64String}`;
  //   const link = document.createElement("a");
  //   link.href = source;
  //   link.download = fileName;
  //   link.click();
  //   //this.finalFileName =null;

  // }

  covertDate(date: any) {
    if (date != null) {
      // eslint-disable-next-line no-var
      var str = date;
      str = str.substring(0, 10);
      date = str;
      return date;
    } else {
      return date;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
