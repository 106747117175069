 <!-- <div class="medium-list">
    <div class="center-screen">
      <img  width="150px" height="150px" src="../../../assets/logo.jpg">
    </div>
    <div class="list-viewer-wrapper">
      <div class="list-header-title">Login</div>

      <form [formGroup]= "loginForm" (ngSubmit)= "loginForm.valid && onLoginSubmit()" novalidate>
      <div class="form-container">

      <mat-form-field>
        <input id="userName"
               matInput
               formControlName= "userName"
               [(ngModel)]= "loginObject.userName"
               placeholder="Enter your user name"
        />
      </mat-form-field>


      <mat-form-field>
        <input id="password"
               [type]= "hide ? 'password':'text'"
               matInput
               formControlName= "password"
               [(ngModel)]= "loginObject.password"
               placeholder="Enter your password"
        />
        <mat-icon matSuffix (click)= "hide=!hide">
          {{ hide ? 'visibility_off' : 'visibility'}}
        </mat-icon>
      </mat-form-field>

      <button mat-raised-button class="btn btn-primary btn-rounded" mdbRipple color="primary" [disabled]= "!loginForm.valid"> Login </button>
      <button mat-raised-button routerLink= "/register" color="primary"> Register </button>
    </div>

  </form>
</div>
    </div>  -->

  <div class="center-screen">
    <img alt="alogo"  width="300px" height="200px" src="../../assets/finbond_logo.png">

      <div class="login-container">
        <div class="login-content">
          <mat-card appearance="outlined">

            <form [formGroup]= "loginForm" (ngSubmit)= "loginForm.valid && onLoginSubmit()" #formDirective="ngForm" >
              <mat-form-field>
                <mat-icon matPrefix>person_outline</mat-icon>
                <input autofocus type="text" placeholder="Username"
                id="userName"
                matInput
                formControlName= "userName"
                [(ngModel)]= "loginObject.userName"
                placeholder="Enter your user name"
                >
              </mat-form-field>

              <mat-form-field>
                <mat-icon matPrefix>lock_outline</mat-icon>
                <input type="password" autocomplete="false" placeholder="Password"
                id="password"
                [type]= "hide ? 'password':'text'"
                matInput
                formControlName= "password"
                [(ngModel)]= "loginObject.password"
                placeholder="Enter your password"
                >
                <mat-icon matSuffix (click)= "hide=!hide">
                  {{ hide ? 'visibility_off' : 'visibility'}}
                </mat-icon>
              </mat-form-field>

              <button type="submit" mat-raised-button class="btn btn-primary btn-rounded" mdbRipple color="primary">LOGIN</button>
            </form>

              <br>
              <span> If you don't have a account yet please <a routerLink ='/register'>REGISTER HERE</a></span>
          </mat-card>
        </div>
      </div>

  </div>
