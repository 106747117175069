<app-page-cardhead
  [PageName]="'Income '+ paySlipModel.month"
  [PageHeadWidth]="500"></app-page-cardhead>
<div class="card m-1">
  <div class="card-body p-2">
    <form [formGroup]="payslipForm">
      <table class="table table-striped table-light table-sm caption-top">
        <tbody class="field-4column-label">
          <tr>
            <td>Pay Date:</td>
            <td>
              <input
                [matDatepicker]="picker"
                [max]="maxDate"
                placeholder="YYYY-MM-DD"
                formControlName="date"
                style="width: 150px;"
                (focus)="picker.open()"
                [(ngModel)]="paySlipModel.date"
                required>
              <!-- (dateChange)="onPayslipDateSelect($event)" -->
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <div *ngIf="validatePayslipInput.date.errors?.required && submitted">
                <small class="text-danger">Pay date is required</small>
              </div>
              <div *ngIf="invalidDate">
                <small class="text-danger">{{payDateError}}</small>
              </div>
            </td>
          </tr>



          <tr>
            <td>Nett Income:</td>
            <td>
              <input
                placeholder="NETT Income"
                numbersOnly
                formControlName="nettIncome"
                #nettIncome
                min="0"
                (keypress)="numberOnly($event)"
                [(ngModel)]="paySlipModel.nettIncome">



              <div *ngIf="validatePayslipInput.nettIncome.errors?.required && submitted">
                <small class="text-danger">Income amount is required</small>
              </div>



              <!-- <div *ngIf="calculateError"  class="error-label">
                Payslip values are incorrect
              </div> -->
            </td>
          </tr>



          <tr>
            <td>Other Income:</td>
            <td>
              <input
                mdc-input-container
                md-input
                numbersOnly
                min="0"
                (keypress)="numberOnly($event)"
                placeholder="Other Income"
                formControlName="otherIncome">
            </td>
          </tr>
        </tbody>
      </table>
      <div class="form-group center-screen">
        <button
          mat-raised-button
          class="me-2"
          type="button"
          color="primary"
          (click)="closeDialog()">
          Close
        </button>
        <button
          mat-raised-button
          type="submit"
          color="primary"
          (click)="save()"
          [disabled]="invalidDate ? 'disabled' : null">
          Save
        </button>
      </div>
    </form>
  </div>
</div>
