import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { RegionsAdminComponent } from './regions-admin.component';
import { RegionsAddComponent } from './regions-add/regions-add.component';
import { RegionAdminDialogComponent } from './region-admin-dialog/region-admin-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LMSSharedUiModule } from '@lmsSharedUI';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatButtonModule } from '@angular/material/button';
import { MdbUiKitModule } from "src/app/mdb-ui-kit.module";


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        LMSSharedUiModule,
        MatButtonModule,
        MatListModule,
        MdbUiKitModule,
        RegionsAdminComponent,
        RegionAdminDialogComponent,
        RegionsAddComponent
    ],
    exports: [
        RegionsAdminComponent,
        RegionAdminDialogComponent,
        RegionsAddComponent
    ]
})
export class RegionsAdminModule { }
