<div class="modal-content document-container">
  <div class="modal-body" >
        <div style="height: 80vh;">
          <pdf-viewer
            class="fill"
            [src]="pdfSrc"
            [c-maps-url]="'assets/cmaps'"
            [rotation]="0"
            [original-size]="false"
            [show-all]="true"
            (after-load-complete)="afterLoadComplete($event)"
            [zoom]="1"
            [zoom-scale]="'page-width'"
            [stick-to-page]="false"
            [render-text]="true"
            [render-text-mode]="0"
            [autoresize]="true"
            [show-borders]="true"
            [external-link-target]="'blank'"
          ></pdf-viewer>
        </div>
  </div>
  <div class="modal-footer align-content-center justify-content-center">
    <button mat-raised-button class="btn btn-primary me-2" mdbRipple color="primary"
    (click)="close()"> Close </button>
    <button mat-raised-button class="btn btn-primary me-2" mdbRipple color="primary"
    (click)="downloadPdfFile()">
    <i class="fas fa-sm fa-fw fa-download"></i>
    Download</button>
  </div>
</div>
