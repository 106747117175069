import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router, RouterLink } from '@angular/router';
import { LoginModel } from 'src/app/core/models/login.model';
import { HideLinksService } from "src/app/core/rest-services/hide-links.service";
import { UserService } from 'src/app/core/services/lmsuser.service';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MatButtonModule } from '@angular/material/button';
import { DisableControlDirective } from '../../shared/directives/disableControl.directive';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    standalone: true,
    imports: [MatCardModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatIconModule, MatInputModule, DisableControlDirective, MatButtonModule, MdbRippleModule, RouterLink]
})
export class LoginComponent implements OnInit {

  loginObject: LoginModel = new LoginModel();
  loginForm: FormGroup;
  hide = true;
  loginResults: any;

  isLogin = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private hideLinkService: HideLinksService) {
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      'userName': [this.loginObject.userName, [Validators.required]],
      'password': [this.loginObject.password, [Validators.required, Validators.minLength(6), Validators.maxLength(30)]]
    });

  }

  onLoginSubmit() {

    this._snackBar.dismiss();
    this.loginUser();
    this.isLogin = true;
  }


  loginUser() {

    this.loginObject.userName = this.loginForm.value.userName;
    this.loginObject.password = this.loginForm.value.password;

    this.userService.getKCProfileObs().subscribe((res: any) => {
      this.loginResults = res;
      console.log(this.loginResults);

      if (this.loginResults.message == "User verified") {
        localStorage.setItem('finbond_user', res);
        localStorage.setItem('username', res.userName);
        this.hideLinkService.addUser(res);
        this.router.navigate([this.userService.userJobTitle.component]);
        //this.router.navigate(['/opt']);
      } else {
        this._snackBar.open(this.loginResults.message, 'Close!', {
          verticalPosition: 'top',
          duration: 5000
        });

        this.router.navigate([this.userService.userJobTitle.component]);
      }

    });

  }

}
