import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ClientDetailsService } from 'src/app/core/rest-services/client-details.service';

import paginate from '@lmsPaginate';
import { RiskScoreModel } from 'src/app/core/models/risk-score-model.model';
import { UserService } from 'src/app/core/services/lmsuser.service';
import { AddRiskScoreComponent } from './add-risk-score/add-risk-score.component';
import { RiskScoreRemoveDialogComponent } from './risk-score-remove-dialog/risk-score-remove-dialog.component';
import { RiskScoresEditDialogComponent } from './risk-scores-edit-dialog/risk-scores-edit-dialog.component';
import { RiskScoresService } from './risk-scores.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MatButtonModule } from '@angular/material/button';
import { NgFor, NgIf, NgClass } from '@angular/common';
import { DisableControlDirective } from '../../shared/directives/disableControl.directive';
import { LMSSelectOptionDirective } from 'src/app/shared/directives/lms-selectOption.directive';

@Component({
    selector: 'app-risk-scores',
    templateUrl: './risk-scores.component.html',
    styleUrls: ['./risk-scores.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule,
      LMSSelectOptionDirective,  NgFor, MatButtonModule, MdbRippleModule, NgIf, NgClass, NgxPaginationModule]
})
export class RiskScoresComponent implements OnInit {
  riskScoreModel: RiskScoreModel = new RiskScoreModel();
  lstParemeterType: any;
  riskScoresForm: FormGroup;

  @Input() items: Array<any>;
  @Output() changePage = new EventEmitter<any>(true);
  @Input() initialPage = 1;
  @Input() pageSize = 8;
  @Input() maxPages = 3;

  pager: any = {};

  constructor(
    private clientDetailsService: ClientDetailsService,
    public riskScoreService: RiskScoresService,
    private userService: UserService,
    private lmsDialog: MatDialog,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.riskScoresForm = this.formBuilder.group({
      'parameterType': [this.riskScoreModel.parameterType, [Validators.required]]
    });
  }

  //public lstParameterTypes = PARAMETER_TYPES;

  ngOnChanges(changes: SimpleChanges) {
    // reset page if items array has changed
    if (changes.items.currentValue !== changes.items.previousValue) {
      this.setPage(this.initialPage);
    }
  }

  setPage(page: number) {
    // get new pager object for specified page
    this.pager = paginate({ totalItems: this.items.length,currentPage: page,pageSize: this.pageSize,maxPages: this.maxPages});

    // get new page of items from items array
    const pageOfItems = this.items.slice(this.pager.startIndex, this.pager.endIndex + 1);
    // call change page function in parent component
    this.changePage.emit(pageOfItems);
  }
  findAll() {
    this.clientDetailsService.findAll().subscribe(
      (result: any) => {
        this.riskScoreModel.parameterType = '';
        this.items = result;

        if (this.items && this.items.length) {
          this.setPage(this.initialPage);
        }
      });
  }

  findByParameterType(pType: any) {
    this.riskScoreService.findByParameterType(pType).subscribe((result: any) => {
      console.log("Score card Details: ", result);
      this.items = result;

      if (this.items && this.items.length) {
        this.setPage(this.initialPage);
      }
    });
  }

  removeRiskScore(score: any) {
    this.riskScoreService.setRiskScorecard(score);

    this.riskScoreModel = new RiskScoreModel()
    this.riskScoreModel.id = score.id;
    this.riskScoreModel.parameterType = score.parameterType;
    this.riskScoreModel.description = score.description;
    this.riskScoreModel.score = score.score;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = 'auto';
    dialogConfig.minWidth = '600px';
    dialogConfig.data = { riskScorecard: this.riskScoreModel };

    const dialogRef = this.lmsDialog.open(RiskScoreRemoveDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      result.id = this.riskScoreService.riskScorecard.id;
      result.userName = this.userService.userLoginName;

      this.riskScoreService.removeScorecard(this.riskScoreService.riskScorecard.id).subscribe((res: any) => {
        this.items = res;
        this.findByParameterType(this.riskScoreModel.parameterType);
      });
    })
  }

  editRiskScore(score: any) {
    this.riskScoreService.setRiskScorecard(score);

    const userName = this.userService.userLoginName ?? 'User not found';

    this.riskScoreModel = new RiskScoreModel()
    this.riskScoreModel.id = score.id;
    this.riskScoreModel.updatedBy = userName;
    this.riskScoreModel.updatedOn = new Date();
    this.riskScoreModel.parameterType = score.parameterType;
    this.riskScoreModel.description = score.description;
    this.riskScoreModel.score = score.score;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '380px';
    dialogConfig.width = '700px';
    dialogConfig.data = { riskScorecard: this.riskScoreModel };

    const dialogRef = this.lmsDialog.open(RiskScoresEditDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      result.id = this.riskScoreService.riskScorecard.id;
      result.updatedBy = this.userService.userLoginName;
      result.updatedOn = Date.now();

      this.riskScoreService.updateRiskScoreData(result).subscribe((res: any) => {
        this.riskScoreService.riskScorecard = res;
        this.findByParameterType(this.riskScoreService.riskScorecard.parameterType);
        this.riskScoreModel.parameterType = this.riskScoreService.riskScorecard.parameterType;
      });
    });
  }

  addRiskScore() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '380px';
    dialogConfig.width = '700px';

    const dialogRef = this.lmsDialog.open(AddRiskScoreComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.createdBy = this.userService.userLoginName;
        result.updatedBy = this.userService.userLoginName;
        result.createdOn = Date.now();
        result.updatedOn = Date.now();

        this.riskScoreService.saveRiskScoreData(result).subscribe((res => {
          this.riskScoreModel = res;
          this.findByParameterType(this.riskScoreModel.parameterType);
        }));
      }
    });
  }
}
