import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, skip, startWith, switchMap, withLatestFrom } from 'rxjs/operators';


//The logic I have in the component is likely to be reused somewhere else in an Angular application,
//therefore it’s worth to extract it into a custom RxJS operator.
//An operator is simply a function which takes an input stream as its argument and returns an output observable.

export function liveSearch<T, R>(
  dataCb: (query: T) => Observable<R>,
  delay = 500
): (source$: Observable<T>) => Observable<R> {
  return (source$: Observable<T>) => source$.pipe(
    skip(1),// The first valueChange emits undefined for unknown reason; therefore, skip is used to discard it
    debounceTime(delay), //emit search value after user stops typing for 'delay' milliseconds
    distinctUntilChanged(),//do nothing when search value is unchanged
    //withLatestFrom(source$),//get the cities returned from HTTP request
    switchMap(dataCb)
  );
}
