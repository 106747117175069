// loader-interceptor.service.ts
import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, Observable, tap, timeout } from 'rxjs';
import { ApiloaderService } from 'src/app/shared/loaders/apiloader/apiloader.service';


@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  constructor(private loaderService: ApiloaderService) { }

  private removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  private logRequestTime(startTime: number, url: string, method: string) {
    const requestDuration = `${performance.now() - startTime}`;
    console.log(`${method} ${url} - ${requestDuration} milliseconds`);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requests.push(req);
    const begin = performance.now();
    this.loaderService.isLoading.next(true);

    return next.handle(req).pipe(
      //const subscription = next.handle(req).pipe(
      //tap(evt => {
      //tap((evt: HttpEvent<any>) => {
      //let showdate = new Date().toLocaleString();
      // if (evt instanceof HttpResponse) {
      //     if(evt.body && evt.body.success)
      //     this.errorService.handleString(evt.body.success.message);
      //     //this.toastrService.success(`Msg(evt.body.success.message):${evt.body.success.message}`, `${showdate}: (title) ${evt.body.title}`, { positionClass: 'toast-top-center' });
      // }
      //}),
      finalize(() => {
        this.logRequestTime(begin, req.url, req.method);
        this.removeRequest(req);
      })
    );
    // .subscribe(
    //   event => {
    //     // if (event instanceof HttpRequest) {
    //     //   req = req.clone({
    //     //     withCredentials: true,
    //     //   });
    //     // }
    //     if (event instanceof HttpResponse) {
    //       //this.removeRequest(req);
    //       observer.next(event);
    //     }
    //   });
    // remove request from queue when cancelled
    //   return () => {
    //     //this.removeRequest(req);
    //     subscription.unsubscribe();
    //   };
    // });
  }

  // private totalRequests = 0;

  // constructor(
  //   public loader: LoadingSpinService) { }

  // intercept(
  //   request: HttpRequest<unknown>,
  //   next: HttpHandler
  // ): Observable<HttpEvent<unknown>> {
  //   this.loader.show();
  //   return next.handle(request).pipe(
  //     finalize(() => {
  //       this.loader.hide();
  //     })
  //   );
  // }
}
