import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MYBENEFIT_TIER, YESNO } from '@lmsConstants';
import { RatesAndFeesModel } from 'src/app/core/models/rates-and-fees-model.model';
import { RatesAdnFeesService } from '@lmsrestServices';
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { PageCardheadComponent } from '../../../shared/ui/pageheader-card/pageheader-card.component';

@Component({
    selector: 'app-add-loan-type',
    templateUrl: './add-loan-type.component.html',
    styleUrls: ['./add-loan-type.component.css'],
    standalone: true,
    imports: [PageCardheadComponent, FormsModule, ReactiveFormsModule, MatExpansionModule, NgIf, NgFor, MatButtonModule]
})
export class AddLoanTypeComponent implements OnInit {
  ratesAndFeesModel = {} as RatesAndFeesModel;
  addLoanTypeForm: FormGroup;
  submitted: boolean = false;
  loanTypeData: any;
  lstLoanTypeData: Array<any> = [];

  panelOpenState = false;

  constructor(
    public dialogRef: MdbModalRef<AddLoanTypeComponent>,
    public ratesAndFeesService: RatesAdnFeesService,
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    // this.keycloak.loadUserProfile().then(user => {
    //   this.userProfile = user;
    // });
    this.ratesAndFeesModel = new RatesAndFeesModel();

    //ADDS loanTypeInfo to the array lstLoanTypeData

    this.addLoanTypeForm = this.fb.group({
      //PRODUCT DETAILS
      //'id': [this.ratesAndFeesModel.id, [Validators.required]],
      code: [this.ratesAndFeesModel.code],
      description: [this.ratesAndFeesModel.description],
      type: [this.ratesAndFeesModel.type],
      regulatoryType: [this.ratesAndFeesModel.regulatoryType],
      minimumLoan: [this.ratesAndFeesModel.minimumLoan, [Validators.required]],
      maximumLoan: [this.ratesAndFeesModel.maximumLoan, [Validators.required]],

      interestRate1st: [
        this.ratesAndFeesModel.interestRate1st,
        [Validators.required],
      ],
      interestRateOther: [
        this.ratesAndFeesModel.interestRateOther,
        [Validators.required],
      ],
      initFeeBelow1000: [this.ratesAndFeesModel.initFeeBelow1000],
      initFeeFirst1000: [this.ratesAndFeesModel.initFeeFirst1000],
      initiationFee: [
        this.ratesAndFeesModel.initiationFee,
        [Validators.required],
      ],
      initiationCap: [
        this.ratesAndFeesModel.initiationCap,
        [Validators.required],
      ],
      includeVatInCalculation: [this.ratesAndFeesModel.includeVatInCalculation],

      myBenefits: [this.ratesAndFeesModel.myBenefits, [Validators.required]],

      factor1stLoan: [
        this.ratesAndFeesModel.factor1stLoan,
        [Validators.required],
      ],
      factorOtherLoan: [
        this.ratesAndFeesModel.factorOtherLoan,
        [Validators.required],
      ],
      factorUpTo850: [
        this.ratesAndFeesModel.factorUpTo850,
        [Validators.required],
      ],
      factorUpTo1000: [
        this.ratesAndFeesModel.factorUpTo1000,
        [Validators.required],
      ],
      initServiceFeeCap: [
        this.ratesAndFeesModel.initServiceFeeCap,
        [Validators.required],
      ],
      term: [this.ratesAndFeesModel.term, [Validators.required]],
      monthlyServiceFee: [
        this.ratesAndFeesModel.monthlyServiceFee,
        [Validators.required],
      ],
      maxDays: [this.ratesAndFeesModel.maxDays, [Validators.required]],

      calculationType: [this.ratesAndFeesModel.calculationType],
      includeInInterestCalc: [this.ratesAndFeesModel.includeInInterestCalc],
      includeVatInCalc: [this.ratesAndFeesModel.includeVatInCalc],
    });
  }

  public lstIncludeInCalculation = YESNO;
  public lstMyBenefitTier = MYBENEFIT_TIER;
  public lstRegulatory: Array<any> = [
    { value: "ShortTerm", description: "Short Term" },
    { value: "Unsecured", description: "Unsecured" },
  ];

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  saveLoanType() {
    this.submitted = true;
    //this.ratesAndFeesModel = this.loanTypeInfo;
    if (this.addLoanTypeForm.invalid) {
      console.log('Form fields are null or empty...');
      this.submitted = false;
      return;
    }
    return this.dialogRef.close(this.ratesAndFeesModel);
  }

  close() {
    this.dialogRef.close();
  }

  get validateRatesAndFeesValues() {
    return this.addLoanTypeForm.controls;
  }
}
