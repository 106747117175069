import { Component, Inject } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs/internal/Observable';
import { UploadFileService } from '@lmsrestServices';
import { UserService } from 'src/app/core/services/lmsuser.service';
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { NgIf, NgStyle, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.css'],
    standalone: true,
    imports: [NgIf, NgStyle, NgFor, MdbRippleModule, AsyncPipe]
})
export class UploadFileComponent {

  selectedFiles: FileList;
  currentFile: File;
  progress = 0;
  message = '';

  fileInfos: Observable<any>;

  constructor(public dialogRef: MdbModalRef<UploadFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private uploadService: UploadFileService) {
  }

  upload(): void {
    this.progress = 0;

    this.currentFile = this.selectedFiles[0];

    this.uploadService.upload(this.currentFile).subscribe(event => {
      if (event && event.type === HttpEventType.UploadProgress) {
        const btotal = event.total?.valueOf() || 0;
        if (btotal > 0) {
          this.progress = Math.round(100 * event.loaded / btotal);
        }
      } else if (event instanceof HttpResponse) {
        this.message = event.body.message;
        this.fileInfos = this.uploadService.getFiles();
      }
    },
      () => {
        this.progress = 0;
        this.message = 'Could not upload the file!';
        // this.currentFile = null;
      });

    // this.selectedFiles = null;
  }

  selectFile(event: any) {
    this.selectedFiles = event.target.files;
    console.log(this.selectedFiles);
  }

  onDismiss() {
    this.dialogRef.close(true);
  }

}
