import { Component,  OnInit } from '@angular/core';

import { UserService } from 'src/app/core/services/lmsuser.service';
import { NgIf } from '@angular/common';
import { AppUtilityService } from '../app-utility.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    standalone: true,
    imports: [NgIf]
})
export class HomeLandingComponent implements OnInit {

  isLoggedIn$ = this.userService.ValidUserLoaded$;

  constructor(
    private userService: UserService,
    public global_utility: AppUtilityService
  ) {
    this.userService.onLogginChanged.subscribe((loginChanged) => {
      if(loginChanged){
        setTimeout(() => {
          this.global_utility.stopLoading();
          this.global_utility.navigateToLanding();
        },500);
      }
    });
  }

  ngOnInit(): void {
    this.global_utility.startLoading('CLMS loading...');
      //this.userService.getLMSProfileObs().subscribe((lmsuser: any) => {
        //if (lmsuser && lmsuser.id!) {
          //this.router.navigate(['/lmsuser']);

          //redirect could happen here, but for now just land on beautifull home page.

          //const component = lmsuser!.jobTitle?.component || '/';
          //this.router.navigate([component]);
       // }
    // });
    //this.onUserProfileLoaded();
    //this.userService.onLMSUserProfile();
  }

}
