import { Injectable, Optional } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { MdbModalRef, MdbModalService } from "mdb-angular-ui-kit/modal";

import { ClientShowModel } from "src/app/core/models/client-show.model";
import { QuoteModel } from "src/app/core/models/quote-model.model";
import { DocumentScanningComponent } from "../features/document-scanning/document-scanning.component";
import { AddressesComponent } from "../modules/addresses/addresses.component";
import { AuditTrailComponent } from "../modules/audit-trail/audit-trail.component";
import { BankingDetailsComponent } from "../modules/banking-details/banking-details.component";
import { ClientDocumentsComponent } from "../modules/client-documents/client-documents.component";
import { NextOfKinComponent } from "../modules/next-of-kin/next-of-kin.component";
import { RejectOfferComponent } from "../modules/reject-offer/reject-offer.component";
import { OfferAuditComponent } from "../offer-audit/offer-audit.component";
import { OfferValuesComponent } from "../offer-values/offer-values.component";
import { CbsGuideComponent } from "../register-loan/cbs-guide/cbs-guide.component";
import { RiskMatrixScoreComponent } from "../risk-matrix-score/risk-matrix-score.component";
import { LexisnexisDialogComponent } from "./dialogs/lexisnexis-dialog/lexisnexis-dialog.component";
import { LMSDialogComponent } from "./dialogs/lms-dialog/lms-dialog.component";
import { DocumentViewerComponent } from './ui/document-viewer/document-viewer.component';
import { BiometricsDialogComponent } from '../modules/biometrics-dialog/biometrics-dialog.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { AgentComplianceNotificationDialogComponent } from './dialogs/agent-compliance-notification-dialog/agent-compliance-notification-dialog.component';
import { PayslipDialogComponent } from '../modules/payslip-dialog/payslip-dialog.component';
import { PaySlipModel1 } from '../core/models/pay-slip-model.model';


@Injectable({
  providedIn: 'root'
})
export class LMSDialogHandlerService {
  private lmsDialogRef: MdbModalRef<LMSDialogComponent> | null = null;
  private clientdocumnetDialogRef: MdbModalRef<ClientDocumentsComponent> | null = null;
  private documnetViewerDialogRef: MdbModalRef<DocumentViewerComponent> | null = null;
  constructor(
    private modalService: MdbModalService,
    @Optional() public matdialog?: MatDialog
  ) { }

  openClientDocuments(clientModel: ClientShowModel, quoteId?: number | string, readonly = false) {
    this.clientdocumnetDialogRef = this.modalService.open(ClientDocumentsComponent, {
      backdrop: true, ignoreBackdropClick: true, keyboard: true, nonInvasive: false,
      modalClass: 'modal show modal-fluid modal-dialog-scrollable ',
      containerClass: 'frame',
      data: {
        quoteId: quoteId || clientModel.id,
        clientShow: clientModel,
        isReadOnly: readonly
      }
    });
  }

  openAddressDialog(quoteModel: QuoteModel, addresses: any, readonly = false) {
    const dialogRef = this.modalService.open(AddressesComponent, {
      backdrop: true, ignoreBackdropClick: true, keyboard: true, nonInvasive: false, animation: false,
      //modalClass: 'modal-dialog modal-lg modal-fluid modal-dialog-centered  d-flex d-flex-md',
      modalClass: 'modal modal-fluid modal-lg modal-dialog modal-dialog-centered  modal-dialog-scrollable',
      containerClass: 'frame',
      //modalClass: 'modal modal-xl modal-frame modal-fluid modal-dialog-centered modal-center',
      //containerClass: 'frame',
      data: {
        addresses: addresses,
        clientDetails: quoteModel,
        isReadOnly: readonly
      }
    });
    return dialogRef;
  }

  openNextofKinDialog(nextOfKin: any, quoteModel: QuoteModel, addresses: any, readonly = false) {
    const dialogRef = this.modalService.open(NextOfKinComponent, {
      backdrop: true, ignoreBackdropClick: true, keyboard: true, nonInvasive: false, animation: false,
      //modalClass: 'modal-dialog modal-lg modal-fluid modal-dialog-centered  d-flex d-flex-md',
      modalClass: 'modal modal-fluid modal-lg modal-dialog modal-dialog-centered  modal-dialog-scrollable',
      containerClass: 'frame',
      data: {
        nextOfKin: nextOfKin,
        clientDetails: quoteModel,
        addresses: addresses,
        isReadOnly: readonly
      }
    });
    return dialogRef;
  }

  openBankingDetailsDialog(bankingDetails: any, clientDetails: QuoteModel, readonly = false) {
    const dialogRef = this.modalService.open(BankingDetailsComponent, {
      backdrop: true, ignoreBackdropClick: true, keyboard: true, nonInvasive: false, animation: false,
      modalClass: 'modal modal-fluid modal-lg modal-dialog modal-dialog-centered  modal-dialog-scrollable',
      containerClass: 'frame',
      data: {
        bankingDetails: bankingDetails,
        clientDetails: clientDetails,
        isReadOnly: readonly
      }
    });
    return dialogRef;
  }

  openDocScanning(quoteModel: QuoteModel, quoteId?: number | string, readonly = false) {
    const dialogRef = this.modalService.open(DocumentScanningComponent, {
      backdrop: true, ignoreBackdropClick: true, keyboard: true, nonInvasive: false,
      modalClass: 'modal show modal-fluid modal-dialog-scrollable ',
      containerClass: 'frame',
      data: {
        quoteId: `${quoteId}`,
        clientDetails: quoteModel,
        isReadOnly: readonly
      }
    });

    return dialogRef;
  }

  openDocumentViewer(fileSrc: string | Blob | Uint8Array , pfileName:string) {
    const dialogRef = this.modalService.open(DocumentViewerComponent, {
      backdrop: true, ignoreBackdropClick: true, keyboard: true, nonInvasive: false,
      modalClass: 'modal show modal-xl modal-dialog-scrollable ',
      containerClass: 'frame',
      data: {
        fileSrc: fileSrc,
        pdffileName: pfileName
      }
    });

    return dialogRef;
  }

  openMatDocumentViewer(fileSrc: string | Blob | Uint8Array , pfileName:string) {
    this.matdialog?.open(DocumentViewerComponent, {
      disableClose: false,
      autoFocus: false,
      minHeight: '800px',
      maxHeight: '950px',
      minWidth: '1300px',
      maxWidth: '1350px',
      data: {
        fileSrc: fileSrc,
        pdffileName:pfileName
      }
    });
  }

  openCompuscanOffersDialog(compuscanOffers: any) {
    this.modalService.open(OfferValuesComponent, {
      backdrop: true, ignoreBackdropClick: true, keyboard: true, nonInvasive: false, animation: false,
      modalClass: 'modal modal-md modal-dialog modal-dialog-centered  modal-dialog-scrollable',
      containerClass: 'frame',
      data: {
        compuscanOffers: compuscanOffers
      }
    });
  }

  openLexisNexisDialog(message: any, title: string) {
    this.modalService.open(LexisnexisDialogComponent, {
      backdrop: true, ignoreBackdropClick: true, keyboard: true, nonInvasive: false, animation: false,
      modalClass: 'modal modal-lg modal-dialog modal-dialog-centered  modal-dialog-scrollable',
      containerClass: 'frame',
      data: {
        message: message,
        title: title
      }
    });
  }

  openBiometricsDialog(biodata: any) {
// data: {
//   userBiometricsData: this.registerObject.loginName,
//   biometricType: this.user,
// }

// data:{
//   preauthBiometricsData: preauthBiometricsData,
//   biometricType: biometricType
// }
    const dialogRef = this.matdialog?.open(BiometricsDialogComponent, {
      disableClose: true,
      autoFocus: true,
      height: 'auto',
      width: 'auto',
      data: biodata
    });

    return dialogRef!;
  }

  openMatConfirmationDialog(confirmMsg: string, msgtitle: string, isError = false) {
    const dialogRef = this.matdialog?.open(ConfirmDialogComponent,{
      width: 'auto',
      height: 'auto',
      autoFocus: true,
      data: {
        title: msgtitle,
        message: confirmMsg,
        showClose: false,
        isErrorOccured: isError,
      }
    });
    return dialogRef!;
  }

  openMatComplianceNotification() {
    const dialogRef = this.matdialog?.open(AgentComplianceNotificationDialogComponent,{
      disableClose: true,
      minHeight: '550px',
      width: 'auto',
      height: 'auto',
      autoFocus: true
    });
    return dialogRef!;
  }


  openRiskMatrixScoresDialog(riskAnalysis) {
    this.modalService.open(RiskMatrixScoreComponent, {
      backdrop: true, ignoreBackdropClick: true, keyboard: true, nonInvasive: false, animation: false,
      modalClass: 'modal modal-fluid modal-dialog modal-dialog-centered  modal-dialog-scrollable',
      containerClass: 'frame',
      data: {
        riskAnalysis: riskAnalysis
      }
    });
  }

  openRejectOfferDialog(currQuote: any) {
    const dialogRef = this.modalService.open(RejectOfferComponent, {
      backdrop: true, ignoreBackdropClick: true, keyboard: true, nonInvasive: false, animation: false,
      modalClass: 'modal modal-fluid modal-dialog modal-dialog-centered  modal-dialog-scrollable',
      containerClass: 'frame',
      data: {
        rejectOfferInfo: currQuote
      }
    });
    return dialogRef;
  }

  openMatPayslipDialog(month: string | number, payslipModel?: PaySlipModel1| null, clientType?:string) {
    const dialogRef = this.matdialog?.open(PayslipDialogComponent, {
      disableClose: true,
      delayFocusTrap:true,
      autoFocus: true,
      height: 'auto',
      width: 'auto',
      data: {
        month: month,
        payslipModel: payslipModel,
        clientType:clientType
      }
    });

    return dialogRef;
  }

  openAuditTrailDialogByQuoteId(quoteId: number) {
    this.matdialog?.open(AuditTrailComponent, {
      disableClose: false,
      autoFocus: false,
      minHeight: '800px',
      maxHeight: '950px',
      minWidth: '1200px',
      maxWidth: '1250px',
      data: {
        quoteid: quoteId
      }
    });
  }
  openAuditTrailDialogbyIdNumber(idNumber: string) {
    this.matdialog?.open(AuditTrailComponent, {
      disableClose: false,
      autoFocus: false,
      minHeight: '800px',
      maxHeight: '950px',
      minWidth: '1300px',
      maxWidth: '1350px',
      data: {
        idNumber: idNumber
      }
    });
  }

  openOfferAuditDialog(lstaudit: any) {
    //console.log(this.auditTrails, "Audit trail list");
    this.matdialog?.open(OfferAuditComponent, {
      disableClose: false,
      autoFocus: false,
      minHeight: '800px',
      maxHeight: '950px',
      width: '1300px',
      maxWidth: '1300px',
      data: {
        offersList: lstaudit,
        isReadOnly: true
      }
    });
  }


  openCBSGuideDialog(){
   this.matdialog?.open(CbsGuideComponent, {
      disableClose: true,
      autoFocus: true,
      height: '240px',
      width: '600px'
    });
  }


}
