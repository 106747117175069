<div class="modal-content">
  <div class="modal-header">
    <div class="modal-title center-fields">
      <!-- =============   Compuscan Offers ============= -->
      <app-page-cardhead [PageName]="'Compuscan Offers'" [PageHeadWidth]=600></app-page-cardhead>
    </div>
  </div>


  <div class="modal-body" >
    <div class="card">
      <div class="card-body p-1">
        <div class="container-fluid bd-example-row">
          <ng-container>
            <table class="mt-3 table table-bordered table-striped table-light table-sm shadow-1-strong rounded-1 p-1" [style.width.px]="720">
              <thead>
                <tr class="table-center-header">
                <th>Product</th>
                <th>Outcome</th>
                <th>Reason</th>
                </tr>
              </thead>
              <tbody>
              <!-- *ngIf="clientResults.loans"> -->
              <tr *ngFor="let compOffers of compuscanOffers">
                <td>{{compOffers.description}}</td>
                <ng-container *ngIf="!!compOffers.outcome">
                  <td class="text-center">
                    <span
                      *ngIf="compOffers.outcome === 'Y'"
                      class="badge badge-success rounded-pill d-inline">
                      {{compOffers.outcome}}
                    </span>
                    <span
                      *ngIf="compOffers.outcome === 'N'"
                      class="badge badge-danger rounded-pill d-inline">
                      {{compOffers.outcome}}
                    </span>
                  </td>
                </ng-container>
                <td>{{compOffers.reason}}</td>
              </tr>
              </tbody>
            </table>
          </ng-container>
        </div>
        <div class="card-footer center-screen">
          <button
            mat-raised-button
            class="btn btn-primary btn-rounded"
            mdbRipple
            color="primary"
            (click)="close()">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
