import { NgModule,APP_INITIALIZER, LOCALE_ID } from "@angular/core";

import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient, HttpClientModule, withInterceptors } from '@angular/common/http';
import { initializeKeycloak } from 'src/app/core/init/keycloak-init.factory';

import { MaterialModule } from 'src/app/material.module';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { ApiloaderService } from 'src/app/shared/loaders/apiloader/apiloader.service';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

import {AppComponent} from './app.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { LMSSharedUiModule } from './shared/shared-ui.module';
import { MenusModule } from 'src/app/modules/menus/menus.module';
import { RatesAndFeesModule } from 'src/app/modules/rates-and-fees/rates-and-fees.module';
import { ClientSearchModule } from 'src/app/client-search/client-search.module';
import { RegionsAdminModule } from 'src/app/modules/regions-admin/regions-admin.module';
import { BranchEmployersModule } from 'src/app/features/branch-employers/branch-employers.module';
import { LoaderInterceptor } from 'src/app/core/interceptors/http.interceptor';
import { HttpErrorInterceptor } from 'src/app/core/interceptors/http-error.interceptor';

import { NgxPaginationModule } from 'ngx-pagination';
import { NavbarComponent } from './navbar/navbar.component';
import { ApiloaderComponent } from './shared/loaders/apiloader/apiloader.component';
import { MdbUiKitModule } from './mdb-ui-kit.module';
import { LMSDatePickerModule } from './core/constants/datepicker-adapter';
import { RestServicesModule } from "./core/rest-services/rest-services.module";
import { PageCardheadComponent } from "@lmsSharedUI";
import { DEFAULT_TIMEOUT, TimeoutInterceptor, TimeoutInterceptorFn } from "./core/interceptors/timeout.interceptor";

const MATERIAL_IMPORTS = [
  BrowserAnimationsModule,
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatStepperModule
];

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RestServicesModule,
    HttpClientModule,
    AppRoutingModule,
    ...MATERIAL_IMPORTS,
    KeycloakAngularModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
        registrationStrategy: 'registerWhenStable:20000',
        enabled: true
    }),
    FormsModule,
    MdbUiKitModule,
    MaterialModule.forRoot(),
    LMSSharedUiModule,
    ToastrModule.forRoot({
        timeOut: 5000,
        positionClass: 'toast-top-center',
        includeTitleDuplicates:true,
        countDuplicates: true,
        preventDuplicates: true,
        maxOpened: 5,
        newestOnTop: true
    }), NgxPaginationModule,
    BranchEmployersModule,
    RegionsAdminModule,
    // RegionalManagerModule,
    ClientSearchModule,
    RatesAndFeesModule,
    MenusModule,
    LMSDatePickerModule,
    ApiloaderComponent,
    PageCardheadComponent,
    ReactiveFormsModule
],
  providers: [

    { provide: ApiloaderService, useClass: ApiloaderService },
    { provide: LOCALE_ID, useValue: 'en-ZA' },
    {
        provide: APP_INITIALIZER,
        useFactory: initializeKeycloak, multi:true, deps:[KeycloakService]
    },
    provideHttpClient(
      withInterceptors([TimeoutInterceptorFn]),
      withInterceptorsFromDi(),
    ),
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpErrorInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: LoaderInterceptor,
        deps: [ApiloaderService],
        multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 150000 },
  ],
  bootstrap: [AppComponent]
  //schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {
}
