/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-var */
/* eslint-disable no-self-assign */
import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormsModule, SelectControlValueAccessor } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
// import { ALLFINGERS, SKIPBIOMETRICSREASONS } from '@lmsConstants';
import paginate from "@lmsPaginate";
import { ToastrService } from 'ngx-toastr';
import { QuoteStoreService } from "src/app/core/rest-services/quote-store.service";
import { UserService } from 'src/app/core/services/lmsuser.service';
import { LoadingSpinService } from "src/app/shared/loaders/spinner/loadspinner.service";
import { BiometricsModel } from 'src/app/core/models/biometrics-model.model';
import { PreAuthorisationModel } from 'src/app/core/models/pre-authorisation.model';
import { BiometricsSerivce } from 'src/app/core/rest-services/biometrics.service';
import { NgxPaginationModule } from "ngx-pagination";
import { MdbRippleModule } from "mdb-angular-ui-kit/ripple";
import { CdkScrollable } from "@angular/cdk/scrolling";
import { NgIf, NgFor, NgClass } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { PageCardheadComponent } from "../../shared/ui/pageheader-card/pageheader-card.component";
import { LMSSelectOptionDirective } from "src/app/shared/directives/lms-selectOption.directive";
// import 'src/assets/websdk.client.bundle.min';
// import 'src/assets/fingerprint.sdk.min';

declare let Fingerprint: any;

export interface CustomerEnrollmentEntity {
  id?: number;
  idNumber?: string;
  username?: string;
  fingerName?: FingerNameEnum;
  fmd?: string;
  created?: string;
  enrolledBy?: string;
  disabledAt?: string;
  disabledBy?: string;
}

type FingerNameEnum = 'LEFT_PINKY' | 'LEFT_RING' | 'LEFT_MIDDLE' | 'LEFT_INDEX' | 'LEFT_THUMB' | 'RIGHT_PINKY' | 'RIGHT_RING' | 'RIGHT_MIDDLE' | 'RIGHT_INDEX' | 'RIGHT_THUMB';

// export namespace CustomerEnrollmentEntity {
//   const FingerNameEnum = {
//     LeftPinky: 'LEFT_PINKY' as FingerNameEnum,
//     LeftRing: 'LEFT_RING' as FingerNameEnum,
//     LeftMiddle: 'LEFT_MIDDLE' as FingerNameEnum,
//     LeftIndex: 'LEFT_INDEX' as FingerNameEnum,
//     LeftThumb: 'LEFT_THUMB' as FingerNameEnum,
//     RightPinky: 'RIGHT_PINKY' as FingerNameEnum,
//     RightRing: 'RIGHT_RING' as FingerNameEnum,
//     RightMiddle: 'RIGHT_MIDDLE' as FingerNameEnum,
//     RightIndex: 'RIGHT_INDEX' as FingerNameEnum,
//     RightThumb: 'RIGHT_THUMB' as FingerNameEnum
//   };
// }

const NO_FINGER = 'NO_FINGER';

export interface SelectItem<T = any> {
  label?: string;
  value: T;
  styleClass?: string;
  icon?: string;
  title?: string;
  disabled?: boolean;
}

@Component({
    selector: 'app-biometrics-dialog',
    templateUrl: './biometrics-dialog.component.html',
    styleUrls: ['./biometrics-dialog.component.css'],
    standalone: true,
    imports: [PageCardheadComponent, MatButtonModule, NgIf,
      FormsModule, NgFor, NgClass, CdkScrollable, MdbRippleModule, NgxPaginationModule,LMSSelectOptionDirective]
})
export class BiometricsDialogComponent implements OnInit {
  @ViewChild('allFingersSelect') allFinger: ElementRef;

  @Input() items: Array<any>;
  @Output() changePage = new EventEmitter<any>(true);
  @Input() initialPage = 1;
  @Input() pageSize = 5;
  @Input() maxPages = 2;

  biometricsModel: BiometricsModel = new BiometricsModel();
  preauthorisationModel: PreAuthorisationModel = new PreAuthorisationModel();
  biometricsDialogForm: FormGroup;

  biometricsInfo: any;
  userBiometricsInfo: any;

  readerOpen = true;
  captureOpen = false;
  verificationOpen = false;
  userVerificationOpen = false;
  disableOpen = false;
  deviceInfo = false;
  signatureOpen = false;

  disabled = true;
  biometrics: any;

  readQuality: string;
  status: string;
  sdk: any;
  numberReadings: number;
  deviceSerial: string;
  currentFormat: any;
  devicesList: string[];
  deviceID: string;
  deviceUIDType: string;
  deviceModality: string;
  deviceTechnology: string;
  sampleData: string[];
  radioFormat: number;
  enrollDisabled: boolean;
  stopAcquisitionDisabled: boolean;
  startAcquisitionDisabled: boolean;

  enrollmentError?: string;
  enrollmentMessage?: string;
  clientEnrolled: CustomerEnrollmentEntity[] | [];
  userEnrolled: CustomerEnrollmentEntity[] | [];
  fingerNames: SelectItem[];
  selectedFingerName: any;
  selectedConsultantFingerName: any;
  verifyDisabled: boolean;

  biometricType: any;
  lmsUserProfile: any;

  clientBiometricsFound: boolean;
  userBiometricsFound: boolean;
  continueApplicationDisabled: boolean;

  enableSkip: boolean;
  //reasonSelected = false ;
  //lstBiometricsSkipReasons = SKIPBIOMETRICSREASONS;
  //lstAllFingers = ALLFINGERS;
  lstAllFingers: any[];
  //lstDisableClientFingers = DISABLECLIENTFINGERS;
  lstVerifiedFingers: any;
  //disableReason: any;

  noBiometrics: boolean;
  lstAllFingersDisable: boolean;
  sampleDataVerify: string;
  lstSignedFingerDetails: any[];
  lstConsultantSignedFingerDetails: any[];

  preAgreement: any;
  isNokComplete: boolean;
  verifiedClient = false;
  verifiedUser = false;
  isCellVisible: boolean;
  bmVerified: boolean;
  submitted = false;
  skipBiometrics = false;

  pager: any = {};

  constructor(
    private router: Router,
    public biometricsService: BiometricsSerivce,
    public quoteStoreService: QuoteStoreService,
    public loaderService: LoadingSpinService,
    private userService: UserService,
    private toastrService: ToastrService,
    private httpClient: HttpClient,
    public dialogRef: MatDialogRef<BiometricsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {
    this.biometricsInfo = data.preauthBiometricsData,
      this.userBiometricsInfo = data.userBiometricsData,
      this.biometricType = data.biometricType,
      this.readQuality = "",
      this.status = "Select a device",
      this.sdk = new Fingerprint.WebApi,
      this.numberReadings = 0,
      this.deviceSerial = "No Device Selected",
      this.currentFormat = Fingerprint.SampleFormat.Intermediate,
      this.devicesList = [],
      this.deviceUIDType = "",
      this.deviceID = "",
      this.deviceModality = "",
      this.deviceTechnology = "",
      this.sampleData = [],
      this.radioFormat = 2,
      this.enrollDisabled = true,
      this.stopAcquisitionDisabled = true,
      this.startAcquisitionDisabled = true,
      this.continueApplicationDisabled = true,

      this.enrollmentMessage = "",
      this.enrollmentError = "",
      this.clientEnrolled = [],
      this.userEnrolled = [],
      //this.selectedFingerName = this.lstAllFingers,
      this.verifyDisabled = true;

    //console.log("constructor end v4..." + this.selectedFingerName);
  }

  updateData(index: number, value: any) {
    this.sampleData[index] = value;
    console.log("this.sampleData[index]---", this.sampleData[index]);
  }

  ngOnInit(): void {
    console.log("Biometrics info---------", this.biometricsInfo);

    console.log("ngOninit..." + this.radioFormat);
    this.selectedFingerName = '';
    this.onUserProfileValue();

    this.sdk.onDeviceConnected = () => {
      // Detects if the deveice is connected for which acquisition started
      console.log("Device Connected");
      this.status = "Device Connected";
    };
    this.sdk.onDeviceDisconnected = () => {
      // Detects if device gets disconnected - provides deviceUid of disconnected device
      console.log("Device disconnected");
      this.status = "Device disconnected";
    };
    this.sdk.onCommunicationFailed = () => {
      // Detects if there is a failure in communicating with U.R.U web SDK
      console.log("Communinication Failed");
      this.status = "Communinication Failed";
    };
    this.sdk.onErrorOccurred = () => {
      console.log("Error Occurred during capture");
      this.status = "Error Occurred during capture";
    };

    this.sdk.onSamplesAcquired = (s: any) => {
      // Sample acquired event triggers this function
      console.log("Sample Acquired");
      this.status = "Sample Acquired";
      this.sampleAcquired(s);
    };
    this.sdk.onQualityReported = (e: { quality: any; }) => {
      console.log("onQualityReported");
      // Quality of sample aquired - Function triggered on every sample acquired
      this.readQuality = Fingerprint.QualityCode[(e.quality)];
      this.status = "Quality Reported";
      if (this.readQuality == 'Good') {
        this.numberReadings++;
      }
      else {
        this.toastrService.error(`${this.readQuality}`, 'Finger Quality Status', { positionClass: 'toast-top-center', closeButton: true });
        this.stopClientCapture();
      }
      console.log("onQualityReported :", this.readQuality);
    };

    if (this.biometricType == 'client-offer') {
      this.getClientEnrolledFingers();
      this.getUserEnrolledFingers();
    }

    if (this.biometricType == 'user') {
      this.getUserEnrolledFingers();
    }

    if (this.biometricType == 'client') {
      this.getClientEnrolledFingers();
    }


    this.getDevice();

    this.biometricsModel.skipReason = "N/A";

    if (this.biometricType == 'client-offer') {
      this.openVerification();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // reset page if items array has changed
    if (changes.items.currentValue !== changes.items.previousValue) {
      this.setPage(this.initialPage);
    }
  }

  setPage(page: number) {
    // get new pager object for specified page
    this.pager = paginate({ totalItems: this.items.length, currentPage: page, pageSize: this.pageSize, maxPages: this.maxPages });

    // get new page of items from items array
    const pageOfItems = this.items.slice(this.pager.startIndex, this.pager.endIndex + 1);
    // call change page function in parent component
    this.changePage.emit(pageOfItems);
  }

  onUserProfileValue = () => {
    this.userService.LMSProfile$.subscribe((lmsuser: any) => {
      if (lmsuser && lmsuser.id!) {
        this.lmsUserProfile = lmsuser;
        console.log("User Profile: ", this.lmsUserProfile);
      }
    });
  };

  getDevice() {
    Promise.all([
      this.sdk.enumerateDevices()
    ]).then(devices => {
      // @ts-ignore
      this.deviceSerial = devices[0];
      console.log("deviceSerial =" + this.deviceSerial);
    })
      .catch((error) => {
        console.log(error);
      });
  }

  getDeviceInfo() {
    // @ts-ignore
    const deviceInfoPromise: Promise<DeviceInfo> = this.sdk.getDeviceInfo(this.deviceSerial);
    this.deviceInfo = true;
    deviceInfoPromise.then((successObj) => {
      console.log(successObj);
      this.deviceID = successObj.DeviceID;
      this.deviceTechnology = Fingerprint.DeviceTechnology[successObj.eDeviceTech];
      this.deviceUIDType = Fingerprint.DeviceUidType[successObj.eUidType];
      this.deviceModality = Fingerprint.DeviceModality[successObj.eDeviceModality];
    })
      .catch((error) => {
        console.log(error);
      });
  }

  startCapture() {
    this.startAcquisitionDisabled = true;
    this.stopAcquisitionDisabled = false;
    this.continueApplicationDisabled = true;//this.clientEnrolled.length != 4;
    if(this.selectedFingerName === 'NO_FINGER'){
      this.verifyDisabled = true;
    }
    else{
      this.verifyDisabled = false;
    }
    console.log("Selected radio format:", this.radioFormat);

    const formats = {
      1: Fingerprint.SampleFormat.Raw,
      2: Fingerprint.SampleFormat.Intermediate,
      3: Fingerprint.SampleFormat.Compressed,
      5: Fingerprint.SampleFormat.PngImage
    };

    this.currentFormat = formats[2] || Fingerprint.SampleFormat.Intermediate;
    console.log("Starting capture with format:", this.currentFormat);

    this.sdk.startAcquisition(this.currentFormat)
      .then(() => {
        console.log("Scan a finger");
        this.status = "Scan a finger";
      })
      .catch((error: any) => {
        console.error("Error starting capture:", error);
        this.status = error.message;
      });
  }

  stopClientCapture() {
    this.startAcquisitionDisabled = false;
    this.stopAcquisitionDisabled = true;
    this.verifyDisabled = false;
    this.enrollDisabled = false;
    this.enrollmentError = "UNKNOWN";
    this.continueClientDisabled();
    console.log("stopCapture...");

    try {
      this.sdk.stopAcquisition();
      console.log("Capturing stopped..");
      this.status = "Capturing stopped";
      this.numberReadings = 0;
    } catch (error: any) {
      console.log(error.message);
      this.status = error.message;
    }
  }


  stopUserCapture() {
    this.startAcquisitionDisabled = false;
    this.stopAcquisitionDisabled = true;
    this.verifyDisabled = true;
    this.enrollDisabled = false;
    this.enrollmentError = "UNKNOWN";
    this.continueUserDisabled();
    console.log("stopCapture...");
    this.sdk.stopAcquisition().then(() => {
      console.log("Capturing stopped..");
      this.status = "Capturing stopped";
      this.numberReadings = 0;
    })
      .catch((error: any) => {
        console.log(error.message);
        this.status = error.message;
      });

  }

  sampleAcquired(s: any) {
    console.log("sampleAcquired..." + s);
    var samples = JSON.parse(s.samples);
    const finger = samples[0];

    console.log("sampleAcquired currentFormat..." + this.currentFormat);
    if (this.currentFormat == Fingerprint.SampleFormat.PngImage) {
      localStorage.setItem("imageSrc", "");
      localStorage.setItem("imageSrc", "data:image/png;base64," + Fingerprint.b64UrlTo64(finger));
      const vDiv = document.getElementById('imagediv');
      // @ts-ignore
      vDiv.innerHTML = "";
      const image = document.createElement("img");
      image.id = "image";
      // @ts-ignore
      image.src = localStorage.getItem("imageSrc");
      // @ts-ignore
      vDiv.appendChild(image);
      this.sampleData[this.numberReadings - 1] = Fingerprint.b64UrlTo64(finger);
    } else if (this.currentFormat == Fingerprint.SampleFormat.Raw) {
      console.log("processing raw...");
      localStorage.setItem("raw", "");
      var samples = JSON.parse(s.samples);
      var sampleData = Fingerprint.b64UrlTo64(samples[0].Data);
      var decodedData = JSON.parse(Fingerprint.b64UrlToUtf8(sampleData));
      localStorage.setItem("raw", Fingerprint.b64UrlTo64(decodedData.Data));
      const vRaw = document.getElementById('imagediv');
      // @ts-ignore
      vRaw.innerHTML = '<div id="animateText" >RAW Sample Acquired <br>' + Date() + '</div>';
      this.sampleData[this.numberReadings] = Fingerprint.b64UrlTo64(decodedData.Data);          //JSON.stringify(decodedData);
    } else if (this.currentFormat == Fingerprint.SampleFormat.Compressed) {
      console.log("processing wsq...");
      localStorage.setItem("wsq", "");
      var samples = JSON.parse(s.samples);
      var sampleData = Fingerprint.b64UrlTo64(samples[0].Data);
      var decodedData = JSON.parse(Fingerprint.b64UrlToUtf8(sampleData));
      localStorage.setItem("wsq", "data:application/octet-stream;base64," + Fingerprint.b64UrlTo64(decodedData.Data));

      const vWsq = document.getElementById('imagediv');
      // @ts-ignore
      vWsq.innerHTML = '<div id="animateText" style="display:none">WSQ Sample Acquired <br>' + Date() + '</div>';
      this.sampleData[this.numberReadings - 1] = Fingerprint.b64UrlTo64(decodedData.Data);      //JSON.stringify(decodedData);
    } else { //intermediate
      //      this.sampleData[this.numberReadings - 1] = Fingerprint.b64UrlTo64(finger.Data);
      this.sampleData[this.numberReadings - 1] = finger.Data;
      // var vImage = document.getElementById('image');
      // // @ts-ignore
      // vImage.remove();
      if (this.numberReadings == 4) {
        this.enrollDisabled = false;
        console.log("Enabling enroll button");
      }
      if (this.numberReadings >= 1) {
        if(this.selectedFingerName === 'NO_FINGER'){
          this.verifyDisabled = true;
        }else{
          this.verifyDisabled = false;
        }
        console.log("Enabling verify button");
      }

    }

  }

  enrollClient() {
    this.biometricsModel.fingerName = this.selectedFingerName;
    this.biometricsModel.fingerImage1 = this.sampleData[0];
    this.biometricsModel.fingerImage2 = this.sampleData[1];
    this.biometricsModel.fingerImage3 = this.sampleData[2];
    this.biometricsModel.fingerImage4 = this.sampleData[3];
    this.biometricsModel.enrolledBy = this.userService.userLoginName;
    this.biometricsModel.idNumber = this.biometricsInfo.idNumber;
    //  'this.biometricsModel.skipReason' is assigned to itself.
    //  this.biometricsModel.skipReason = this.biometricsModel.skipReason;
    this.biometricsModel.branchName = this.userService.userBranchName;

    console.log("Biometrics Model:---", this.biometricsModel);

    // Subscribe to enrollClientFinger method of biometricsService
    this.biometricsService.enrollClientFinger(this.biometricsModel).subscribe(response => {
      console.log("Enrolling Fingers");
      console.log("Enrollement Response:--------", response);

      // Assign errorCode and errorMessage from response to enrollmentError and enrollmentMessage respectively
      this.enrollmentError = response?.errorCode;
      this.enrollmentMessage = response?.errorMessage;

      if (this.enrollmentError != 'SUCCESS') {
        this.toastrService.error(`${this.enrollmentError} : ${this.enrollmentMessage}`, "Enrollment Error: ", { positionClass: 'toast-top-center', closeButton: true });
        setTimeout(() => {
          this.numberReadings = 0;
          this.toastrService.info(`Please try again.`, "Enrollment Retry: ", { positionClass: 'toast-top-center', closeButton: true });
        }, 2500);
        this.numberReadings = 0;
        this.sampleData = [];
        this.stopClientCapture();

      } else {
        // Reset selectedFingerName, stop client capture, and update enrolled and fingers list
        this.toastrService.success(`${this.enrollmentError} : ${this.enrollmentMessage}`, 'Enrollment Status', { positionClass: 'toast-top-center', closeButton: true });
        this.selectedFingerName = '';
        this.stopClientCapture();
        this.getClientEnrolledFingers();
        this.getFingersList();
        this.sampleData = [];

        // Enable/disable UI controls based on the number of enrolled fingers
        if (this.clientEnrolled.length === 3) {
          this.startAcquisitionDisabled = false;
          this.lstAllFingersDisable = true;
          // this.continueApplicationDisabled = false;
        } else {
          this.startAcquisitionDisabled = true;
          this.lstAllFingersDisable = false;
        }
      }
    });
  }

  verifyClientIntermediate() {
    // Extract first element of sampleData and convert it to a string
    if (!this.sampleData || this.sampleData.length == 0) {
      this.toastrService.warning('Finger could not be Verified or no finger provided.', '', { positionClass: 'toast-top-center' });
      return;
    }

    const sampleDataVerify = this.sampleData[0].toString();

    // Create a new biometricsModel object and assign values to its properties
    const biometricsModel = {
      fingerImage: sampleDataVerify,
      verifiedBy: this.userService.userLoginName,
      branchName: this.userBiometricsInfo.branchName,
      idNumber: this.biometricType == 'client' || this.biometricType == 'client-offer' ? this.userBiometricsInfo.idNumber : this.userBiometricsInfo
    };

    // Pass the biometricsModel to the verifyClientFinger method of biometricsService
    this.biometricsService.verifyClientFinger(biometricsModel).subscribe(response => {
      console.log(response);
      this.enrollmentError = response?.errorCode;
      this.enrollmentMessage = response?.errorMessage;
      this.enrollDisabled = true;
      this.numberReadings = 0;
      this.sampleData = [];

      const toastString = 'Verify Finger Response: ' + this.enrollmentError;
      this.quoteStoreService.setVerifiedMessage(this.enrollmentMessage);

      if(this.biometricType == 'client-offer'){
        if(!this.enrollmentMessage || this.enrollmentMessage == '') {
          this.verifyDisabled = true;
        }

        this.quoteStoreService.auditLogClientFingerVerification(this.biometricsModel.idNumber, this.enrollmentMessage).subscribe();
      }

      if (this.enrollmentError == 'FAILURE') {
        this.toastrService.warning(toastString, '', { positionClass: 'toast-top-center' });
      } else {
        this.toastrService.info(toastString, '', { positionClass: 'toast-top-center' });
        this.continueApplicationDisabled = false;
        this.verifiedClient = true;
        this.stopClientCapture();
      }
    });
  }

  verifyUserIntermediate() {
    if (!this.sampleData || this.sampleData.length == 0) {
      this.toastrService.warning('Finger could not be Verified or no finger provided.', '', { positionClass: 'toast-top-center' });
      return;
    }

    this.sampleDataVerify = this.sampleData.slice(0, 1).toString();
    this.biometricsModel.fingerImage = this.sampleDataVerify;
    this.biometricsModel.verifiedBy = this.userService.userLoginName;

    if (this.biometricType == 'client-offer') {
      this.biometricsModel.userName = this.userService.userLoginName;
    }
    else {
      this.biometricsModel.userName = this.userBiometricsInfo.loginName;
    }

    this.biometricsService.verifyUserFinger(this.biometricsModel).subscribe(response => {
      console.log(response);
      this.enrollmentError = response?.errorCode;
      this.enrollmentMessage = response?.errorMessage;
      this.enrollDisabled = true;
      this.numberReadings = 0;
      this.sampleData = [];

      if (this.biometricType == 'client-offer') {
      this.quoteStoreService.auditLogUserFingerVerification(this.biometricsModel.idNumber, this.enrollmentMessage).subscribe();
      }

      const toastString = 'Verify Finger Response: ' + this.enrollmentError;
      if (this.enrollmentError == 'FAILURE') {
        this.toastrService.warning(toastString, '', { positionClass: 'toast-top-center' });
      }
      else {
        this.toastrService.info(toastString, '', { positionClass: 'toast-top-center' });
        this.verifiedUser = true;
        this.stopUserCapture();
      }
    });
  }


  verifyBranchManager() {

    if(this.biometricsModel.bmUserName === '' || this.biometricsModel.bmUserName === null || this.biometricsModel.bmUserName === this.userService.userLoginName){
      this.toastrService.warning('Please Enter a valid Branch Manger Username.', '', { positionClass: 'toast-top-center' });
      this.sampleData = [];
      this.stopUserCapture();
      return;
    }

    if (!this.sampleData || this.sampleData.length == 0) {
      this.toastrService.warning('Finger could not be Verified or no finger provided.', '', { positionClass: 'toast-top-center' });
      return;
    }

    if(this.biometricsInfo.cellNumber == '0'){
      this.biometricsModel.cellNoExist = 'No';
    }else{
      this.biometricsModel.cellNoExist = 'Yes';
    }

    this.sampleDataVerify = this.sampleData.slice(0, 1).toString();
    this.biometricsModel.fingerImage = this.sampleDataVerify;
    this.biometricsModel.fingerName = this.selectedFingerName;
    this.biometricsModel.verifiedBy = this.biometricsModel.bmUserName;
    this.biometricsModel.userName = this.biometricsModel.bmUserName;
    this.biometricsModel.branchName = this.userService.userBranchName;

    this.biometricsModel.skipReason = 'No Biometrics, BM Override';

    // if(this.biometricsModel.bmUserName != ''){
      this.biometricsService.verifyUserFinger(this.biometricsModel).subscribe(response => {
        console.log(response);
        this.enrollmentError = response?.errorCode;
        this.enrollmentMessage = response?.errorMessage;
        this.enrollDisabled = true;
        this.numberReadings = 0;
        this.sampleData = [];

        const toastString = 'Verify Finger Response: ' + this.enrollmentMessage;
        if (this.enrollmentError == 'FAILURE') {
          this.toastrService.warning(toastString, '', { positionClass: 'toast-top-center' });
          this.bmVerified = false;
        }
        else {
          this.toastrService.info(toastString, '', { positionClass: 'toast-top-center' });
          this.bmVerified = true;
          this.skipRegistration(this.biometricsModel.skipReason);
          //this.dialogRef.close(this.biometricsModel);
        }
        this.stopUserCapture();
      });
    // }
    // else {
    //   this.toastrService.warning("Branch Username is required " , '', { positionClass: 'toast-top-center' });
    // }


  }


  getClientEnrolledFingers() {
    console.log("Retrieving Client Enrolled Fingers...");

    this.biometricsModel.idNumber = (this.biometricType == 'client' || this.biometricType == 'client-offer') ? this.biometricsInfo.idNumber : this.userBiometricsInfo;
    const currentDate = new Date();

    this.biometricsService.enrolledFingersDetails(this.biometricsModel.idNumber).subscribe(result => {
      console.log("Enrolled Data", result);
      this.clientEnrolled = result;
      this.numberReadings = 0;

      if (this.biometricType == "client" || this.biometricType == 'client-offer') {
        if (this.clientEnrolled.length == 4) {
          this.noBiometrics = false;
          this.lstAllFingersDisable = false;
          this.startAcquisitionDisabled = false;

          if (this.startAcquisitionDisabled == false) {
            this.continueApplicationDisabled = true;
          }
          else {
            this.continueApplicationDisabled = false;
          }

        } else if (this.clientEnrolled.length == 1 && this.selectedFingerName == NO_FINGER) {
          this.noBiometrics = false;
          this.lstAllFingersDisable = false;
          this.startAcquisitionDisabled = false;
          this.continueApplicationDisabled = false;
        } else {
          this.noBiometrics = true;
          this.lstAllFingersDisable = false;
          this.startAcquisitionDisabled = true;
        }
      }
    });
  }

  disableClientFinger(id: any) {
    this.biometricsModel.id = id;
    this.biometricsModel.username = this.userService.userLoginName;
    //this.biometricsModel.disableReason = this.biometricsModel.disableReason;

    this.biometricsService.disableFinger(this.biometricsModel).subscribe(
      (response) => {
        console.log("Response", response);

        this.enrollmentError = response?.errorCode;
        this.enrollmentMessage = response?.errorMessage;

        this.getClientEnrolledFingers();
      }
    );

    // this.biometricsService.updateAuditTrail(this.biometricsModel.idNumber, this.biometricsModel.disableReason, this.userService.userDisplayName).subscribe(
    //   (response) => {
    //     console.log("response ----", response);

    // });
  }


  disableAllClientFingers() {
    this.biometricsModel.idNumber = this.biometricsInfo.idNumber;
    this.biometricsModel.username = this.userService.userLoginName;

    this.biometricsService.disableAllClientFingers(this.biometricsModel).subscribe(responseData => {
      console.log("Response", responseData);

      this.enrollmentError = responseData?.errorCode;
      this.enrollmentMessage = responseData?.errorMessage;

      this.getClientEnrolledFingers();
    });
  }

  enrollUser() {
    this.biometricsModel.fingerName = this.selectedFingerName;
    this.biometricsModel.fingerImage1 = this.sampleData[0];
    this.biometricsModel.fingerImage2 = this.sampleData[1];
    this.biometricsModel.fingerImage3 = this.sampleData[2];
    this.biometricsModel.fingerImage4 = this.sampleData[3];
    this.biometricsModel.fingerImage5 = this.sampleData[4];
    this.biometricsModel.fingerImage6 = this.sampleData[5];
    this.biometricsModel.fingerImage7 = this.sampleData[6];
    this.biometricsModel.fingerImage8 = this.sampleData[7];
    this.biometricsModel.fingerImage9 = this.sampleData[8];
    this.biometricsModel.fingerImage10 = this.sampleData[9];
    this.biometricsModel.enrolledBy = this.userService.userDisplayName;
    this.biometricsModel.loginName = this.userBiometricsInfo.loginName;
    this.biometricsModel.branchName = this.userBiometricsInfo.branchName;
    this.biometricsService.enrollUserFinger(this.biometricsModel).subscribe(response => {
      this.enrollmentError = response?.errorCode;
      this.enrollmentMessage = response?.errorMessage;
      this.selectedFingerName = '';

      if (this.enrollmentError != 'SUCCESS') {
        this.toastrService.error(`${this.enrollmentError} : ${this.enrollmentMessage}`, "Enrollment Error: ", { positionClass: 'toast-top-center', closeButton: true });
        setTimeout(() => {
          this.numberReadings = 0;
          this.toastrService.info(`Please try again.`, "Enrollment Retry: ", { positionClass: 'toast-top-center', closeButton: true });
        }, 2500);
        this.numberReadings = 0;
        this.sampleData = [];
        this.stopUserCapture();

      } else {
        // Reset selectedFingerName, stop client capture, and update enrolled and fingers list
        this.toastrService.success(`${this.enrollmentError} : ${this.enrollmentMessage}`, 'Enrollment Status', { positionClass: 'toast-top-center', closeButton: true });
        this.selectedFingerName = '';
        this.stopClientCapture();
        this.getUserEnrolledFingers();
        this.getFingersList();
        this.sampleData = [];

        // Enable/disable UI controls based on the number of enrolled fingers
        if (this.userEnrolled.length === 3) {
          this.startAcquisitionDisabled = false;
          this.lstAllFingersDisable = true;
          // this.continueApplicationDisabled = false;
        } else {
          this.startAcquisitionDisabled = true;
          this.lstAllFingersDisable = false;
        }
      }

      this.stopUserCapture();
      this.getUserEnrolledFingers();
      this.getFingersList();
      this.sampleData = [];

      if (this.userEnrolled.length === 9) {
        this.startAcquisitionDisabled = false;
        this.lstAllFingersDisable = true;
      }
      else {
        this.startAcquisitionDisabled = true;
        this.lstAllFingersDisable = false;
      }
    });
  }


  getUserEnrolledFingers() {
    console.log("Retrieving User Enrolled Fingers...");
    // Set the userName based on the biometric type
    this.biometricsModel.userName = (this.biometricType === 'client-offer') ? this.userService.userLoginName : this.userBiometricsInfo.loginName;
    this.biometricsModel.idNumber = this.userBiometricsInfo.idNumber;
    this.biometricsModel.branchName = this.userBiometricsInfo.branchName;
    this.biometricsService.enrolledUserFingersDetails(this.biometricsModel).subscribe(result => {
      console.log("Enrolled Data", result);
      this.userEnrolled = result;
      this.numberReadings = 0;

      this.items = this.userEnrolled;
      if (this.userEnrolled && this.userEnrolled.length) {
        this.setPage(this.initialPage);
      }

      if (this.biometricType === "user") {
        // Check if all fingers have been enrolled
        if (this.userEnrolled.length === 10) {
          this.noBiometrics = false;
          this.lstAllFingersDisable = true;
          this.startAcquisitionDisabled = false;
          this.continueApplicationDisabled = false;
        } else {
          this.noBiometrics = true;
          this.lstAllFingersDisable = false;
          this.startAcquisitionDisabled = true;
        }
      }
    });
  }

  disableUserFinger(id: any) {
    this.biometricsModel.id = id;
    this.biometricsModel.username = this.userService.userLoginName;

    this.biometricsService.disableUserFinger(this.biometricsModel).subscribe(
      (response) => {
        console.log("Repsonse", response);

        this.enrollmentError = response?.errorCode;
        this.enrollmentMessage = response?.errorMessage;

        this.getUserEnrolledFingers();
      });
  }

  disableAllUserFingers() {
    this.biometricsModel.username = 'AsdA';
    this.biometricsModel.disabledBy = this.userService.userLoginName;

    this.biometricsService.disableAllUserFingers(this.biometricsModel).subscribe(response => {
      console.log("Repsonse", response);

      this.enrollmentError = response?.errorCode;
      this.enrollmentMessage = response?.errorMessage;

      this.getUserEnrolledFingers();
    });

  }

  openReader() {
    this.readerOpen = true;
    this.captureOpen = false;
    this.disableOpen = false;
    this.verificationOpen = false;
    this.userVerificationOpen = false;
    this.signatureOpen = false;
  }

  openCapture() {
    this.captureOpen = this.biometricType != 'client-offer';
    this.readerOpen = false;
    this.disableOpen = false;
    this.verificationOpen = this.biometricType == 'client-offer';
    this.signatureOpen = false;
    this.userVerificationOpen = false;
  }


  openUserCapture() {
    this.captureOpen = false;
    this.readerOpen = false;
    this.disableOpen = false;
    this.signatureOpen = false;
    this.verificationOpen = false;
    this.userVerificationOpen = (this.biometricType === 'client-offer');
  }

  openDisableFinger() {
    const {
      disableOpen,
      captureOpen,
      readerOpen,
      verificationOpen,
      signatureOpen
    } = this;

    this.disableOpen = true;
    this.captureOpen = false;
    this.readerOpen = false;
    this.verificationOpen = false;
    this.signatureOpen = false;
  }


  openVerification() {
    this.readerOpen = true;
    this.verificationOpen = this.captureOpen = this.disableOpen = this.startAcquisitionDisabled = false;
  }


  backToPreAuth() {
    this.dialogRef.close(this.biometricsModel);
  }

  close() {
    this.dialogRef.close();
  }

  continueApplication() {
    if (this.biometricsInfo.cellNumber == '0') {
      if (this.clientEnrolled.length == 0) {
        this.enableSkip = true;
        this.isCellVisible = false;
        this.biometricsModel.verifiedMessage = this.quoteStoreService.verifiedMessage;
      } else if (this.clientEnrolled.length == 1) {
        this.enableSkip = true;
        this.isCellVisible = false;
        this.skipBiometrics = false;
        this.biometricsModel.cellNoExist = 'No';
        this.biometricsModel.verifiedMessage = this.quoteStoreService.verifiedMessage;
      } else {
        this.isCellVisible = false;
        this.biometricsModel.cellNoExist = 'No';
        this.biometricsModel.verifiedMessage = this.quoteStoreService.verifiedMessage;
        this.closeDialog();
      }
    } else {
      if (this.clientEnrolled.length == 0) {
        this.enableSkip = true;
        this.isCellVisible = false;
        this.biometricsModel.verifiedMessage = this.quoteStoreService.verifiedMessage;
      } else if (this.clientEnrolled.length == 1) {
        this.isCellVisible = true;
        this.biometricsModel.cellNoExist = 'Yes';
        this.selectedFingerName = NO_FINGER;
        this.biometricsModel.verifiedMessage = this.quoteStoreService.verifiedMessage;
        this.closeDialog();
      } else {
        this.isCellVisible = true;
        this.biometricsModel.cellNoExist = 'Yes';
        this.biometricsModel.verifiedMessage = this.quoteStoreService.verifiedMessage;
        this.closeDialog();
      }
    }
  }

  proceed() {
    this.verificationOpen = this.userVerificationOpen = this.readerOpen = this.captureOpen = this.disableOpen = this.startAcquisitionDisabled = false;
    this.signatureOpen = true;

    this.getClientEnrolledFingers();
    this.getUserEnrolledFingers();
  }

  getClientFingersCount() {
    return this.clientEnrolled.length < 4;
  }

  getUserFingersCount() {
    return this.userEnrolled.length < 4;
  }

  // getFingersList() {
  //   this.lstAllFingers.forEach((element: { value: string; }) => {
  //     if (this.biometricsModel.fingerName == element.value) {
  //       this.lstAllFingers = this.lstAllFingers.filter((item: { value: string; }) => item != element);
  //     }
  //   });
  // }


  getFingersList() {
    //this.lstAllFingers.forEach((element: { value: string; }) => {

    const allFingersSelect = (this.allFinger as ElementRef<SelectControlValueAccessor>).nativeElement;
    const allfiungercontrol = this.allFinger as ElementRef<HTMLSelectElement>;
    if(allfiungercontrol && allfiungercontrol.nativeElement.options){
      const fingerOpti = allfiungercontrol.nativeElement.options as HTMLOptionsCollection;
      Array.from(fingerOpti).forEach((element: { value: string; }) => {
      if (this.biometricsModel.fingerName == element.value) {
        this.lstAllFingers = Array.from(fingerOpti).filter((item: { value: string; }) => item != element);
      }
      });
    }
  }

  continueClientDisabled() {
    this.continueApplicationDisabled = this.clientEnrolled.length < 3;
  }

  continueUserDisabled() {
    this.continueApplicationDisabled = this.userEnrolled.length < 3;
  }

  backToOffers() {
    this.enableSkip = false;
  }

  continueLoan() {
    const hasCellNumber = this.biometricsInfo.cellNumber !== '0';
    const hasNoSelectedFinger = this.selectedFingerName === NO_FINGER;

    this.enableSkip = true;
    this.isCellVisible = hasCellNumber && hasNoSelectedFinger;
  }


  onClientChange(event: any) {
    if (this.startAcquisitionDisabled) {
      this.startAcquisitionDisabled = false;
    } else {
      if (!this.selectedFingerName) {
        this.startAcquisitionDisabled = true;
      } else if (this.selectedFingerName === NO_FINGER) {
        this.startAcquisitionDisabled = true;
        this.skipBiometrics = true;
      }
    }

    if (this.selectedFingerName === NO_FINGER) {
      this.skipBiometrics = true;
    }
  }

  onUserChange(event: any) {
    if (!this.startAcquisitionDisabled) {
      this.startAcquisitionDisabled = this.userEnrolled.length > 3;
    } else {
      this.startAcquisitionDisabled = !this.selectedFingerName;
    }
  }

  skipRegistration(data: any) {
    this.biometricsModel.skipReason = data;

    if (this.biometricsInfo.cellNumber != '0' && this.selectedFingerName == NO_FINGER) {
      if (this.biometricsModel.skipReason != null && this.biometricsModel.skipReason != 'N/A') {
        this.submitted = true;
        this.enableSkip = true;
        this.biometricsModel.cellNoExist = 'Yes';
        this.biometricsModel.idNumber = this.biometricsInfo.idNumber;
        this.biometricsModel.fingerName = this.selectedFingerName;
        this.biometricsModel.enrolledBy = this.userService.userLoginName;
        this.biometricsService.skipBiometrics(this.biometricsModel).subscribe(response => {
          console.log("Response:", response);
          this.enrollmentError = response?.errorCode;
          this.enrollmentMessage = response?.errorMessage;
          this.dialogRef.close(this.biometricsModel);
        });
      } else {
        this.submitted = false;
      }
    } else {
      if (this.biometricsModel.skipReason != null && this.biometricsModel.skipReason != 'N/A') {
        this.enableSkip = true;
        this.biometricsModel.cellNoExist = 'No';
        this.biometricsModel.idNumber = this.biometricsInfo.idNumber;
        this.biometricsModel.fingerName = this.selectedFingerName;
        this.biometricsModel.enrolledBy = this.userService.userLoginName;
        this.biometricsService.skipBiometrics(this.biometricsModel).subscribe(response => {
          console.log("Response:", response);
          this.enrollmentError = response?.errorCode;
          this.enrollmentMessage = response?.errorMessage;
          return this.dialogRef.close(this.biometricsModel);
        });
      } else {
        this.enableSkip = false;
      }
    }
  }


  fetchFinger(selectedFingerName: any) {
    this.selectedFingerName = selectedFingerName;

    const selectedFinger = this.clientEnrolled.find((item) => item.fingerName === this.selectedFingerName);

    if (selectedFinger) {
      this.biometricsService.enrolledSingleFingersDetails(selectedFinger.id).subscribe(result => {
        console.log("Response", result);
        this.lstSignedFingerDetails = result;
      });
    }
  }


  fetchConsultantFinger(selectedConsultantFingerName: string): void {
    this.selectedConsultantFingerName = selectedConsultantFingerName;
    const data: any = this.findUserEnrolledItem();

    this.biometricsService.enrolledConsultantSingleFingersDetails(data?.id).subscribe((result: any) => {
      console.log("Response", result);
      this.lstConsultantSignedFingerDetails = result;
    });
  }

  findUserEnrolledItem(): any {
    return this.userEnrolled.find((item: any) => item.fingerName === this.selectedConsultantFingerName);
  }

  signDocument(selectedFingerName: any, selectedConsultantFingerName: any) {
    const foundFinger1 = this.lstSignedFingerDetails.find(x => x.fingerName === selectedFingerName);
    const foundFinger2 = this.lstConsultantSignedFingerDetails?.find(x => x.fingerName === selectedConsultantFingerName);

    if (foundFinger1 || foundFinger2) {
      this.biometricsModel.id = this.quoteStoreService.quoteWorkbook.id;

      if (foundFinger1) {
        this.biometricsModel.fingerId = foundFinger1.id;
        if (selectedFingerName === NO_FINGER) {
          this.biometricsModel.fingerId = 0;
        } else {
          this.biometricsModel.fingerId = foundFinger1.id;
        }
      }

      if (foundFinger2) {
        this.biometricsModel.userFingerId = foundFinger2.id;
      }

      const saveFingerIdPromise = new Promise<boolean>((resolve, reject) => {
        this.quoteStoreService.saveFingerId(this.biometricsModel).subscribe((res: any) => {
          console.log('Result: ---', res);
          if (res) {
            this.quoteStoreService.quoteWorkbook.fingerId = res;
            resolve(true);
            return;
          } else {
            resolve(false);
          }
        });
      });

      console.log("biometric data------", this.biometricsModel);

      saveFingerIdPromise.then((found: boolean) => {
        if (found) {
          setTimeout(() => {
            this.isNokComplete = true;
            this.doSigning();
          }, 300);
        }
      });
    } else {
      console.log('Not found');
    }
  }

  doSigning() {
    if (this.isNokComplete) {
      this.closeDialog();
    }
  }

  onSkipReasonChange(event: any) {
    this.submitted = !event || event === 'N/A';
  }

  closeDialog() {
    this.dialogRef.close(this.biometricsModel);
  }

  closeScanFingers() {
    try {
      this.sdk.stopAcquisition();
      console.log("Capturing stopped..");
      this.status = "Capturing stopped";
      this.numberReadings = 0;
      this.dialogRef.close();
    } catch (error: any) {
      console.log(error.message);
      this.status = error.message;
    }
  }

  // fetchDisableReason(event:any){
  //   if(event.target.value != ''){
  //     this.reasonSelected = true;
  //     this.disableReason = event.target.value;
  //     this.biometricsModel.disableReason = this.disableReason;
  //   }
  //   else{
  //     this.reasonSelected = false;
  //     this.toastrService.error(`No reason selected.`, 'Disable Client Finger', { positionClass: 'toast-top-center', closeButton: true });
  //   }


  // }

}
