<header>
  <!--Sidebar-->
    <!-- <app-sidenav [isFixed]="false" [DisplayedUsername]="'userDisplay'" [userBranchName]="'userBranchName'"></app-sidenav> -->
  <!--SideBar-->

  <!-- Navbar -->
  <nav id="main-navbar" class="navbar py-0 navbar-expand-lg navbar-dark bg-gradient sticky-top">
    <!-- Container wrapper -->
    <div class="container-fluid">
    <!-- Toggle button -->
    <button mdbBtn class="navbar-toggler " type="button"
      (click)="navbarSupportedContent.toggle()"
      [attr.aria-expanded]="!navbarSupportedContent.collapsed"
      aria-controls="collapseNavbar">
      <i class="fas fa-bars"></i>
    </button>

    <!-- Collapsible wrapper -->
    <div mdbCollapse #navbarSupportedContent="mdbCollapse" class="collapse navbar-collapse" id="collapseNavbar">
      <!-- Navbar brand -->
      <a class="navbar-brand mb-n2" href="#">
        <img class="finlogo" alt="fmb logo" style="height: 71px; width:175px;" src="assets/finbond_logo.png"
        loading="lazy" />
      </a>

      <!-- Left links -->
      <ul *ngIf="MenuList$ | async" class="navbar-nav me-auto mb-1 mb-lg-0">
        <ng-container *ngFor="let menu of MenuList$ | async">
          <div *ngIf="menu.hasSubmenu; then submenu else normal">
          </div>
          <ng-template #normal>
            <li class="nav-item nav-pills">
              <a [routerLink]="menu.component" style="color: #fafafa;" mdbWavesEffect class="nav-link hover-zoom hover-shadow rounded-1" [ariaCurrentWhenActive]="'page'" routerLinkActive="active"
              >{{menu.menu}}</a>
            </li>
          </ng-template>
          <ng-template #submenu>
            <!-- <button mat-button class="label-small" [matMenuTriggerFor]="myMenu" (menuOpened)="getSubmenus(menu)" color="primary">{{menu.menu}}</button> -->
            <!-- Dropdown -->
            <li mdbDropdown class="nav-item nav-pills dropdown hover-shadow hover-zoom">
              <a
                class="nav-link dropdown-toggle"
                style="color: #fafafa;"
                id="navbarDropdownMenuLink"
                role="button"
                mdbDropdownToggle
                aria-expanded="false"
              >
              {{menu.menu}}
            </a>
              <ul mdbDropdownMenu  class="dropdown-menu nav-link" aria-labelledby="navbarDropdownMenuLink"       >
                <li *ngFor="let asubmenu of menu.submenus; let i=index">
                  <a [routerLink]="[asubmenu.component]" class="nav-item dropdown-item">
                    {{asubmenu.submenu}}</a>
                  </li>
              </ul>
            </li>
              <!-- <ul class="navbar-nav me-auto mb-1 mb-lg-0">
                <li class="nav-link nav-dropdown">
                  <a class="nav-item hover-zoom"  mdbWavesEffect
                  (menuOpened)="getSubmenus(menu)" color="primary">{{menu.menu}}</a>
                </li>
              </ul> -->
            <!-- <button mat-raised-button class="hover-zoom" [matMenuTriggerFor]="myMenu" mdbWavesEffect
              (menuOpened)="getSubmenus(menu)" color="primary">{{menu.menu}}</button> -->
            <!-- <mat-menu #myMenu="matMenu">
              <div *ngFor="let asubmenu of activeSubmenus; let i=index">
                <a mat-menu-item [routerLink]="[asubmenu.component]" class="nav-link label-small hover-zoom" mdbWavesEffect>
                  {{asubmenu.submenu}}</a>
              </div>
            </mat-menu> -->
          </ng-template>
        </ng-container>
      </ul>
          <!-- <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    <a class="nav-link" href="#">Dashboard</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">Team</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">Projects</a>
                  </li>
                </ul> -->
      <!-- Left links -->
    </div>
    <!-- Collapsible wrapper -->

    <!-- Right elements -->
    <div class="d-flex align-items-center">
      <!-- Icon -->
      <!-- <a class="text-reset me-3" href="#">
            <i class="fas fa-xs fa-shopping-cart"></i>
          </a> -->
          <!-- <span class="spacer"></span> -->
            <!-- Notifications -->
            <!-- <span class="spacer"></span> -->
            <!-- Avatar -->
            <!-- <div class="dropdown"> -->
              <!-- <a class="dropdown-toggle d-flex align-items-center hidden-arrow" href="#" id="navbarDropdownMenuAvatar"
                role="button" data-mdb-toggle="dropdown" aria-expanded="false">
                <img src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp" class="rounded-circle" height="25"
                  alt="Black and White Portrait of a Man" loading="lazy" />
              </a> -->
          <ng-container>
            <div *ngIf="isLoggedIn$; then isuser else nouser"></div>
            <ng-template #nouser>
            </ng-template>
            <ng-template #isuser>
              <span class="d-none d-md-flex align-items-center justify-content-center">
                <label class="fs-5 hover-zoom"
                  mdbPopover="{{usertoken}}"
                  mdbPopoverTitle="User token"
                  [class.text-white]="useractioncolor ===  3 || useractioncolor === 5">
                  {{DisplayedUsername}}
                </label>
                <div class="nav-item hover-zoom hover-shadow ms-1">
                  <a type="button" class="btn btn-sm rounded-1"
                  data-mdb-ripple-color="#ffffff"
                  style="color: #fafafa; box-shadow: none; cursor: pointer;"  (click)="logout()"> <!--*ngIf="isLoggedIn"-->
                    <i class="fas fa-power-off fa-fw "></i>
                    Logout
                  </a>
              </div>
              </span>
            </ng-template>
          </ng-container>
          <label class="version-label label-small text-sm-end">{{version}}</label>
      <!-- </div> -->
    </div>
    <!-- Right elements -->
    </div>
    <!-- Container wrapper -->
    </nav>
    <!-- Navbar -->
</header>
<app-apiloader></app-apiloader>
