import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HideLinksService {

  hideSubject  = new BehaviorSubject(null);
  constructor() { }


  addUser(user: any){
    this.hideSubject.next(user)
  }
}
