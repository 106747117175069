import { HostListener, Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, map, merge, Observable, Observer } from 'rxjs';

@Injectable({
  providedIn:'root'
})
export class ApiloaderService {
  public isLoading = new BehaviorSubject(false);

  public isOffline = new BehaviorSubject(false);
  public isAPIOffline = new BehaviorSubject(false);

  constructor() {
    this.createOnline$().subscribe((isOnline) => {
      this.isOffline.next(!isOnline);
    });
  }

  @HostListener('window:online', ['$event'])
  createOnline$() {
    return merge(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }
}
