// loader-interceptor.service.ts
import {
  HttpContextToken,
  HttpEvent, HttpHandler,  HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { finalize, Observable, tap, timeout } from 'rxjs';
import { ApiloaderService } from 'src/app/shared/loaders/apiloader/apiloader.service';


export const CACHING_ENABLED = new HttpContextToken<boolean>(() => true);

// export function LoaderInterceptorFn(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
//   const loaderService = inject(ApiloaderService);


//   return next(req).pipe(tap(event => {
//     if (event.type === HttpEventType.Response) {
//       console.log(req.url, 'returned a response with status', event.status);
//     }
//   }));
//   loaderService.isLoading.next(requests.length > 0);
//   if (req.context.get(CACHING_ENABLED)) {
//     console.log(req.url);
//     const resp = new HttpResponse({
//       body: 'response body',
//     });
//   } else {
//     return next(req);
//   }
// }

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  constructor(private loaderService: ApiloaderService) { }

  loaderFn = (req: HttpRequest<any>, next: HttpHandler) =>
    {
      return this.intercept(req,next);
    };

  private removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  private logRequestTime(startTime: number, url: string, method: string) {
    const requestDuration = `${performance.now() - startTime}`;
    console.info(`${method} ${url} - ${requestDuration} milliseconds`);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requests.push(req);
    const begin = performance.now();
    this.loaderService.isLoading.next(true);
    console.warn('Loader Handled >> ', req.url);
    return next.handle(req).pipe(
      //const subscription = next.handle(req).pipe(
      tap(evt => {
      //tap((evt: HttpEvent<any>) => {
      //let showdate = new Date().toLocaleString();
      // if (evt instanceof HttpResponse) {
      //     if(evt.body && evt.body.success)
      //     this.errorService.handleString(evt.body.success.message);
      //     //this.toastrService.success(`Msg(evt.body.success.message):${evt.body.success.message}`, `${showdate}: (title) ${evt.body.title}`, { positionClass: 'toast-top-center' });
      // }
      }),
      finalize(() => {
        this.logRequestTime(begin, req.url, req.method);
        this.removeRequest(req);
      })
    );
    // .subscribe(
    //   event => {
    //     // if (event instanceof HttpRequest) {
    //     //   req = req.clone({
    //     //     withCredentials: true,
    //     //   });
    //     // }
    //     if (event instanceof HttpResponse) {
    //       //this.removeRequest(req);
    //       observer.next(event);
    //     }
    //   });
    // remove request from queue when cancelled
    //   return () => {
    //     //this.removeRequest(req);
    //     subscription.unsubscribe();
    //   };
    // });
  }

  // private totalRequests = 0;

  // constructor(
  //   public loader: LoadingSpinService) { }

  // intercept(
  //   request: HttpRequest<unknown>,
  //   next: HttpHandler
  // ): Observable<HttpEvent<unknown>> {
  //   this.loader.show();
  //   return next.handle(request).pipe(
  //     finalize(() => {
  //       this.loader.hide();
  //     })
  //   );
  // }
}
