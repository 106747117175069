/* eslint-disable @typescript-eslint/no-empty-function */

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MdbUiKitModule } from 'src/app/mdb-ui-kit.module';

import { ClientHeaderComponent } from 'src/app/modules/client-header/client-header.component';
//import { SessionExpiredComponent } from './session-expired/session-expired.component';

import { LMSDatePickerModule } from 'src/app/core/constants/datepicker-adapter';
import { LmsPaginateComponent } from './ui/lms-paginate/lms-paginate.component';
import { LmsGridviewComponent } from 'src/app/shared/ui/lms-gridview/lms-gridview.component';
import { LMSDialogComponent } from './dialogs/lms-dialog/lms-dialog.component';

import { HighlightDirective } from './directives/highlight.directive';
import { LMSSelectOptionDirective } from './directives/lms-selectOption.directive';

import { LMSDialogHandlerService } from './lmsdialog-handler.service';

import { LmsClientSearchComponent, PageCardheadComponent } from './ui';
import { LMS_NoSpecialsDirective, LMS_NoPasteDirective, NumbersOnlyDirective, UniquePayslipDirective } from './directives';
import { ZAcurrencyPipe, ZARcurrencyPipe, NoSpecialCharPipe,UpperCaseSearchListPipe } from './pipes';
import { RestServicesModule } from '../core/rest-services/rest-services.module';
import { ClientDetailsService } from '../core/rest-services/client-details.service';
import { QuoteStoreService } from '../core/rest-services/quote-store.service';

const EXPORTED_DECLARATIONS = [
  ClientHeaderComponent,
  PageCardheadComponent,
  LmsClientSearchComponent,
  LmsGridviewComponent,
  LmsPaginateComponent,
  LMSDatePickerModule,
  ZAcurrencyPipe,
  ZARcurrencyPipe,
  NoSpecialCharPipe,
  LMSDialogComponent,
  HighlightDirective,
  UpperCaseSearchListPipe,
  NumbersOnlyDirective,
  LMSSelectOptionDirective,
  LMS_NoPasteDirective,
  LMS_NoSpecialsDirective
];

@NgModule({
  imports:[
    CommonModule,
    MdbUiKitModule,
    MatDialogModule,
    DatePipe,
    RestServicesModule,
    ...EXPORTED_DECLARATIONS
  ],
  providers: [
    LMSDialogHandlerService,
    HighlightDirective,
    NumbersOnlyDirective,
    LMSSelectOptionDirective,
    LMS_NoSpecialsDirective,
    LMS_NoPasteDirective,
    UniquePayslipDirective,
    UpperCaseSearchListPipe,
    ZARcurrencyPipe,
    ZAcurrencyPipe
  ]
})
export class LMSSharedUiModule {}
