import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import { JwtRequest } from '../models/jwt-request';
//import { JwtResponse } from '../models/jwt-response';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { JwtHelperService } from '@auth0/angular-jwt';

const TOKEN_NAME = 'id_token';
const EXPIRES_AT = 'expires_at';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  constructor(
    private router: Router,
    private keycloak: KeycloakService) { }

  // getLoggedInUserDetails(uuid: string) {
  //   return new Promise(resolve => {
  //     this.getUserByUuid(uuid)
  //       .pipe(
  //       catchError(this.handleError),
  //         // map((user: any) => {

  //         // })
  //         ).subscribe((user:any)=>{
  //        if(user){
  //           this.setLMSProfileObs(user);
  //           resolve(user);
  //        }
  //     })
  //   });
  // }

  login(uuid: string) {
    if(!this.keycloak.isLoggedIn()) {
      this.keycloak.login();
    }

    if(this.keycloak.isTokenExpired()) {
      this.keycloak.updateToken().then(toeknupdated => {
        if(toeknupdated){
          this.keycloak.getToken().then(token => {
            localStorage.setItem(TOKEN_NAME,token);
            this.tokenExpVal(token);
          });
          return;
        }

        this.logout();
      });
    }

    // this.keycloak.getKeycloakInstance().loadUserProfile().then(async (profile: KeycloakProfile) => {
    //   // this.storageService.clean();
    //    if (!profile) {

    //    }
    // })

    //let jwtRequest: keycloak.JwtRequest = { username: username, password: password };

    //this.keycloak.getToken().then((value)=>this.setSession(value));
  }

  private setSession(authResult: string) {

    //const expiresAt = authResult.expirationDate;
    //console.log("Token expires at " + expiresAt);
    //console.log("Token date and time is " + this.dateService.getShortDateAndTimeDisplay(expiresAt));

    localStorage.setItem(TOKEN_NAME, authResult);
    //localStorage.setItem(EXPIRES_AT, JSON.stringify(expiresAt.valueOf()));
  }

  private tokenExpVal(token: string): boolean | string {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    // Other functions
    const expirationDate = helper.getTokenExpirationDate(token);
    localStorage.setItem(EXPIRES_AT, expirationDate?.toLocaleString() || '');
    const isExpired = helper.isTokenExpired(token);

    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return Math.floor((new Date()).getTime() / 1000) >= expiry;
}

  clearStorage() {
    this.keycloak.clearToken();
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem(EXPIRES_AT);
  }

  logout() {
    this.keycloak.logout();
    this.clearStorage();
    this.router.navigate(['/']);
  }

  isTokenExpired(): boolean {

    return this.keycloak.isTokenExpired();

  }

  isLoggedIn(): boolean {
    let loggedIn: boolean = false;

    if (this.token()) {
      loggedIn = !this.isTokenExpired();
    }

    return loggedIn;
  }

  isLoggedOut(): boolean {
    return !this.isLoggedIn();
  }

  private getExpiration(): number {
    let expiresAt!: number | 0;

    const expiration = localStorage.getItem(EXPIRES_AT);

      if (expiration) {
          expiresAt = JSON.parse(expiration);
      }

      return expiresAt;
  }

  token(): string {
    return localStorage.getItem(TOKEN_NAME) || '';
  }
}
