import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError, TimeoutError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApiloaderService } from 'src/app/shared/loaders/apiloader/apiloader.service';
import { LoadingSpinService } from 'src/app/shared/loaders/spinner/loadspinner.service';
import { environment } from 'src/environments/environment';
import { ErrorhanderService } from '../services/error-handler.service';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private apiloader: ApiloaderService,
    public loaderService: LoadingSpinService,
    private errorService: ErrorhanderService,
    private toastrService: ToastrService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let handled = false;
    return next.handle(request).pipe(
      //const subscription = next.handle(req).pipe(
      //tap(evt => {
      tap((returnedError) => {
        if(returnedError instanceof HttpErrorResponse){
          if(returnedError.status === 504)
          {
            this.apiloader.isAPIOffline.next(true);
            this.loaderService.stopLoading();

            if(environment.showtrace ) {
              console.error('API/Server ERROR >> ', returnedError);
              this.toastrService.error(returnedError.message,'API/Server OFFLINE',{disableTimeOut:true, positionClass:'toast-top-right', tapToDismiss: true});
            } else {
              console.error('API/Server ERROR: ', returnedError.statusText);
              this.toastrService.error(`${returnedError.statusText}`,'API/Server OFFLINE',{disableTimeOut:true, positionClass:'toast-top-right', tapToDismiss: true});
            }
            handled = true;
          }

          if (returnedError.url?.endsWith('doLoanCreate')) {
            handled = true;
            let errorMessage = '';
            // NUPAY Error response
            errorMessage = returnedError.error.message || returnedError.message;

            return of(returnedError);
            console.error('NupayResult ERROR:: ', errorMessage);
            const newerror = new Error(errorMessage);
            return throwError(() => { return newerror; });
          }

          this.apiloader.isAPIOffline.next(false);
          if(isDevMode()){
            console.warn('Error catch Handled >> ', returnedError);
          }

          let errorMessage = '';
          if (handled) {
            return of(returnedError);
          }


          handled = false;
          // **  ERROR EVENT ** //
          if (returnedError.error instanceof ErrorEvent) {
            errorMessage = `Error: ${returnedError.error!.message || returnedError.message}`;
            console.error(errorMessage);
            const newerror = new Error(errorMessage);
            this.errorService.handle(newerror, `ERROR Event.`, true);

            return throwError(() => { return newerror; });
          }

          // **  TIMEOUT ERROR ** //
          else if (!handled && (returnedError instanceof TimeoutError)) {
            this.toastrService.warning('Request timeout occured', `Request timed-out`, { positionClass: 'toast-top-center', disableTimeOut: true, closeButton: true });
            this.errorService.handleOffline(returnedError.message, 'Server not responding');
            this.loaderService.stopLoading();
            return throwError(() => {
              return returnedError;
            });
          }
          // **  HTTP RESPONSE ERROR ** //
          else if (!handled && returnedError instanceof HttpErrorResponse) {
            // **  PROGRESS ERROR ** //
            if (!handled && returnedError.status == 0 && returnedError.error instanceof ProgressEvent) {
              if (!returnedError.url?.endsWith('doLoanCreate')) {
                handled = this.handleProgressEvent(returnedError);
              }
            }

            switch (returnedError.status) {
              case 302: // Found REsponse
                return throwError(() => {
                  const error: any = new Error('Duplicate Record Found!');
                  this.errorService.handle(error, `LMS Server Duplicate not allowed!!`, true);
                  return returnedError;
                });
              case 417:
                if (!returnedError.url?.endsWith('doLoanCreate')) {
                  // NUPAY Error response
                  console.error('LMS SERVER ::', returnedError.error.message);
                  const dispMsg = returnedError.error.message || returnedError.message;

                  const newerror = new Error(dispMsg);
                  this.errorService.handle(newerror, `LMS Server [response]`, true);

                  return throwError(() => {
                    const error: any = new Error(dispMsg);
                    return error;
                  });
                }
                break;
              case 501:
                this.errorService.handleOffline(returnedError.error.message, 'Server OFFLINE');
                handled = true;
                break;
              case 400: // Bad Request
                handled = this.handleBadRequest(returnedError);
                break;
              case 504: // Bad Gateway
                handled = this.handleBadGateway(returnedError);
                break;
              default:
                if ((returnedError.error && returnedError.error.type == 'error') && returnedError.statusText == 'Unauthorized') {
                  this.errorService.handleString("Unauthorized recieved. Require login");
                  handled = true;
                } else if ((returnedError.error && returnedError.error.type == 'error') && returnedError.statusText === 'Unknown Error') {
                  //Offline
                  this.errorService.handleOffline(returnedError.message || returnedError.error.message, 'Server OFFLINE');
                  handled = true;
                } else {
                  if (returnedError.error && !returnedError.error.result) {
                    handled = this.errorService.handleServerSideError(returnedError);
                  }

                  if ((returnedError.error?.trace || returnedError.error?.stackTrace) && (!environment?.production && !!environment?.showtrace)) {
                    this.errorService.handleStack(returnedError.error?.trace || returnedError.error?.stackTrace);
                  }
                }

                if (!handled && returnedError.error.name == 'TimeoutError') {
                  this.toastrService.warning('Timeout has occured', `Timeout has occured, refresh?`, { positionClass: 'toast-top-center', disableTimeOut: true, closeButton: true, progressAnimation: 'increasing' });
                  this.loaderService.stopLoading();
                  handled = true;
                }
                break;
            }
          }

          this.loaderService.stopLoading();
          if (!handled) {
            //errorMessage = `${returnedError.error!.path}[${returnedError.status}]: ${returnedError.error!.message || returnedError.message || returnedError.error?.error!.message}`;
            errorMessage = `Unhandled error ${returnedError?.status}: ${returnedError?.error!.message || returnedError?.message}`;
            console.error(errorMessage);

            return throwError(() => {
              const error: any = new Error(errorMessage);
              this.errorService.handle(error);
              return error;
            });
          } else {
            return of(returnedError);
          }
        }
      })
    );
  }

  private handleProgressEvent(error: HttpErrorResponse): boolean {
    const message1 = error.url ?? '';
    const hdrString1 = `Network error`;
    const showdateStr1 = `${new Date().toLocaleString()}`;
    this.toastrService.error(`${showdateStr1} : ${message1}`, `${hdrString1}`, { positionClass: 'toast-top-center', disableTimeOut: true, closeButton: true, progressAnimation: 'increasing' });
    this.loaderService.stopLoading();
    this.apiloader.isAPIOffline.next(true);
    return true;
  }

  private handleBadRequest(error: HttpErrorResponse): boolean {
    const message1 = error.error.message ?? '';
    const hdrString1 = 'Bad Request error';
    const showdateStr1 = `${new Date().toLocaleString()}`;
    this.toastrService.error(`${showdateStr1} Server response:<br><br>${message1}`, `${hdrString1}`, { enableHtml:true, positionClass: 'toast-top-center', timeOut: 18000, newestOnTop: true, disableTimeOut: true, tapToDismiss: true });
    this.loaderService.stopLoading();
    return true;
  }

  private handleBadGateway(error: HttpErrorResponse): boolean {
    const message1 = error.url ?? '';
    const hdrString1 = `Bad Gateway error : ` + error.statusText;
    const showdateStr1 = `${new Date().toLocaleString()}`;
    this.toastrService.error(`${showdateStr1} : ${message1}`, `${hdrString1}`, { positionClass: 'toast-top-center', timeOut: 18000, newestOnTop: true, disableTimeOut: true, tapToDismiss: true });
    this.loaderService.stopLoading();
    this.apiloader.isAPIOffline.next(true);
    return true;
  }
}
