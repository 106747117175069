  <form  class="form-container center-screen" [formGroup]="riskScoresForm">
    <table class="table table-striped table-hover table-sm caption-top" [style.width.px]="600">
      <caption>Search</caption>
      <tbody class="field-4column-label">
        <tr>
          <td style="padding-top: 11px;">Parameter Type</td>
          <td>
            <select formControlName="parameterType" [(ngModel)]="riskScoreModel.parameterType"
              (change)="findByParameterType(riskScoreModel.parameterType)">
              <option *ngFor="let pType of lstParameterTypes;" [value]="pType.value">{{pType.description}}
              </option>
            </select>
            &nbsp;
            &nbsp;
            <button mat-raised-button class="btn btn-primary btn-rounded" mdbRipple color="primary" (click)="findAll()" class="mat-16">Show All</button>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="table table-striped table-hover table-sm caption-top" [style.width.px]="650">
      <caption>Scorecard</caption>
      <thead>
        <tr>
          <th>Parameter Type</th>
          <th>Description</th>
          <th>Score</th>
          <th style="width: 30px;">Edit </th>
          <th style="width: 30px;">Delete </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let score of items | paginate: { itemsPerPage: pageSize, currentPage: pager.currentPage };">
          <td>{{score.parameterType}}</td>
          <td>{{score.description}}</td>
          <td align="center" style="font-weight: 600;">{{score.score}}</td>
          <td align="center">
            <button mat-icon-button class="btn btn-primary btn-rounded" mdbRipple color="primary" (click)="editRiskScore(score)">
              <i class="fas fa-xs fa-pen"></i>
            </button>
          </td>
          <td align="center">
            <!--removeScorecard(score.id);-->
            <button mat-icon-button class="btn btn-primary btn-rounded" mdbRipple color="primary" (click)="removeRiskScore(score)">
              <i class="fas fa-xs fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="lms-pager my-2">
      <div *ngIf="pager.pages && pager.pages?.length > 1" class="btn-toolbar" role="toolbar" aria-label="LMS Pager group">
        <div class="btn-group btn-group-sm" role="group" aria-label="Move Previous group">

          <button type="button" class="btn btn-primary rounded-start" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5; margin: 0;" (click)="setPage(1)" [disabled]="pager.currentPage === 1">First</button>
          <button type="button" class="btn btn-primary rounded-0" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5; margin: 0;"(click)="setPage(pager.currentPage - 1)" [disabled]="pager.currentPage === 1">Previous</button>
        </div>
        <div class="btn-group btn-group-sm" role="group" aria-label="PageNumbers">
          <button *ngFor="let page of pager.pages" type="button" data-mdb-ripple-color="#7a7676" style="background-color:#f5f9ff; margin: 0; color: #7a7676;" [ngClass]="{active:pager.currentPage === page}" (click)="setPage(page)" class="btn btn-outline-secondary page-item number-item  rounded-0">{{page}}</button>
        </div>
        <div class="btn-group btn-group-sm" role="group" aria-label="Move Next group">
          <button type="button" class="btn btn-primary rounded-0" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5; margin: 0;" (click)="setPage(pager.currentPage + 1)" [disabled]="pager.currentPage === pager.totalPages">Next</button>
          <button type="button" class="btn btn-primary rounded-end" data-mdb-ripple-color="#ffffff" style="background-color:#3f51b5;margin: 0;" (click)="setPage(pager.totalPages)" [disabled]="pager.currentPage === pager.totalPages">Last</button>
        </div>
      </div>
    </div>

    <div class="center-screen m-1 p-1">
      <button mat-raised-button class="btn btn-primary btn-rounded" mdbRipple color="primary" class="mat-16" (click)="addRiskScore()"
        matTooltip="Add a new risk score" matTooltipClass="custom-tooltip">
        Add a new risk score
      </button>
    </div>
  </form>
