/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, inject, OnInit } from "@angular/core";
import { ApiloaderService } from "./apiloader.service";

@Component({
  selector: "app-apiloader",
  templateUrl: "./apiloader.component.html",
  styleUrls: ["./apiloader.component.css"],
  standalone: true
})
export class ApiloaderComponent implements OnInit {
  loaderService = inject(ApiloaderService);

  loading?: boolean;
  isOffline?: boolean;
  apiOffline?: boolean;

  constructor() {
    this.loaderService.isLoading.subscribe((v) => {
      // console.log(`loadingsub:${v}`);
      this.loading = v;
    });

    this.loaderService.isOffline.subscribe((ofl) => {
      this.isOffline = ofl;
    });

    this.loaderService.isAPIOffline.subscribe((ofl) => {
      this.apiOffline = ofl;
    });
  }

  ngOnInit() { }
}
