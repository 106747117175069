<div class="center-screen">
  <p><b>Client is high risk and an email will be sent to Compliance. Please complete this form.</b></p>
  <form [formGroup]="complainceForm">
    <table class="table table-striped table-light table-hover table-sm mb-3 caption-top" style="width:800px">
      <caption>Client Detials</caption>
      <tbody>
        <tr align="left">
          <td style="width: 25%;">Full names</td>
          <td>
            {{preAuthorisationModel.fullNames}}
          </td>

          <td>Surname</td>
          <td>
            {{preAuthorisationModel.lastName}}
          </td>
        </tr>

        <tr>
          <td>ID Number</td>
          <td>
            {{preAuthorisationModel.idNumber}}
          </td>

          <td>Risk Score</td>
          <td>
            {{preAuthorisationModel.riskScore}}
          </td>
        </tr>

        <tr>
          <!-- Occupation -->
          <td>Occupation</td>
          <td>
            <input formControlName="occupation" placeholder="Client's occupation"
              (keypress)="global_utility.omit_special_char($event)"
              [(ngModel)]="preAuthorisationModel.occupation" required>
            <div *ngIf="validateClientInput.occupation.errors?.required && submitted" class="error-label">
              Client's occupation is required.
            </div>
          </td>


          <td>Gender</td>
          <td>
            <select formControlName="gender" style="width: 250px; height: 26px;" placeholder="Gender"
              [(ngModel)]="preAuthorisationModel.gender" required>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            <div *ngIf="validateClientInput.gender.errors?.required && submitted" class="error-label">
              Gender is required.
            </div>
          </td>
        </tr>

        <tr>
          <!-- Marital Status -->
          <td>Marital Status</td>
          <td>
            <select formControlName="maritalStatus" style="width: 250px; height: 26px;"
              placeholder="Client's marital status" [(ngModel)]="preAuthorisationModel.maritalStatus" required>
              <option *ngFor="let status of lstMaritalStatus" [value]="status.value">{{status.title}}
              </option>
            </select>
            <div *ngIf="validateClientInput.maritalStatus.errors?.required && submitted" class="error-label">
              Marital status is required.
            </div>
          </td>
          <!-- Occupation -->
          <!--    <td>Occupation</td>
    <td>
      <input formControlName="occupation"  placeholder="Client's occupation"
        (keypress)="($event.charCode <= 93 && $event.charCode >= 65 || $event.charCode == 32) || ($event.charCode <= 122 && $event.charCode >= 97)"
        [(ngModel)]="preAuthorisationModel.occupation" required>
      <div *ngIf="validateClientInput.occupation.errors?.required && submitted"
        class="error-label">
        Client's occupation is required.
      </div>
    </td>
    -->
          <!-- Nationality -->
          <td>Nationality</td>
          <td>
            <select formControlName="nationality" [(ngModel)]="preAuthorisationModel.nationality"
              (change)="onNationalitySelect($event)" required>
              <option *ngFor="let nat of lstNationality" [value]="nat.countryCode">{{nat.countryName}}
              </option>
            </select>

            <div *ngIf="validateClientInput.nationality.errors?.required && submitted" class="error-label">
              Client's nationality is required.
            </div>
          </td>
        </tr>

        <tr>
          <td>Address line 1</td>
          <td>
            <input placeholder="Address line 1" formControlName="homeAdressLine1"
              [(ngModel)]="preAuthorisationModel.homeAdressLine1" required type="text"
              (keypress)="global_utility.omit_special_char($event)">
            <div *ngIf="validateClientInput.homeAdressLine1.errors?.required && submitted" class="error-label">
              Address line 1 is required
            </div>
          </td>

          <td>Address line 2</td>
          <td>
            <input placeholder="Address line 2" formControlName="homeAdressLine2"
              [(ngModel)]="preAuthorisationModel.homeAdressLine2" type="text" (keypress)="global_utility.omit_special_char($event)">
          </td>
        </tr>

        <tr>
          <td>Suburb</td>
          <td>
            <!-- style="width: 120px;" -->
            <input formControlName="homeAdressSuburb" placeholder="Suburb"
              [(ngModel)]="preAuthorisationModel.homeAdressSuburb" type="text" (keypress)="global_utility.omit_special_char($event)">
            <!-- <div *ngIf="validateClientInput.homeAdressSuburb.errors?.required && submitted"
        class="error-label">
        Suburb is required
      </div> -->
          </td>

          <td>Town / City</td>
          <td>
            <input placeholder="Town/City *" formControlName="homeAdressTown"
              [(ngModel)]="preAuthorisationModel.homeAdressTown" required>
            <div *ngIf="validateClientInput.homeAdressTown.errors?.required && submitted" class="error-label">
              Town is required
            </div>
          </td>
        </tr>

        <tr>
          <td>Area code</td>
          <td>
            <input type="number" maxlength="4" placeholder="Area code *" formControlName="homeAdressAreaCode"
              [(ngModel)]="preAuthorisationModel.homeAdressAreaCode" required type="numbers"
              (keypress)="global_utility.numberOnly($event)">
            <div *ngIf="validateClientInput.homeAdressAreaCode.errors?.required && submitted" class="error-label">
              Area code is required
            </div>
          </td>

          <td> Province</td>
          <td>
            <select formControlName="homeAdressProvince" [(ngModel)]="preAuthorisationModel.homeAdressProvince"
              required>
              <option *ngFor="let prov of lstProvinces" [value]="prov.value">{{prov.provName}}
              </option>
            </select>
            <div *ngIf="validateClientInput.homeAdressProvince.errors?.required && submitted" class="error-label">
              Province is required
            </div>
          </td>
        </tr>
    </table>
  </form>
  <br>
  <button mat-raised-button class="btn btn-primary btn-rounded" color="primary" (click)="close()"> Close </button>
  &nbsp;
  <button mat-raised-button class="btn btn-primary btn-rounded" color="primary" (click)="submit()"> Submit </button>
</div>
