<div class="container-sm">
<!-- <div class="card p-2" >
  <div class="card-body p-2" > -->
    <div class="idlight ms-auto me-auto rounded-1 shadow-2-strong my-3 p-2 d-none d-sm-flex w-50" style="max-width:400px">
<form [formGroup]="clientSearchForm" >
  <span class="input-group w-auto align-items-center mx-3 my-2 ">
      <label for="idNumber">Id Number</label>
        <!-- <label class="mat-mdc-floating-label label-small text-black"> Id Number </label> -->
        <input mdbValidate
        mdbInput type="search" class="form-control ms-3 rounded"
      placeholder="Enter Id Number" formControlName="idNumber"
      (keypress)="global_utility.numberOnly($event)"
      required maxlength="13" autofocus />

      <div class="input-group-text border-0 ">
      <button mat-raised-button class="btn btn-primary" type="button" mdbRipple color="primary"
        (click)="getClient2()">Filter</button>
      </div>
    <div class="center-screen"
      *ngIf="idNumber.errors && (idNumber?.dirty || idNumber?.touched)">

      <mdb-error mdbLabel *ngIf="idNumber!.errors?.notfound" >
        Id Number not found!
      </mdb-error>
      <mdb-error *ngIf="idNumber!.errors?.maxlength" >
        Must be a valid Id Number
      </mdb-error>
      <mdb-error *ngIf="idNumber!.errors?.wrongBranch" >
        Incorrect Branch selection!
      </mdb-error>
    </div>
  </span>
</form>

      <!-- <table class="table table-fixed table-light table-sm caption-top" >
        <tbody>
          <tr>
            <td style="width: 25%;" class="text-center">Id Number</td>
            <td>
              <input placeholder="Enter Id Number" formControlName="idNumber" maxlength="13" autofocus
                (keypress)="($event.charCode >= 48 && $event.charCode < 58)" required>
                <div *ngIf="clientSearchForm && clientSearchForm.controls['idNumber'].invalid" class="error-label">
                  <div *ngIf="clientSearchForm.controls['idNumber']!.errors?.notfound" class="error-label">
                    Id Number not found!
                  </div>
                  <div *ngIf="clientSearchForm.controls['idNumber']!.errors?.maxlength" class="error-label">
                    Must be a valid Id Number
                  </div>
                  <div *ngIf="clientSearchForm.controls['idNumber']!.errors?.wrongBranch" class="error-label">
                    Incorrect Branch selection!
                  </div>
                </div>
            </td>
            <td style="width: 25%;" class="justify-content-center">
              <button mat-raised-button class="btn btn-primary btn-rounded ms-auto" type="button"
                mdbRipple color="primary"
                (click)="getClient()">Filter</button>
            </td>
          </tr>
        </tbody>
      </table> -->
    </div>
    <ng-container *ngIf="isSearchClient">
      <div class="d-flex align-items-center">
        <div class="container mt-2">
          <div class="row justify-content-center center-section ">
            <table class="table table-striped table-light table-hover table-sm lms-table__grid caption-top"
              style="width: 950px; margin: auto; text-align: start; vertical-align: bottom;">
              <caption>
                Client / Account filter
              </caption>
              <thead class="center-header">
                <tr>
                  <th>Application #</th>
                  <th>Id Number</th>
                  <th>Name </th>
                  <th>Surname</th>
                  <th>Cell Number</th>
                  <th>Status</th>
                  <th>User</th>
                  <th *ngIf="resetLoan"> Manage Reference No.</th>
                  <th> View </th>
                </tr>
              </thead>
              <tbody role="rowgroup" class="mat-mdc-row">
                <tr>
                  <td>{{preAuthorisationModel.id}}</td>
                  <td>{{preAuthorisationModel.idNumber}}</td>
                  <td>{{preAuthorisationModel.firstName}}</td>
                  <td>{{preAuthorisationModel.lastName}}</td>
                  <td>{{preAuthorisationModel.cellNumber}}</td>
                  <td class="mat-mdc-cell ">
                    <ng-container *ngIf="!!preAuthorisationModel.loanStatus">
                    <span  class="rounded-pill d-inline badge"
                    [ngClass]="{'badge-primary': (preAuthorisationModel.loanStatus === 'Active'),
                    'badge-info':(preAuthorisationModel.loanStatus === 'New' || preAuthorisationModel.loanStatus=== 'Pending'),
                    'badge-success':(preAuthorisationModel.loanStatus === 'Submitted'),
                    'badge-dark': (preAuthorisationModel.loanStatus === 'Cancelled' || preAuthorisationModel.loanStatus === 'Rejected'),
                    'badge-secondary': (preAuthorisationModel.loanStatus === 'Disbursed' || preAuthorisationModel.loanStatus === 'NuPay')}">
                    {{preAuthorisationModel.loanStatus}}</span>
                  </ng-container>


                  </td>
                  <!-- <td style="width: 100px;">{{preAuthorisationModel.loanStatus}}</td> -->
                  <td>{{preAuthorisationModel.userName}}</td>
                  <td *ngIf="!resetLoan" style="width: 100px;">
                      <a (click)="viewSearchedClient(preAuthorisationModel)"
                        data-mdb-ripple-color="#ffffff"
                        style="background-color:#3f51b5; color: #fafafa;"
                      class="btn btn-sm btn-primary rounded-1"
                      [textContent]="preAuthorisationModel.status === 'Submitted' ? 'View' : 'Continue'"></a>

                    <!-- <button mat-raised-button class="btn btn-primary rounded-1" mdbRipple type="button" color="primary"
                      [textContent]="preAuthorisationModel.status == 'Submitted' ? 'View' : 'Continue'"
                      (click)="viewSearchedClient(preAuthorisationModel)">
                    </button> -->
                  </td>

                  <td *ngIf="resetLoan">
                    <input #referenceNumber placeholder="Manage Engine Reference No." required>
                  </td>

                  <td *ngIf="resetLoan">
                    <button mat-raised-button class="btn btn-primary rounded-1" mdbRipple type="button" color="primary"
                      (click)="resetLoanApplication(preAuthorisationModel)">Reset
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
<!-- </div>
</div> -->
