<app-page-cardhead [PageName]="'Current model comparison'" [PageHeadWidth]=600></app-page-cardhead>

<div class="card center-screen m-2">
  <div class="card-body p-1">
        <table class="table table-borderlesstable-sm caption-top" style="width: 550px;">
          <caption>Model comparison</caption>
          <tbody>
            <tr>
              <td>
                <table class="table  table-striped  table-sm caption-top" style="width: 365px;">
                  <caption>
                    Quote Workbook
                  </caption>
                  <tbody>
                    <tr>
                      <td>Id</td>
                      <td>{{quoteWorkBook?.id}}</td>
                    </tr>
                    <tr>
                      <td>Application #</td>
                      <td>{{quoteWorkBook.quoteId}}</td>
                    </tr>
                    <tr>
                      <td>Names</td>
                      <td>{{quoteWorkBook.firstName || ''}} {{quoteWorkBook.lastName|| ''}}</td>
                    </tr>
                    <tr>
                      <td>Client ID number</td>
                      <td>{{quoteWorkBook.idNumber || ''}}</td>
                    </tr>
                    <tr>
                      <td>Client status</td>
                      <td>{{quoteWorkBook.clientStatus || ''}}</td>
                    </tr>
                    <tr>
                      <td>Client cell number</td>
                      <td>{{quoteWorkBook.cellNumber || ''}}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>
                <table class="table  table-striped  table-sm caption-top" style="width: 365px;">
                  <caption>
                    Pre Auth Model
                  </caption>
                  <tbody>
                    <tr>
                      <td>Id</td>
                      <td>{{preAuthModel.id}}</td>
                    </tr>
                    <tr>
                      <td>Application #</td>
                      <td>{{preAuthModel.quoteId}}</td>
                    </tr>
                    <tr>
                      <td>Names</td>
                      <td>{{preAuthModel.firstName}} {{preAuthModel.surname}}</td>
                    </tr>
                    <tr>
                      <td>Client ID number</td>
                      <td>{{preAuthModel.idNumber}}</td>
                    </tr>
                    <tr>
                      <td>Client status</td>
                      <td>{{preAuthModel.clientStatus}}</td>
                    </tr>
                    <tr>
                      <td>Client cell number</td>
                      <td>{{quoteWorkBook.cellNumber}}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <div class="btn-group" style="cursor: pointer;">
                <td>
                <input
                mdbRipple
                  mdbRadio
                  type="radio"
                  class="btn-check"
                  name="selectedmodel"
                  id="quoteWorkbook"
                  autocomplete="off"
                  [valueAsNumber]="quoteWorkBook.id"
                  [checked]="true"
                />
                <label class="btn btn-secondary" for="quoteWorkbook">Use Workbook: {{quoteWorkBook.id}}</label>
              </td>
              <td>
                <input
                mdbRipple
                  mdbRadio
                  type="radio"
                  class="btn-check"
                  name="selectedmodel"
                  id="preAuthModel"
                  [valueAsNumber]="preAuthModel.id"
                  autocomplete="off"
                />
                <label class="btn btn-secondary" for="preAuthModel">Use Pre Auth: {{preAuthModel.id}}</label>
              </td>
              </div>
              <!-- <mat-radio-group>
                <td>
                  <mat-radio-button class="radio-button align-content-center" style="width: 120px;" [value]="product" matTooltipClass="custom-tooltip"
                    color="primary" (change)="onOfferSelect(product)" ></mat-radio-button>
                </td>
              </mat-radio-group>               -->
            </tr>
          </tbody>
        </table>
  </div>
  <div class="card-footer center-screen">
    <button mat-raised-button type="button" class="me-2" (click)="close()" color="primary"> Close </button>
    <button [disabled]="selectedmodel == 0" mat-raised-button class="mat-raised-button mat-accent" type="button"
    (click)="continueApplication()">
      Continue </button>
  </div>
</div>

