/* eslint-disable prefer-const */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ClientDetailsService, QuoteStoreService } from '@lmsrestServices';
import { AppUtilityService } from 'src/app/app-utility.service';
import { PreAuthorisationModel } from 'src/app/core/models/pre-authorisation.model';
import { LMSPageTitleService } from 'src/app/core/services/lmspage-headertitle.service';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor } from '@angular/common';
import { DisableControlDirective } from '../../directives/disableControl.directive';
import { LMSSelectOptionDirective } from '../../directives/lms-selectOption.directive';

@Component({
    selector: 'app-agent-compliance-notification-dialog',
    templateUrl: './agent-compliance-notification-dialog.component.html',
    styleUrls: ['./agent-compliance-notification-dialog.component.css'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule,  NgIf, NgFor, MatButtonModule, LMSSelectOptionDirective]
})
export class AgentComplianceNotificationDialogComponent implements OnInit {
  agentComplianceNotificationForm: FormGroup = new FormGroup({});
  // lstGenders = GENDERS;
  // lstMaritalStatus = COMPLIANCEMARITALSTATUS;
  lstNationality: any[];
  // lstProvinces = COMPLIANCEPROVINCES;
  clientDetails: any;
  preAuthorisationModel: PreAuthorisationModel = new PreAuthorisationModel();
  submitted = false;
  countryWght: number;

 constructor(
  private formBuilder: FormBuilder,
  public quoteStoreService: QuoteStoreService,
  private clientSearch: ClientDetailsService,
  public dialogRef: MatDialogRef<AgentComplianceNotificationDialogComponent>,
private lmsPageTitleService: LMSPageTitleService,
public global_utility: AppUtilityService
) {
    this.lmsPageTitleService.setPageTitle('Compliance Notification');

  }

    ngOnInit(): void {
      this.preAuthorisationModel = this.clientSearch.clientResult;
      this.preAuthorisationModel.fullNames = this.clientSearch.clientResult.firstName;
      this.preAuthorisationModel.surname = this.clientSearch.clientResult.lastName;
      this.preAuthorisationModel.userName = this.clientSearch.clientResult.createdBy
      this.preAuthorisationModel.quoteId = this.clientSearch.clientResult.id;
      this.agentComplianceNotificationForm = this.formBuilder.group({
        'occupation': [this.preAuthorisationModel.occupation, [Validators.required]],
        'gender': [this.preAuthorisationModel.gender, [Validators.required]],
        'maritalStatus': [this.preAuthorisationModel.maritalStatus, [Validators.required]],
        'nationality': [this.preAuthorisationModel.nationality, [Validators.required]],
        'adressLine1': [this.preAuthorisationModel.homeAdressLine1, [Validators.required]],
        'adressLine2': [this.preAuthorisationModel.homeAdressLine2],
        'suburb': [this.preAuthorisationModel.homeAdressSuburb, [Validators.required]],
        'town': [this.preAuthorisationModel.homeAdressTown, [Validators.required]],
        'areaCode': [this.preAuthorisationModel.homeAdressAreaCode, [Validators.minLength(4)]],
        'province': [this.preAuthorisationModel.homeAdressProvince, [Validators.required]]
      });

      this.fetchComplianceData(this.preAuthorisationModel);

    }

  get validateClientInput() {
    return this.agentComplianceNotificationForm.controls;
  }

  onNationalitySelect(event: any) {
    if (event.target.value == 'OTHER') {
      console.log(event.target.value);
      this.clientSearch.findNationalCountries().subscribe(
        (res: any) => {
          this.lstNationality = res;
        })
    } else if (event.target.value == 'South Africa') {
      this.preAuthorisationModel.riskCountryValue = 'South Africa';
      this.preAuthorisationModel.nationality = 'South Africa';
      this.preAuthorisationModel.countryScore = 10 * this.countryWght;
      this.preAuthorisationModel.setTotalScore();
    } else if (event.target.value == "Botswana" ||
      event.target.value == "Lesotho" ||
      event.target.value == "Mozambique" ||
      event.target.value == "Namibia" ||
      event.target.value == "Swaziland" ||
      event.target.value == "Zimbabwe") {
      this.preAuthorisationModel.riskCountryValue = event.target.value;
      this.preAuthorisationModel.nationality = event.target.value
      this.preAuthorisationModel.countryScore = 30 * this.countryWght;
      this.preAuthorisationModel.setTotalScore();
    } else {
      console.log(event.target.value)
      let natCountry = event.target.value;
      for (const country of this.lstNationality) {
        if (event.target.value == country.countryName) {
          this.preAuthorisationModel.nationality = country.countryName;
          natCountry = country.countryName;
          console.log(natCountry, "..natCountry")
          break;
        }
      }
    }
  }


  fetchComplianceData(data: any){
    data = this.preAuthorisationModel;

    this.clientSearch.findExistingComplianceData(data)
    .subscribe({
      next: (result: any) => {
        this.preAuthorisationModel.homeAdressLine1= result.occupation;
        this.preAuthorisationModel.homeAdressLine1 = result.homeLine1;
        this.preAuthorisationModel.homeAdressLine2 = result.homeLine2;
        this.preAuthorisationModel.homeAdressSuburb = result.homeSuburb;
        this.preAuthorisationModel.homeAdressTown = result.homeTown;
        this.preAuthorisationModel.homeAdressAreaCode = result.homeAreaCode;
        this.preAuthorisationModel.homeAdressProvince = result.homeProvince;

        this.agentComplianceNotificationForm.patchValue({
        occupation: result.occupation,
        adressLine1: result.homeLine1,
        adressLine2: result.homeLine2,
        suburb: result.homeSuburb,
        town: result.homeTown,
        areaCode: result.homeAreaCode,
        province: result.homeProvince,
      });
      }
    });

  }

  submit(){
    this.submitted = true;
    if (this.agentComplianceNotificationForm.valid) {
      this.preAuthorisationModel.homeAdressLine1 = this.agentComplianceNotificationForm.value.adressLine1;
      this.preAuthorisationModel.homeAdressLine2 = this.agentComplianceNotificationForm.value.adressLine2;
      this.preAuthorisationModel.homeAdressAreaCode = this.agentComplianceNotificationForm.value.areaCode;
      this.preAuthorisationModel.homeAdressProvince = this.agentComplianceNotificationForm.value.province;
      this.preAuthorisationModel.maritalStatus = this.agentComplianceNotificationForm.value.maritalStatus;
      this.preAuthorisationModel.occupation = this.agentComplianceNotificationForm.value.occupation;
      this.preAuthorisationModel.gender = this.agentComplianceNotificationForm.value.gender;
      this.preAuthorisationModel.homeAdressTown = this.agentComplianceNotificationForm.value.town;
      this.preAuthorisationModel.homeAdressSuburb = this.agentComplianceNotificationForm.value.suburb;
     // this.preAuthorisationModel.nationality = this.agentComplianceNotificationForm.value.nationality;
      this.dialogRef.close(this.preAuthorisationModel);
    }
  }

  omit_special_char(event: any) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
