import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MdbUiKitModule } from 'src/app/mdb-ui-kit.module';
import { LMSDialogComponent } from '../dialogs/lms-dialog/lms-dialog.component';
//import { LmsClientSearchComponent } from './lmsclient-search/lmsclient-search.component';
import { LmsGridviewComponent } from 'src/app/shared/ui/lms-gridview/lms-gridview.component';
import { LmsPaginateComponent } from './lms-paginate/lms-paginate.component';
import { PageCardheadComponent } from 'src/app/shared/ui/pageheader-card/pageheader-card.component';
//import { SessionExpiredComponent } from './session-expired/session-expired.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { HighlightDirective } from '../directives/highlight.directive';
import { UpperCaseInputDirective } from '../directives/uppercaseonly.directive';
import { NoSpecialCharPipe } from '../pipes/specialchar.pipe';
import { UpperCaseSearchListPipe } from '../pipes/uppercase-search.pipe';
import { ZAcurrencyPipe } from '../pipes/zacurrency.pipe';
import { ZARcurrencyPipe } from '../pipes/zarcurrency.pipe';
import { AppUtilityService } from 'src/app/app-utility.service';
import { MatDialogModule } from '@angular/material/dialog';
import { ClientHeaderComponent } from 'src/app/modules/client-header/client-header.component';
import { LMSDialogHandlerService } from '../lmsdialog-handler.service';
import { LmsClientSearchComponent } from './lmsclient-search/lmsclient-search.component';

const EXPORTED_DECLARATIONS = [
  //PageCardheadComponent,
  LmsPaginateComponent,
  ZAcurrencyPipe,
  ZARcurrencyPipe,
  NoSpecialCharPipe,
  UploadFileComponent,
  LMSDialogComponent,
  // LMSFileUploaderComponent,
  // SessionExpiredComponent,
  HighlightDirective,
  UpperCaseSearchListPipe,
  UpperCaseInputDirective
];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PageCardheadComponent,
        MdbUiKitModule,
        MatDialogModule,
        LmsGridviewComponent,
        ClientHeaderComponent,
        LmsClientSearchComponent,
        ...EXPORTED_DECLARATIONS
    ],
    exports: [
      PageCardheadComponent,
      LmsPaginateComponent,
      LmsGridviewComponent,
      LMSDialogComponent,
      ZAcurrencyPipe,
      ZARcurrencyPipe,
      HighlightDirective,
      NoSpecialCharPipe,
      UpperCaseSearchListPipe,
      UpperCaseInputDirective,
      ClientHeaderComponent,
      LmsClientSearchComponent
    ]
})
export class LMSSharedUiModule {
  static forRoot(): ModuleWithProviders<LMSSharedUiModule> {
    return {
      ngModule: LMSSharedUiModule,
      providers: [
        {provide: AppUtilityService, useClass: AppUtilityService},
          ZAcurrencyPipe,
          ZARcurrencyPipe,
      ]
    };
  }
 }

