<ng-container *ngIf="preauthModel" [formGroupName]="controlKey">
	<div class="card">
		<h5 class="card-header text-center"
			[ngClass]="(sallabel && sallabel === 'Pensioner') ? 'pension-card' : (sallabel && sallabel === 'Salaried') ? 'sal-card': ''">
			{{sallabel}} information
		</h5>
		<div class="card-body">
			<table id="tblsalariedinfo" class="table table-striped table-sm mb-1">
				<tbody class="field-4column-label">
          <tr>
						<td>
							<label for="employer">Employer Name</label>
						</td>
						<td>
							<!-- (empSelected)="opepreAuthorisaionModel.employer = $event.employerName" -->
							<span [ngClass]="updateEmployment ? 'badge-warning':'badge-success'"
								class="badge p-1 rounded">
								<app-branch-employers-search [searchValue]="employerNametxt" [debounceTime]="300"
									[frmControlName]="'employer'" [dontAdd]="true" required
									(empSelected)="doEmployerConfirm($event)"
									(addnewRequired)="goforNew()">{{employerNametxt}}</app-branch-employers-search>
								<i class="text-emphasis">{{(updateEmployment)? 'new':'existing'}}</i>
								<div *ngIf="(this.employerDetailsModel?.id === 0) && Submitted">
									<small class="text-danger">Employer selection is required.</small>
								</div>

							</span>
							<!-- <input formControlName="employer" placeholder="Employer's Name *" hidden
                [(ngModel)]="preAuthorisationModel.employer" (keypress)="omit_special_char($event)" required> -->
							<!-- <div *ngIf="!this.employerDetailsModel || this.employerDetailsModel.id === 0">

							</div> -->
						</td>

						<td>
							<label for="employmentType">Employment Type</label>
						</td>
						<td>
							<select formControlName="employmentType" (change)="toggleEmploymentType()"
              lmsSelectOption [constantName]="'EMPLOYMENTTYPE'"
              required>
								<!-- <option *ngFor="let employ of lstEmploymentType" [value]="employ.value">
									{{employ.description}}
								</option> -->
							</select>
							<div *ngIf="employmentType?.invalid && Submitted">
								<div *ngIf="employmentType?.errors?.required">
									<small class="text-danger">Employment type required.</small>
								</div>
								<div *ngIf="preauthModel!.employmentType === 'Unemployed'">
									<small class="text-danger">Can't proceed with application.</small>
								</div>
							</div>
						</td>
					</tr>
					<!-- Employer -->
					<!-- <tr>
						<td>
							<label for="employmentType">Employment Type</label>
						</td>
						<td>
							<select
								formControlName="employmentType"
								(change)="toggleEmploymentType()"
								required
							>
								<option
									*ngFor="let employ of lstEmploymentType"
									[value]="employ.value"
								>
									{{employ.description}}
								</option>
							</select>
							<div *ngIf="employmentType?.invalid && Submitted">
								<div *ngIf="employmentType?.errors?.required">
									<small class="text-danger">Employment type required.</small>
								</div>
								<div *ngIf="preauthModel!.employmentType == 'Unemployed'">
									<small class="text-danger"
										>Can't proceed with application.</small
									>
								</div>
							</div>
						</td>
					</tr> -->
					<!-- EMPLOYMENT -->
					<tr *ngIf="employmentType.value === 'Permanent'">
						<td>
							<label>Employment Start Date</label>
						</td>
						<td *ngIf="employmentType.value === 'Permanent'">
							<input [matDatepicker]="picker" (focus)="picker.open()" placeholder="YYYY-MM-DD"
								formControlName="employmentStartDate" style="width: 120px;"
								(dateChange)="PayDateSelected($event)"
								[required]="employmentType.value === 'Permanent'? 'required': false" />
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-datepicker #picker></mat-datepicker>
							<div *ngIf="employmentStartDate?.invalid || invalidDate">
								<div *ngIf="employmentStartDate?.errors?.required">
									<small class="text-danger">Employment start date is required
									</small>
								</div>
								<div *ngIf="invalidDate">
									<small class="text-danger">{{payDateError}}</small>
								</div>
							</div>
						</td>
					</tr>
					<!-- CONTRACT START DATE and END DATE -->
					<tr *ngIf="employmentType.value !== 'Permanent'">
						<td>
							<label>Contract Start Date</label>
						</td>
						<td *ngIf="employmentType.value !== 'Permanent'">
							<input [matDatepicker]="picker1" [max]="maxDate" (focus)="picker1.open()"
								placeholder="YYYY-MM-DD" style="width: 120px;" formControlName="contractStartDate"
								[required]="employmentType.value !== 'Permanent'"
								(dateChange)="PayDateSelected($event)" />
							<mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
							<mat-datepicker #picker1 startView="year" [startAt]="maxDate"></mat-datepicker>
							<div *ngIf="contractStartDate?.invalid  || invalidDate">
								<div *ngIf="contractStartDate?.errors?.required && Submitted">
									<small class="text-danger">Contract start date is required</small>
								</div>
								<div *ngIf="invalidDate">
									<small class="text-danger">{{payDateError}}</small>
								</div>
							</div>
						</td>
						<td>
							<label>Contract End Date</label>
						</td>
						<td *ngIf="employmentType.value !== 'Permanent'">
							<input [matDatepicker]="picker2" placeholder="YYYY-MM-DD" (focus)="picker2.open()"
								style="width: 120px;" formControlName="contractEndDate"
								[required]="employmentType.value !== 'Permanent'"
								(dateChange)="PayEndDateSelected($event)" />
							<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
							<mat-datepicker #picker2 startView="year"></mat-datepicker>
							<div *ngIf="contractEndDate?.invalid">
								<div *ngIf="contractEndDate?.errors?.required && Submitted">
									<small class="text-danger">Contract end date is required</small>
								</div>
							</div>
							<div *ngIf="invalidEndDate">
								<small class="text-danger">{{payDateError}}</small>
							</div>
						</td>
					</tr>
					<!-- WAGE FREQUENCY -->
					<tr>
						<td>
							<label>Wage Frequency</label>
						</td>
						<td>
							<select formControlName="wageFrequency"
              (change)="togglewageFrequency()"
              lmsSelectOption [constantName]="'WAGEFREQUENCY'"
              required>
								<!-- <option *ngFor="let wage of lstWageFrequency" [value]="wage.value">
									{{wage.description}}
								</option> -->
							</select>
							<div *ngIf="f.wageFrequency?.invalid && Submitted">
								<div *ngIf="f.wageFrequency?.errors?.required">
									<small class="text-danger">Wage frequency is required.</small>
								</div>
							</div>
						</td>
						<!-- PAY DAYs -->
						<td>
							<label>Pay Day</label>
						</td>
						<td>
							<div>
								<select formControlName="payDate" style="width: 130px;"
                lmsSelectOption [constantName]="f.wageFrequency.value === 'Monthly'? 'PAYDATES': 'PAYDAYOFWEEK'"
									required>
									<!--<div *ngIf="f.wageFrequency?.value === 'Monthly'; then lmPayDates else lmPayDays">
									</div>
									 <ng-template #lmPayDates>
										<option *ngFor="let pDate of lstWageFreqPayDates" [value]="pDate.value">
											{{pDate.description}}
										</option>
									</ng-template>
									<ng-template #lmPayDays>
										<option *ngFor="let pDate of lstpayDates" [value]="pDate.value">
											{{pDate.description}}
										</option>
									</ng-template> -->
								</select>
							</div>
							<!-- <div *ngIf="f.wageFrequency?.value !== 'Monthly'">
								<select
									formControlName="payDate"
									style="width: 130px;"
									required
								>


								</select>
							</div>-->
							<div *ngIf="f.payDate?.invalid && Submitted">
								<div *ngIf="f.payDate?.errors?.required">
									<small class="text-danger">Salary pay date is required</small>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<label>Do you work on Saturdays?</label>
						</td>
						<td>
							<select formControlName="workSaturday" required>
								<option value="true">Yes</option>
								<option value="false">No</option>
							</select>
						</td>
					</tr>
					<!-- Industry Type -->
					<tr>
						<td>
							<label for="industryType">Employment Industry Type</label>
						</td>
						<td>
							<select formControlName="industryType"
              lmsSelectOption [constantName]="'INDUSTRYTYPE'"
              required>
								<!-- <option *ngFor="let industry of lstIndustryType" [value]="industry.value">
									{{industry.description}}
								</option> -->
							</select>
							<div *ngIf="f.industryType?.invalid && Submitted">
								<div *ngIf="f.industryType?.errors?.required">
									<small class="text-danger">Industry type is required.</small>
								</div>
							</div>
						</td>
						<td *ngIf="f.industryType?.value === 'Other'">
							<label for="industryTypeOther">Industry type - Other</label>
						</td>
						<td *ngIf="f.industryType?.value === 'Other'">
							<div>
								<input formControlName="industryTypeOther" placeholder="Industry Type *"
									(keypress)="global_utility.omit_special_char($event)"
									[value]="preauthModel!.industryTypeOther"
									[required]="f.industryType.value === 'Other'" />
							</div>
							<div *ngIf="f.industryType?.invalid && Submitted">
								<div *ngIf="f.industryType?.errors?.required">
									<small class="text-danger">Other industry type is required.</small>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<label for="additionalIncome">Additional Income</label>
						</td>
						<td>
							<mat-select [formControlName]="'additionalIncome'" multiple required
								style="max-width: 215px; padding-left: .3rem;"
								(valueChange)="updateAdditionalIncomeOptions($event)">
								<mat-option *ngFor="let addIncome of lstAdditionalIncome" [value]="addIncome.value"
									[disabled]="(addIncome.description!=='None'&&isAINoneSelected)">
									{{addIncome.description}}
								</mat-option>
							</mat-select>
							<div *ngIf="f.additionalIncome?.invalid">
								<div *ngIf="f.additionalIncome?.errors?.required && Submitted">
									<small class="text-danger">Additional Income is required.</small>
								</div>
							</div>
						</td>
						<td *ngIf="isAIOtherOptionSelected">
							<label for="additionalIncomeOther">Additional Income Other</label>
						</td>
						<td *ngIf="isAIOtherOptionSelected">
							<input formControlName="additionalIncomeOther" placeholder="Other Additional Income *"
								(keypress)="global_utility.omit_special_char($event)"
								[required]="isAIOtherOptionSelected" />
						</td>
					</tr>
					<tr>
						<td>Last 3 incomes</td>
						<td colspan="4" class="py-1">
              <ng-container formGroupName="payslips">
                <span class="input-group d-flex align-items-center ms-auto">
                  <button class="btn btn-secondary me-5" (click)="openPaySlip(1)" mdbRipple>
                    {{(income1 === 0) ? 'Income 1' : income1|zaRcurrency}}
                  </button>
                  <input hidden="true"
                      [class.ng-invalid]="payslips!.hasError('duplicatePayslip')"
                      formControlName="payslip1"/>

                  <button class="btn btn-secondary me-5" (click)="openPaySlip(2)" mdbRipple>
                    {{(income2 === 0) ? 'Income 2' : income2|zaRcurrency}}
                  </button>
                  <input hidden="true"
                      [class.ng-invalid]="payslips!.hasError('duplicatePayslip')"
                      formControlName="payslip2"/>
                    <input hidden="true"
                    [class.ng-invalid]="payslips!.hasError('duplicatePayslip')"
                    formControlName="payslip3"/>
                  <button class="btn btn-secondary me-5" (click)="openPaySlip(3)" mdbRipple>
                    {{(income3 === 0) ? 'Income 3' : income3|zaRcurrency}}
                  </button>
                </span>
                <div *ngIf="payslips?.invalid">
                  <div *ngIf="payslips!.hasError('duplicatePayslip')">
                  <small class="text-danger">
                    Duplicate payslip detected, this is not permitted!
                  </small>
                  </div>
                </div>
                </ng-container>
            </td>
					</tr>
					<tr *ngIf="Submitted && (income1 === 0 || income2 === 0 || income3 === 0)">
						<td></td>
						<td colspan="3">
							<small class="text-danger">
								At least 3 incomes needs to be captured.
							</small>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</ng-container>
