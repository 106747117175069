import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'zacurrency',
    standalone: true
})
export class ZAcurrencyPipe implements PipeTransform {
  transform(
    value: string | number | 0,
    currencyCode: string = '',
    display:
      | 'code'
      | 'symbol'
      | 'symbol-narrow'
      | string
      | boolean = 'symbol',
    digitsInfo: string = '1.2-2',
    locale: string = 'za',
  ): string {
    return formatCurrency(
      Number(value) | 0,
      locale,
      getCurrencySymbol(currencyCode, 'wide'),
      currencyCode,
      digitsInfo,
    );
  }
}



