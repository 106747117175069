import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ToastrService } from 'ngx-toastr';
import { ClientShowModel } from "src/app/core/models/client-show.model";
import { environment } from "src/environments/environment";
import { ClientNotesComponent } from './client-notes/client-notes.component';
import { ClientnoteService } from './clientnote.service';


@Component({
  selector: 'app-client-header',
  templateUrl: './client-header.component.html',
  styleUrls: ['./client-header.component.css'],
  standalone: true,
  imports:[
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
  ]
})
export class ClientHeaderComponent implements OnInit, AfterViewInit {
  isNotesShowing = environment.showClientNotes || false;

  @Input() clientDetails?: ClientShowModel;

  @Input() showQuote?= false;

  clientNotes: any;
  notesCount = 0;

  constructor(
    private clientNoteService: ClientnoteService,
    public dialog: MatDialog,
    public toastrService: ToastrService) { }

  ngOnInit(): void {
    if (this.isNotesShowing) {
      const cid = this.clientDetails!.clientId;
      if (cid) {
        this.clientNoteService.getClientNotes(cid).subscribe(
          (cnote: any) => {
            this.notesCount = cnote?.length || 0;
            //this.clientNoteCount.emit(cnote.length);
          }
        );
      }
    }
  }

  ngAfterViewInit() {
    console.log(this.isNotesShowing); // Dolphin
    // this.quoteStoreService.quoteData2$.subscribe((quote) => {
    //   if (this.quoteStoreService.isQuoteFound) {
    //     this.clientDetails = quote;
    //   }
    // });


  }

  openNoteDialog() {
    if (this.clientDetails && this.clientDetails.clientId) {
      this.dialog.open(ClientNotesComponent, {
        disableClose: true,
        autoFocus: true,
        maxHeight: '900px',
        height: 'auto',
        width: 'auto',
        data: {
          'clientid': this.clientDetails.clientId,
          'existingNotes': this.clientNotes
        }
      }).afterClosed().subscribe(result => {
        this.notesCount = result || 0;
      });
    }
  }

  // setnotesCount(notCount: number) {
  //   this.notesCount = notCount;
  // }
}
