import { from } from 'rxjs';

export { BranchService } from './branch.service';
export { UploadFileService } from './upload-file.service';
export { ClientDetailsService } from './client-details.service';
export { QuoteStoreService } from './quote-store.service';
export { UserAuditTrailService } from './user-audit-trail.service';
export { RatesAdnFeesService } from './rates-and-fees.service';
export {BiometricsSerivce} from './biometrics.service';
export * from './rest-services.module';
