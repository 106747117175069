<ng-container [formGroupName]="controlKey">
  <div class="card">
    <h5 class="card-header text-center">SASSA information</h5>
    <div class="card-body">
      <table
        id="tblsalariedinfo"
        class="table table-striped table-sm mb-1">
        <tbody class="field-4column-label">
          <tr>
            <td>
              <label for="grantType">Type of grant</label>
            </td>
            <td>
              <select
                formControlName="grantType"
                class="ms-2"
                required
                [ngClass]="{ 'is-invalid': Submitted && f.controls.grantType.errors }">
                <option
                  *ngFor="let grant of lstGrantType"
                  [value]="grant.value">
                  {{grant.description}}
                </option>
              </select>
              <div
                *ngIf="Submitted && f.controls.grantType.errors?.required"
                class="invalid-feedback">
                <!-- <div *ngIf="parentFormGroup.get('grantType')!.errors?.['required']"> -->
                <small class="text-danger">Type of grant is required.</small>
                <!-- </div> -->
              </div>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <label>Latest grant amounts</label>
            </td>
            <td>
              <input
                placeholder="Month 1"
                formControlName="month1"
                required
                class="mx-2"
                max="99999"
                numbersOnly
                min="1"
                (keypress)="numberOnly($event)"
                [ngClass]="{ 'is-invalid': Submitted && f.controls.month1.errors }">
              <input
                placeholder="Month 2"
                formControlName="month2"
                required
                class="mx-2"
                max="99999"
                numbersOnly
                min="1"
                (keypress)="numberOnly($event)"
                [ngClass]="{ 'is-invalid': Submitted && f.controls.month2.errors }">
              <input
                placeholder="Month 3"
                formControlName="month3"
                required
                min="0"
                max="99999"
                numbersOnly
                (keypress)="numberOnly($event)"
                [ngClass]="{ 'is-invalid': Submitted && f.controls.month3.errors }">
            </td>
          </tr>
          <tr *ngIf="f.controls.month1.errors || f.controls.month2.errors || f.controls.month3.errors">
            <td colspan="4">
              <div
                *ngIf="Submitted && f.controls.month1?.errors"
                class="invalid-feedback">
                <small class="text-danger">1st month`s grant requires an amount.</small>
              </div>
              <div
                *ngIf="Submitted && f.controls.month2?.errors"
                class="invalid-feedback">
                <small class="text-danger">2nd month`s grant requires an amount.</small>
              </div>
              <div
                *ngIf="Submitted && f.controls.month3?.errors"
                class="invalid-feedback">
                <small class="text-danger">3rd month`s grant requires an amount.</small>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
