import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { LMSSharedUiModule } from '@lmsSharedUI';
import { RatesAndFeesRoutingModule } from "./rates-and-fees-routing.module";
import { RemoveLoanTypeDialogComponent } from "./remove-loan-type/remove-loan-type.component";
import { AddLoanTypeComponent } from "./add-loan-type/add-loan-type.component";
import { RatesAndFeesComponent } from "./rates-and-fees.component";
import { MatExpansionModule } from "@angular/material/expansion";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RatesAndFeesRoutingModule,
        MatDialogModule,
        MatButtonModule,
        MatExpansionModule,
        HttpClientModule,
        LMSSharedUiModule,
        AddLoanTypeComponent,
        RemoveLoanTypeDialogComponent,
        RatesAndFeesComponent
    ]
})
export class RatesAndFeesModule { }
