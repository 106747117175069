import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { UserService } from 'src/app/core/services/lmsuser.service';

@Injectable({
  providedIn:'root'
})
export class LMSAuthGuard extends KeycloakAuthGuard {
  constructor(
    public override router: Router,
  public keycloak: KeycloakService,private userService: UserService) {
    super(router, keycloak);
    this.userService = inject(UserService);
  }

  CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
    ) => {
      if (!this.authenticated) {
        this.keycloak.login().then(
        _ => {
          redirectUri: window.location.origin + state.url
        });
      }
      return this.canActivate(route,state);
  };

  async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (!this.authenticated) {
      await this.keycloak.login().then(() => {
        redirectUri: window.location.origin + state.url
      });
    }

    return new Promise<boolean>((resolve, reject) => {
      if (this.authenticated && !this.userService?.isUserLoggedinValue) {
        this.userService?.isUserLoggedin$.subscribe((isloggedin)=>{
          if(isloggedin){
            resolve(true);
            return;
          }

          this.keycloak.loadUserProfile().then((profile)=> {
            this.userService.getLMSUserProfile(profile).then(()=>{
              resolve(true);
            }).catch((reson)=>{
              resolve(false);
            });
          }).catch((reson)=>{
            resolve(false);
          });
        });
      } else {
        resolve(true);
      }
    });
    //return this.authenticated || this.userService?.isUserLoggedinValue;
  };

  // async isAccessAllowed(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Promise<boolean | UrlTree> {

  //   if (!this.authenticated && !this.userService?.isUserLoggedinValue) {
  //     //this.userService.needsLogIn(window.location.origin,// + state.url,
  //     this.keycloakAngular.login().then(() => {
  //       redirectUri: window.location.origin + state.url
  //     });
  //     this.userService.setisUserLoggedin(this.authenticated);
  //     return this.authenticated;
  //     this.navigateToURL(state.url);
  //   }

  //   return this.authenticated || this.userService?.isUserLoggedinValue;
  // }

  async navigateToURL(URL: string) {
    await this.router.navigateByUrl(URL);
  }
}
