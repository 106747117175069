<div class="modal-content">
  <div class="modal-header">
    <button class="btn btn-close" color="red"  (click)="modalRef.close()" ></button>
    <div class="modal-title center-fields">
      <app-page-cardhead style="margin-top: -2.1rem;" [PageName]="'Address Details'" [PageHeadWidth]=700></app-page-cardhead>
    </div>
  </div>

  <div class="modal-body" >
    <form [formGroup]="addressDetailsForm" #formDirective="ngForm">
    <div class="card">
      <div class="card-body p-1">
        <div class="container-fluid bd-example-row">
          <table id="clientHeader" class="table p-1 table-striped table-hover table-sm mb-3 caption-top shadow-1-strong rounded-1" [style.width.px]="700">
            <caption>Client Details</caption>
              <tbody class="field-4column-label">
                <tr>
                  <td>First names</td>
                  <td>{{clientDetails.firstName}}</td>

                  <td>Surname</td>
                  <td>{{clientDetails.lastName}}</td>
                </tr>

                <tr>
                  <td>ID number</td>
                  <td>{{clientDetails.idNumber}}</td>

                  <td>Cell number</td>
                  <td>{{clientDetails.cellNumber}}</td>
                </tr>
              </tbody>
          </table>

          <ng-container class="container-md align-items-center">
            <div *ngIf="hasAddress">
              <table class="mt-3 table table-sm table-fixed" [style.width.px]="750">
                <tbody>
                  <tr>
                    <td class="aligndetails"  style="border-bottom-width: 0;">
                      <!--  Home Address -->
                      <table id="homeAddress" class="table table-striped table-sm caption-top shadow-1-strong rounded-1" >
                        <caption>Residential Address</caption>

                        <tbody class="field-4column-label">
                          <tr>
                            <td>Address line 1</td>
                            <td>{{homeAddress.line1}}</td>
                          </tr>

                          <tr>
                            <td> Address line 2</td>
                            <td>{{homeAddress.line2}}</td>
                          </tr>
                          <tr>
                            <td>Suburb</td>
                            <td>{{homeAddress.suburb}}</td>
                          </tr>
                          <tr>
                            <td>Town / City</td>
                            <td>{{homeAddress.town}}</td>
                          </tr>
                          <tr>
                            <td>Area code</td>
                            <td>{{homeAddress.code}}</td>
                          </tr>

                          <tr>
                            <td> Province</td>
                            <td>{{homeAddress.province}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>

                    <td class="aligndetails"  style="border-bottom-width: 0;" *ngIf="postalAddress || !this.addressModel.sameAsAdress">
                      <!--  POSTAL Address -->
                      <table id="postalAddressDetails" class="table table-striped table-sm caption-top shadow-1-strong rounded-1">
                        <caption>Postal Address</caption>

                        <tbody class="field-4column-label">
                          <tr>
                            <td>Postal address line 1</td>
                            <td>{{postalAddress.line1}}</td>
                          </tr>

                          <tr *ngIf="postalAddress.line2">
                            <td>Postal address line 2</td>
                            <td>{{postalAddress.line2}}</td>
                          </tr>
                          <tr>
                            <td>Suburb</td>
                            <td>{{postalAddress.suburb}}</td>
                          </tr>
                          <tr>
                            <td>Town / City</td>
                            <td>{{postalAddress.town}}</td>
                          </tr>

                          <tr>
                            <td>Area code</td>
                            <td>{{postalAddress.code}}</td>
                          </tr>
                          <tr>
                            <td> Province</td>
                            <td>{{postalAddress.province}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- NO address details (EDITING)-->
            <div *ngIf="!hasAddress && !isReadOnly">
              <table class="mt-3 table table-sm table-fixed" [style.width.px]="750">
                <tbody>
                  <tr>
                    <td class="aligndetails"  style="border-bottom-width: 0;">
                      <!-- Home and Postal address -->
                      <table class="table p-1 table-striped table-bordered table-sm caption-top shadow-1-strong rounded-1" [ngClass]="{'myw-400':addressModel.sameAsAdress}">
                        <caption *ngIf="addressModel.sameAsAdress">Residential and Postal address</caption>
                        <caption *ngIf="!addressModel.sameAsAdress">Residential address</caption>

                        <tbody class="field-4column-label">
                          <tr>
                            <td>Address line 1</td>
                            <td>
                              <input class="form-control" placeholder="Address line 1" formControlName="homeAdressLine1"
                                [(ngModel)]="addressModel.homeAdressLine1" required type="text"
                                (keypress)="global_utility.omit_special_char($event)">
                              <div
                                *ngIf="validateAddressesInput.homeAdressLine1.errors?.required && validateAddressesInput.homeAdressLine1.touched && submitted">
                                <small *ngIf="validateAddressesInput.homeAdressLine1.errors?.required" class="text-danger">
                                  Address line 1 is required
                                </small>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>Address line 2</td>
                            <td>
                              <input class="form-control" placeholder="Address line 2" formControlName="homeAdressLine2"
                                [(ngModel)]="addressModel.homeAdressLine2" type="text"
                                (keypress)="global_utility.omit_special_char($event)">
                            </td>
                          </tr>

                          <tr>
                            <td>Suburb</td>
                            <td style="width: 120px;">
                              <!-- <div class="custom-select">
                                <input #homeAdressSuburb placeholder="Suburb *" type="text" matInput formControlName="homeAdressSuburb"
                                  [matAutocomplete]="autohomeAdressSuburb" class="custom-input" (keypress)="omit_special_char($event)" required>
                                <mat-autocomplete autoActiveFirstOption #autohomeAdressSuburb="matAutocomplete"
                                  (optionSelected)="onHomeOptionClick($event)" panelWidth="auto">
                                  <mat-option *ngFor="let homeSuburb of homeAdressSuburb$ | async" (click)="homeAdressSuburb.value=''"
                                    [value]="homeSuburb">
                                    <span><small>{{homeSuburb.CODE}}</small> |
                                      <small>{{homeSuburb.SUBURB}}</small></span><br>
                                    <small><strong>{{homeSuburb.AREA}}</strong></small>
                                  </mat-option>
                                </mat-autocomplete>
                              </div>
                              <div
                                *ngIf="validateAddressesInput.homeAdressSuburb.errors?.required && validateAddressesInput.homeAdressSuburb.touched && submitted">
                                <small *ngIf="validateAddressesInput.homeAdressSuburb.errors?.required" class="text-danger">
                                  Suburb is required
                                </small>
                              </div> -->
                              <input class="form-control" formControlName="homeAdressSuburb" placeholder="Suburb *" [(ngModel)]="addressModel.homeAdressSuburb" required
                                type="text" (keypress)="global_utility.omit_special_char($event)">
                              <div
                                *ngIf="validateAddressesInput.homeAdressSuburb.errors?.required && validateAddressesInput.homeAdressSuburb.touched && submitted">
                                <small *ngIf="validateAddressesInput.homeAdressSuburb.errors?.required" class="text-danger">
                                  Suburb is required
                                </small>
                              </div>
                            </td>
                            <tr>
                              <td>Town/City</td>
                              <td style="width: 120px;">
                                <input class="form-control" placeholder="Town/City" formControlName="homeAdressTown" [(ngModel)]="addressModel.homeAdressTown" required
                                  type="text" (keypress)="global_utility.omit_special_char($event)">
                                <div *ngIf="validateAddressesInput.homeAdressTown.errors?.required && submitted">
                                  <small *ngIf="validateAddressesInput.homeAdressTown.errors?.required" class="text-danger">
                                    Town/City is required
                                  </small>
                                </div>
                              </td>

                            <!-- <td style="width: 120px;">
                              <div class="custom-select">
                                <input placeholder="Suburb" type="text" matInput formControlName="homeAdressSuburb"
                                  [(ngModel)]="addressModel.homeAdressSuburb" [matAutocomplete]="autohomeAdressSuburb"
                                  class="custom-input" (keypress)="omit_special_char($event)" required>
                                <mat-autocomplete autoActiveFirstOption #autohomeAdressSuburb="matAutocomplete">
                                  <mat-option *ngFor="let homeCodeSuburb of homeCodesSuburbs | async"
                                    [value]="homeCodeSuburb.SUBURB" (click)="onHomeOptionClick(homeCodeSuburb)">
                                    <span><small>{{homeCodeSuburb.CODE}}</small> |
                                      <small>{{homeCodeSuburb.SUBURB}}</small></span><br>
                                    <small><strong>{{homeCodeSuburb.AREA}}</strong></small>
                                  </mat-option>
                                </mat-autocomplete>
                              </div>
                              <div
                                *ngIf="validateAddressesInput.homeAdressSuburb.errors?.required && validateAddressesInput.homeAdressSuburb.touched && submitted">
                                <small *ngIf="validateAddressesInput.homeAdressSuburb.errors?.required" class="text-danger">
                                  Suburb is required</small>
                              </div> -->
                          </tr>

                          <tr>
                            <td>Area code</td>
                            <td style="width: 120px;">
                              <input class="form-control" formControlName="homeAdressAreaCode" placeholder="Area code *" [(ngModel)]="addressModel.homeAdressAreaCode" required maxlength="4"
                                type="text" (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
                              <div
                                *ngIf="validateAddressesInput.homeAdressAreaCode.errors?.required && validateAddressesInput.homeAdressAreaCode.touched && submitted">
                                <small *ngIf="validateAddressesInput.homeAdressAreaCode.errors?.required" class="text-danger">
                                  Suburb is required
                                </small>
                              </div>
                            </td>
                            <!-- <td style="width: 120px;">
                              <div class="custom-select">
                                <input maxlength="4" placeholder="Area Code" formControlName="homeAdressAreaCode"
                                  (keypress)="($event.charCode >= 48 && $event.charCode < 58)" matInput
                                  [matAutocomplete]="autoHomeCode" class="custom-input" required>
                                <mat-autocomplete autoActiveFirstOption #autoHomeCode="matAutocomplete">
                                  <mat-option *ngFor="let homeCode of homepostalCodes | async" [value]="homeCode.CODE"
                                    (click)="onHomeOptionClick(homeCode)">
                                    <span><small>{{homeCode.CODE}}</small> |
                                      <small>{{homeCode.SUBURB}}</small></span><br>
                                    <small><strong>{{homeCode.AREA}}</strong></small>
                                  </mat-option>
                                </mat-autocomplete>
                              </div>
                              <div
                                *ngIf="validateAddressesInput.homeAdressAreaCode.errors?.required && validateAddressesInput.homeAdressAreaCode.touched && submitted">
                                <small *ngIf="validateAddressesInput.homeAdressAreaCode.errors?.required"
                                  class="text-danger">
                                  Area code is required
                                </small>
                                <small *ngIf="validateAddressesInput.homeAdressAreaCode.errors?.pattern" class="text-danger"
                                  s>
                                  Invalid Area Code
                                </small>
                              </div>
                            </td> -->
                          </tr>

                          <tr>
                            <td> Province</td>
                            <td>
                              <select class="form-control form-select" formControlName="homeAdressProvince" [(ngModel)]="addressModel.homeAdressProvince"
                                required>
                                <option value="Gauteng">Gauteng</option>
                                <option value="KwaZulu-Natal">KwaZulu-Natal</option>
                                <option value="Free-State">Free State</option>
                                <option value="Eastern-cape">Eastern Cape</option>
                                <option value="Limpopo">Limpopo</option>
                                <option value="Mpumalanga">Mpumalanga</option>
                                <option value="North West">North West</option>
                                <option value="Northern-Cape">Northern Cape</option>
                                <option value="Western-Cape">Western Cape</option>
                              </select>
                              <div *ngIf="validateAddressesInput.homeAdressProvince.errors?.required && submitted">
                                <small *ngIf="validateAddressesInput.homeAdressProvince.errors?.required"
                                  class="text-danger">
                                  Province is required
                                </small>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>

                    <!-- NOT sameAddress-->
                    <td *ngIf="!addressModel.sameAsAdress"  style="border-bottom-width: 0;" class="aligndetails">
                      <form [formGroup]="postalDetailsForm" #formDirective="ngForm">
                        <!--  POSTAL Address -->
                        <table class="table p-1 table-striped table-bordered table-sm caption-top shadow-1-strong rounded-1" >
                          <caption>Postal Address</caption>
                          <tbody class="field-4column-label">
                            <tr>
                              <td>Address type</td>
                              <td>
                                <span>
                                <div class="d-flex align-items-center justify-content-around">
                                <input  class="form-control-sm mdc-radio radio-button form-radio-input " color="primary" id="POSTAL" name="postalType" type="radio" value="POSTAL" (click)="_postalType = 'POSTAL'">
                                <label for="POSTAL"> Postal </label>
                                <input class="form-control-sm mdc-radio form-radio-input" color="primary" id="HOME" name="postalType" type="radio" value="HOME" (click)="_postalType = 'HOME'" >
                                <label for="HOME"> Residential </label>
                                </div>
                              </span>
                              <!-- <span>
                                <div class="d-flex align-items-center justify-content-around">
                                <mat-radio-group [(ngModel)]="_postalType">
                                  <mat-radio-button class="radio-button" value="POSTAL" color="primary"
                                  (click)="_postalType = 'POSTAL'">Postal</mat-radio-button>
                                  <mat-radio-button class="radio-button" value="HOME" color="primary"
                                  (click)="_postalType = 'HOME'" >Residential</mat-radio-button>
                                </mat-radio-group>
                              </div>
                              </span> -->
                              </td>
                            </tr>
                            <tr *ngIf="postalType !== 'HOME'">
                              <td>Box number</td>
                              <td>
                                <input class="form-control" placeholder="Box Number" formControlName="postalAdressLine1"
                                  [(ngModel)]="addressModel.postalAdressLine1" required type="text"
                                  (keypress)="global_utility.omit_special_char($event)">
                                <div *ngIf="validatePostalAddressesInput.postalAdressLine1.errors?.required && submitted">
                                  <small *ngIf="validateAddressesInput.postalAdressLine1.errors?.required"
                                    class="text-danger">
                                    PO Box number is required
                                  </small>
                                </div>
                              </td>
                            </tr>
                            <tr *ngIf="postalType === 'HOME'">
                              <td>Address line 1</td>
                              <td>
                                <input class="form-control" placeholder="Address line 1" formControlName="postalAdressLine1"
                                  [(ngModel)]="addressModel.postalAdressLine1" required type="text"
                                  (keypress)="global_utility.omit_special_char($event)">
                                <div *ngIf="validatePostalAddressesInput.postalAdressLine1.errors?.required && submitted">
                                  <small *ngIf="validateAddressesInput.postalAdressLine1.errors?.required"
                                    class="text-danger">
                                    Address line 1 is required
                                  </small>
                                </div>
                              </td>
                            </tr>
                            <tr *ngIf="postalType === 'HOME'">
                              <td>Address line 2</td>
                              <td>
                                <input class="form-control" placeholder="Address line 2" formControlName="postalAdressLine2"
                                  [(ngModel)]="addressModel.postalAdressLine2" type="text"
                                  (keypress)="global_utility.omit_special_char($event)">
                              </td>
                            </tr>
                            <tr>
                              <td>Suburb</td>
                              <td style="width: 120px;">
                                <input class="form-control" formControlName="postalAdressSuburb" placeholder="Suburb *"
                                [(ngModel)]="addressModel.postalAdressSuburb" required
                                  type="text" (keypress)="global_utility.omit_special_char($event)">
                                <div
                                  *ngIf="validatePostalAddressesInput.postalAdressSuburb.errors?.required && validatePostalAddressesInput.postalAdressSuburb.touched && submitted">
                                  <small *ngIf="validatePostalAddressesInput.postalAdressSuburb.errors?.required" class="text-danger">
                                    Suburb is required
                                  </small>
                                </div>
                                <!-- <div class="custom-select">
                                  <input #postalAdressSuburb placeholder="Suburb" type="text" matInput formControlName="postalAdressSuburb"
                                    [matAutocomplete]="autoSuburb" class="custom-input" required>
                                  <mat-autocomplete autoActiveFirstOption #autoSuburb="matAutocomplete"
                                  (optionSelected)="onOptionClick($event)" panelWidth="auto">
                                    <mat-option *ngFor="let postalCodeSuburb of postalCodeSuburbs$ | async"
                                      (click)="postalCodeSuburb.value=''"
                                      [value]="postalCodeSuburb">
                                      <span><small>{{postalCodeSuburb.CODE}}</small> |
                                        <small>{{postalCodeSuburb.SUBURB}}</small></span><br>
                                      <small><strong>{{postalCodeSuburb.AREA}}</strong></small>
                                    </mat-option>
                                  </mat-autocomplete>
                                </div>
                                <div *ngIf="validatePostalAddressesInput.postalAdressSuburb.errors?.required && submitted">
                                  <small *ngIf="validateAddressesInput.postalAdressSuburb.errors?.required" class="text-danger">
                                    Suburb is required
                                  </small>
                                </div> -->
                              </td>
                            </tr>
                            <tr>
                              <td>Town/City</td>
                              <td style="width: 120px;">
                                <input class="form-control" placeholder="Town/City" formControlName="postalAdressTown" [(ngModel)]="addressModel.postalAdressTown" required
                                  type="text" (keypress)="global_utility.omit_special_char($event)">
                                <div *ngIf="validatePostalAddressesInput.postalAdressTown.errors?.required && submitted">
                                  <small *ngIf="validateAddressesInput.postalAdressTown.errors?.required" class="text-danger">
                                    Town/City is required
                                  </small>
                                </div>
                                <!-- <div class="custom-select">
                                  <input #postalAdressTown placeholder="Town/City *" type="text" matInput formControlName="postalAdressTown"
                                    [matAutocomplete]="autopostalAdressTown" class="custom-input" (keypress)="omit_special_char($event)" required>
                                  <mat-autocomplete autoActiveFirstOption #autopostalAdressTown="matAutocomplete"
                                    (optionSelected)="onOptionClick($event)" panelWidth="auto">
                                    <mat-option *ngFor="let postalTown of postalCodeTown$ | async" (click)="postalAdressTown.value=''"
                                      [value]="postalTown">
                                      <span><small>{{postalTown.CODE}}</small> |
                                        <small>{{postalTown.SUBURB}}</small></span><br>
                                      <small><strong>{{postalTown.AREA}}</strong></small>
                                    </mat-option>
                                  </mat-autocomplete>
                                </div>
                                <div *ngIf="validateAddressesInput.postalAdressTown.errors?.required && submitted">
                                  <small *ngIf="validateAddressesInput.postalAdressTown.errors?.required" class="text-danger">
                                    Town/City is required
                                  </small>
                                </div> -->
                              </td>
                            </tr>
                            <tr>
                              <td>Area code</td>
                              <td>
                              <input class="form-control" maxlength="4" placeholder="Area Code" formControlName="postalAdressAreaCode"
                                [(ngModel)]="addressModel.postalAdressAreaCode" required
                                (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
                              <div *ngIf="(validatePostalAddressesInput.postalAdressAreaCode.errors?.required || validatePostalAddressesInput.postalAdressAreaCode.errors?.pattern) && submitted">
                                <small *ngIf="validateAddressesInput.postalAdressAreaCode.errors?.required" class="text-danger">
                                Area code is required
                                </small>
                                <small *ngIf="validatePostalAddressesInput.postalAdressAreaCode.errors?.pattern" class="text-danger">
                                Invalid Area Code
                                </small>
                              </div>
                            </td>
                              <!-- <td style="width: 120px;">
                                <div class="custom-select">
                                  <input placeholder="Area Code" type="text" matInput formControlName="postalAdressAreaCode"
                                    [(ngModel)]="addressModel.postalAdressAreaCode" [matAutocomplete]="autoAreaCode"
                                    class="custom-input" required>
                                  <mat-autocomplete autoActiveFirstOption #autoAreaCode="matAutocomplete">
                                    <mat-option *ngFor="let postalCode of postalCodes | async" [value]="postalCode.CODE"
                                    (click)="onOptionClick(postalCode)">
                                      <span><small>{{postalCode.CODE}}</small> |
                                        <small>{{postalCode.SUBURB}}</small></span><br>
                                      <small><strong>{{postalCode.AREA}}</strong></small>
                                    </mat-option>
                                  </mat-autocomplete>
                                </div>
                                <div
                                  *ngIf="(validatePostalAddressesInput.postalAdressAreaCode.errors?.required || validatePostalAddressesInput.postalAdressAreaCode.errors?.pattern) && submitted">
                                  <small *ngIf="validateAddressesInput.postalAdressAreaCode.errors?.required"
                                    class="text-danger">
                                    Area code is required
                                  </small>
                                  <small *ngIf="validatePostalAddressesInput.postalAdressAreaCode.errors?.pattern"
                                    class="text-danger">
                                    Invalid Area Code
                                  </small>
                                </div>
                              </td> -->
                            </tr>

                            <tr>
                              <td> Province</td>
                              <td>
                                <select class="form-control form-select" formControlName="postalAdressProvince"
                                  [(ngModel)]="addressModel.postalAdressProvince" required>
                                  <option value="Gauteng">Gauteng</option>
                                  <option value="KwaZulu-Natal">KwaZulu-Natal</option>
                                  <option value="Free-State">Free State</option>
                                  <option value="Eastern-cape">Eastern Cape</option>
                                  <option value="Limpopo">Limpopo</option>
                                  <option value="Mpumalanga">Mpumalanga</option>
                                  <option value="North West">North West</option>
                                  <option value="Northern-Cape">Northern Cape</option>
                                  <option value="Western-Cape">Western Cape</option>
                                </select>
                                <div
                                  *ngIf="validatePostalAddressesInput.postalAdressProvince.errors?.required && submitted">
                                  <small *ngIf="validateAddressesInput.postalAdressProvince.errors?.required"
                                    class="text-danger">
                                    Province is required
                                  </small>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </form>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- NOT sameAdddres end-->
              <div class="center-screen">
                <div class="d-flex justify-content-center align-content-center">
                  <span>
                    <label for="sameAddress" class="form-check-label m-2">Postal address is the same as residential address</label>
                  <input #sameAddress class="form-control form-check-input form-check-inline form-check" type="checkbox"
                  aria-label="Postal address is the same as residential address"
                  [checked]="addressModel.sameAsAdress"
                  (change)="addressModel.sameAsAdress = !addressModel.sameAsAdress"/>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="card-footer center-screen">
        <button mat-raised-button type="button" class="me-2" (click)="close()" color="primary"> Close </button>
        <button mat-raised-button type="button" class="me-2"  *ngIf="!isReadOnly && !hasAddress"
          (click)="saveAddress()" color="primary">
          Save </button>
        <button mat-raised-button type="button" class="me-2" *ngIf="hasAddress && !isReadOnly"
        (click)="editAddress()" color="primary">
          Edit </button>
        <!-- clientStatus === 'REPEAT' && -->
        <button *ngIf=" !isReadOnly && hasAddress" mat-raised-button
          class="mat-raised-button mat-primary ms-2" type="button"
          (click)="confirmAddDetails()"><i class="fas fa-thumbs-up fa-fw me-1"></i>
          Confirm </button>
      </div>
    </div>
  </form>
  </div>
</div>
