import { EnvironmentInjector, EventEmitter, inject, Injectable, Output, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { KeycloakEvent, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { BehaviorSubject, finalize, Observable, of, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { AppUtilityService } from 'src/app/app-utility.service';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {
  @Output() onLogginChanged = new EventEmitter<boolean>();
  private environmentInjector = inject(EnvironmentInjector);
  private currentProfile$ = new BehaviorSubject<KeycloakProfile>({});
  private LMSProfile = new BehaviorSubject<any>({});
  public isUserLoggedin$ = new BehaviorSubject<boolean>(false);
  public UserhasEvent$ = new BehaviorSubject<KeycloakEvent>({type: KeycloakEventType.OnReady});

  private menusControl = new BehaviorSubject<any[]>([]);
  private menuHold = new BehaviorSubject<any[]>([]);
  private fingerAuthentication = new BehaviorSubject<boolean>(false);
  private OnUserEvent = this.UserhasEvent$;

  //currentUser: User | null = this.auth.currentUser;
  userSubscription: Subscription;

  userBranch_apiURL = `${environment.apiUrl}/loan/api/user-branch`;
  menu_apiURL = `${environment.apiUrl}/loan/menu`;
  accessToken: any;
  userDetails = new BehaviorSubject<any>({});
  private loginStarted = false;
  HttpParamsOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    public keycloak: KeycloakService,
    private authService: AuthenticationService,
    //private storageService: StorageService,
    private global_utility: AppUtilityService) {
      this.userSubscription = this.keycloak.keycloakEvents$.subscribe((evt: KeycloakEvent)=>{
        if(evt.type == KeycloakEventType.OnTokenExpired || evt.type == KeycloakEventType.OnActionUpdate){
          this.authService.login(this.keycloak.getKeycloakInstance().subject!);
        }

        this.UserhasEvent$.next(evt);
      });
      // this.isUserLoggedin$.subscribe((validuser)=>{
      //   if(validuser) {
      //     this.keycloak.loadUserProfile().then(async (profile: KeycloakProfile) => {
      //     this.getLMSUserProfile(profile);
      //     });
      //     return;
      //   }

      //   if(validuser!!){
      //     this.keycloak.login().then(() => {
      //       redirectUri: window.location.origin;
      //     });
      //     return;
      //   }
      // })
  }


  LMSProfile$ = this.LMSProfile.asObservable();

  menusControl$ = this.menusControl.asObservable();
  menuHold$ = this.menuHold.asObservable();
  isFingerAuth$ = this.fingerAuthentication.asObservable();

  async getLMSUserProfile(profile : KeycloakProfile) {
    //this.keycloak.getKeycloakInstance().loadUserProfile().then(async (profile: KeycloakProfile) => {
      // this.storageService.clean();
      if (!profile) {
        this.setisUserLoggedin(false);
        this.fingerAuthentication.next(false);
        this.loginStarted = false;
        return;
      }


      const uuid = profile.id || this.keycloak.getKeycloakInstance().subject!;
      await this.getLoggedInUserDetails(uuid).then((lmsUser: any) => {
        if (lmsUser && !this.isUserLoggedinValue) {
          //this.storageService.saveUser(lmsUser);
          this.fingerAuthentication.next(false);
          this.setisUserLoggedin(true);

          setTimeout(() => {
            this.getLoggedinUserMenu(uuid, lmsUser);
            let component = lmsUser!.jobTitle?.component;
            this.global_utility.setGlobalData('user_Landing', component);
            if (!component || component === 'branchconsultant' || lmsUser.jobTitle!.jobTitle === 'System Owner') {
              component = '/agent';
              this.global_utility.setGlobalData('user_Landing', '/agent');

              this.LMSProfile.getValue().jobTitle.component = '/agent';
              //component = '/fingerprintSignin';
            }
            this.global_utility.navigateToURL([component]);
          },500);
        }
        // setTimeout(() => {
        // if(!stoptimer) {
        //   this.loginStarted = false;
        //   this.router.navigate(['/clms']);
        // }}, 30000);
      }).catch(() => {
        this.authService.logout();
        //.handleOffline('OFFLINE');
        this.setisUserLoggedin(false);
      });
    //});
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Server responded with status ${error.status} and the message: ${error.message}`;
    }
    this.global_utility.error(errorMessage, error.statusText);
    return of(errorMessage);
  }

  getLoggedInUserDetails(uuid: string) {
    return new Promise((resolve, reject) => {
      this.getUserByUuid(uuid).subscribe({
        next: (user: any) => {// map((user: any) => {})
          if (user) {
            this.setLMSProfileObs(user);
            this.global_utility.setGlobalData('userDisplay', user.nameSurname);
            this.global_utility.setGlobalData('user_LoginName', user.userLoginName);
            this.global_utility.setGlobalData('branch_serial', user.delfinBranchSerial);
            this.global_utility.setGlobalData('branch_Name', user.branchName);
            this.global_utility.setGlobalData('user_Landing', user.jobTitle.component);
            resolve(user);
          }
        },
        error: (err) => {
          if (err instanceof HttpErrorResponse) {
            if ((err.error && err.error.type == 'error') && err.statusText === 'Unknown Error') {
              reject('SERVER OFFLINE');
              //Offline
            }
          }
          reject('SERVER OFFLINE');
        }
      });
    });
  }



  getUserByUuid(uuid = '') {
    if (uuid == '') {
      // this.setisUserLoggedin(false);
    }

    return this.http.get<any>(`${this.userBranch_apiURL}/uuid/${uuid}`).pipe(catchError(this.handleError));
  }

  getLoggedinUserMenu(uuid: string, usrDetails: any) {
    if (!this.userDetails) {
      return;
    }
    // All Keycloak ROLES
    const filteredRoles = this.keycloak?.getUserRoles(true);

    //Filtered Keycloak roles
    const rolefilter = filteredRoles
      .filter(role =>
        role == 'ROLE_ADMIN' ||
        role == 'ROLE_SUPER_USER' ||
        role == 'ROLE_USER' ||
        role == 'ROLE_APPROVER');

    //console.log(rolefilter);

    //const userNode = { uuid: uuid, roles: rolefilter.join(",") };
    // BUILD MENU
    this.getUserMenus(rolefilter.join(","), uuid, usrDetails.jobTitle).subscribe({
      next: (menus1) => {
        if (menus1 && menus1.length > 0) {
          this.setUserMenusObs(menus1);
          this.setNoMenus(false);
        }
      }
    });
  }

  setNoMenus(hideMenus: boolean) {
    //First save the menus to private member incase the user is loaded later in the session
    if (hideMenus) {
      if (this.menuHold.getValue()) {
        this.menusControl$.
          pipe(tap(menus => {
            this.menuHold.next(menus);
          }));
      }
      this.setUserMenusObs([]);
      return;

    }

    if (this.menuHold.value) {
      //const previousMenus = this.menuHold.value;
      this.menuHold.pipe(tap(menus => {
        this.menusControl.next(menus);
      }),
        finalize(() => {
          this.menuHold.next([]);
        }));
    }
  }

  // userServiceWrapper(
  //   HTTP_method: string,
  //   API_URL: string,
  //   responseProcessing: any,
  //   request_data?: any,
  //   skip_loading_animation?: string
  // ): Subject<any> {

  //   let response_subject = new Subject<any>();

  //   this.http.request(
  //     HTTP_method,
  //     API_URL,
  //     request_data
  //   ).pipe(
  //     finalize(
  //       () => {
  //         if (!!!skip_loading_animation) {
  //           //
  //         }
  //       }
  //     )
  //   ).subscribe((value: any) => {
  //     // If this is an error object directly send it across
  //     if (!!value['errorCode']) {
  //       response_subject.error(value);
  //     } else {
  //       let processed_response = responseProcessing(value);
  //       if (!!processed_response.error) {
  //         response_subject.error(processed_response.error);
  //       } else {
  //         if (!processed_response.data) {
  //           response_subject.next(processed_response);
  //         }
  //         else {
  //           response_subject.next(processed_response.data);
  //         }
  //       }
  //     }
  //   }
  //   );

  //   return response_subject;
  // }

  getUserMenus(roles: any, uuid: any, jobTitle: any) {
    const userRoles = { roles, uuid, jobTitle };
    // const userNode = { uuid: uuid, roles: roles };
    // console.log('menu', 'menu');
    return this.http
      .post<any>(`${this.menu_apiURL}/getMenusByUuid`, userRoles).pipe(catchError(this.handleError));
  }

  getRoles(): string[] {
    return this.keycloak.getUserRoles();
  }

  getKCInstance_Profile() {
    return this.keycloak?.getKeycloakInstance().profile;
  }

  public get CurrentKCUserProfile() {
    return this.currentProfile$?.value;
  }

  public get CurrentLMSUserBranchValue() {
    return this.LMSProfile.value;
  }

  public get CurrentUserMenus() {
    return this.menusControl.value;
  }

  public get IsFingerValid() {
    return this.fingerAuthentication.value;
  }

  setFingerValid(validBoolean: boolean) {
    // this.toastrService.info('FingerAuth changing: fingerValid is ' + { validBoolean }, '', { positionClass: 'toast-top-center' });
    this.fingerAuthentication.next(validBoolean);
  }

  getKCProfileObs(): Observable<KeycloakProfile> {
    return this.currentProfile$.asObservable();
  }

  setKCProfileObs(profile: KeycloakProfile) {
    this.currentProfile$.next(profile);
  }
  setLMSProfileObs = (lmsprofile: any) => { this.LMSProfile.next(lmsprofile); };
  setUserMenusObs = (menus: []) => { this.menusControl.next(menus); };

  get userLoginName() {
    return `${this.CurrentLMSUserBranchValue?.loginName || ''}`;
  }

  get userUUID() {
    return `${this.CurrentLMSUserBranchValue?.keyclockUuid}`;
  }

  get userDisplayName() {
    return this.CurrentLMSUserBranchValue?.nameSurname || '';
  }

  get userBranchName() {
    return `${this.CurrentLMSUserBranchValue?.branchName}`;
  }
  get userBranchNumber() {
    return `${this.CurrentLMSUserBranchValue?.branchNumber}`;
  }

  get userBranchSerial() {
    return `${this.CurrentLMSUserBranchValue?.delfinBranchSerial}`;
  }

  get userJobTitle() {
    return this.CurrentLMSUserBranchValue?.jobTitle;
  }


  // getKeycloakUserProfile() {
  //   return JSON.parse(sessionStorage.getItem("keycloakProfile") || '{}');
  // }

  // setKeycloakUserProfile(profile: KeycloakProfile) {
  //   return sessionStorage.setItem("keycloakProfile", JSON.stringify(profile) || '{}');
  // }

  //CODE BLOCK NOT USED
  // getLMSKeycloakRoles() {
  //   return this.http
  //     .get<any>(`${this.userBranch_apiURL}/getLMSRoles`)
  //     .pipe(
  //       map((res: any) => {
  //         return res;
  //       })
  //     );
  // }

  logOutUser() {
    this.loginStarted = false;

    this.setNoMenus(true);
    this.keycloak.logout();
  }

  getPublicContent(): Observable<any> {
    return this.http.get(this.userBranch_apiURL + 'all', { responseType: 'text' }).pipe(catchError(this.handleError));
  }

  getUserAreaBranches(area: string): Observable<any> {
    return this.http.get(`${this.userBranch_apiURL}/userbranchareas/${area}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  setisUserLoggedin(loggedin: boolean) {
    this.isUserLoggedin$.next(loggedin);
    if(this.onLogginChanged!!){
      this.onLogginChanged.emit(loggedin);
    }
  }

  isUserLoggedinValue = this.isUserLoggedin$.getValue();

  ngOnDestroy() {
    this.menusControl.unsubscribe();
    this.LMSProfile.unsubscribe();
    //this.isLMSLoggedin$.unsubscribe();
  }
}


