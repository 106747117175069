import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PDFDocumentProxy, PDFProgressData, PDFSource, PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { AppUtilityService } from 'src/app/app-utility.service';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { MdbModalModule, MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-document-viewer',
  standalone: true,
  providers:[
    MdbModalService
  ],
  imports: [
    CommonModule,
    PdfViewerModule,
    FormsModule,
    ReactiveFormsModule,
    MdbModalModule,
    MdbFormsModule
  ],
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.css']
})
export class DocumentViewerComponent implements OnInit, AfterViewInit {
  @ViewChild(PdfViewerComponent)
  private pdfComponent!: PdfViewerComponent;

  @ViewChild("pdfViewer", { static: true }) pdfViewer: ElementRef;

  @Input() pdfSrc: string | Uint8Array | PDFSource;

  error: any;
  page = 1;
  rotation = 0;
  pdf: any;
  //progressData!: PDFProgressData;
  isLoaded = false;
  fileSrc: any;
  pdffileName: string;
  outline!: any[];
  isPdfLoaded = false;
  zoom = 'auto';
  progressData!: PDFProgressData;

  //pdf: jsPDF;
  //pdfData: any;
  //pdfPage = 1;

  constructor(  public global_utility: AppUtilityService,
    public modalRef: MdbModalRef<DocumentViewerComponent>){
    // public dialogRef: MatDialogRef<DocumentViewerComponent>,
    // @Inject(MAT_DIALOG_DATA) data?: any){

      //}
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
  ngAfterViewInit() {}

  ngOnInit() {
    this.pdfSrc = this.fileSrc;
  }

  public testBeforePrint() {
    console.log('testBeforePrint() successfully called');

  }

  public testAfterPrint() {
    console.log('testAfterPrint() successfully called');
  }

  public testPagesLoaded(count: number) {
     //this.pdfViewer.refresh();
     this.isPdfLoaded = !this.isPdfLoaded;

    console.log('testPagesLoaded() successfully called. Total pages # : ' + count);
  }

  public testPageChange(pageNumber: number) {
    console.log('testPageChange() successfully called. Current page # : ' + pageNumber);
  }

  downloadPdfFile(): void {
   //this.pdfData.save();
       //const source = `data:` + contentType + `;base64,` + `${base64String}`;
    const link = document.createElement("a");
    link.href = this.pdfSrc.toString();
    link.download = this.pdffileName;
    link.click();
  }

  // onProgress(progressData: PDFProgressData) {
  //   // do anything with progress data. For example progress indicator
  // }

  close() {
    this.modalRef.close("Closed");
  }


  // search(stringToSearch: string) {
  //   this.pdfComponent.pdfViewer.eventBus.dispatch('find', {
  //     query: stringToSearch, type: 'again', caseSensitive: false, findPrevious: undefined, highlightAll: true, phraseSearch: true
  //   });
  // }
/**
   * Get pdf information after it's loaded
   * @param pdf pdf document proxy
   */
afterLoadComplete(pdf: PDFDocumentProxy) {
  this.pdf = pdf;

  this.loadOutline();
}

/**
 * Get outline
 */
loadOutline() {
  this.pdf.getOutline().then((outline: any[]) => {
    this.outline = outline;
  });
}

/**
 * Handle error callback
 *
 * @param error error message
 */
onError(error: any) {
  this.error = error; // set error

  if (error.name === 'PasswordException') {
    const password = prompt(
      'This document is password protected. Enter the password:'
    );

    if (password) {
      this.error = null;
      this.setPassword(password);
    }
  }
}

setPassword(password: string) {
  let newSrc: PDFSource;

  if (this.pdfSrc instanceof ArrayBuffer) {
    newSrc = { data: this.pdfSrc as any };
    // newSrc = { data: this.pdfSrc };
  } else if (typeof this.pdfSrc === 'string') {
    newSrc = { url: this.pdfSrc };
  } else {
    newSrc = { ...this.pdfSrc };
  }

  newSrc.password = password;

  newSrc.password = password;

    this.pdfSrc = newSrc;
}

/**
 * Pdf loading progress callback
 * @param progressData pdf progress data
 */
onProgress(progressData: PDFProgressData) {
  console.log(progressData);
  this.progressData = progressData;

  this.isLoaded = progressData.loaded >= progressData.total;
  this.error = null; // clear error
}
/**
   * Page rendered callback, which is called when a page is rendered (called multiple times)
   *
   * @param e custom event
   */
pageRendered(e: CustomEvent) {
  console.log('(page-rendered)', e);
}

/**
 * Page initialized callback.
 *
 * @param {CustomEvent} e
 */
pageInitialized(e: CustomEvent) {
  console.log('(page-initialized)', e);
}

/**
 * Page change callback, which is called when a page is changed (called multiple times)
 *
 * @param e number
 */
pageChange(e: number) {
  console.log('(page-change)', e);
}
}
