import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { tap } from 'rxjs';
import { UserService } from 'src/app/core/services/lmsuser.service';

@Injectable({
  providedIn:'root'
})
export class LMSAuthGuard extends KeycloakAuthGuard {
  private userService = inject(UserService);
  constructor(
    public override router: Router,
    private keycloak: KeycloakService) {
    super(router, keycloak);    
  }

  CanActivateFn = (route: ActivatedRouteSnapshot,state: RouterStateSnapshot) => {
    if (!this.authenticated) {
      this.keycloak.login().then(() => window.location.origin + state.url );
      return false;      
    }
    
    return this.canActivate(route,state);
  };

  async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (!this.authenticated) {
      // await this.keycloak.login().then(() => {
      //   //const _currentKCProfile = this.keycloak.getKeycloakInstance().profile;            
      //   //this.userService.getLMSUserProfile(_currentKCProfile?.id || this.keycloak.getKeycloakInstance().subject);
      //   window.location.origin + state.url
      // });
    }

    return new Promise<boolean>((resolve) => {
      if (this.authenticated && !this.userService?.ValidUserLoaded$) {
        this.userService?.ValidUserLoaded$.pipe(
          tap((isloggedin) => {
            if(isloggedin) { resolve(true); }

            // this.keycloak.loadUserProfile().then((profile)=> {
            //   this.userService.getLMSUserProfile(profile);
            //   resolve(true);
            // }).catch((reason) => {            
            //   reject(reason);
            // });
          })
        ).subscribe({
          next: (valid)=>{
            this.authenticated = valid;
            resolve(valid);
          }
        });
      } else {
        resolve(true);
      }
    });
    //return this.authenticated || this.userService?.isUserLoggedinValue;
  }

  // async isAccessAllowed(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Promise<boolean | UrlTree> {

  //   if (!this.authenticated && !this.userService?.isUserLoggedinValue) {
  //     //this.userService.needsLogIn(window.location.origin,// + state.url,
  //     this.keycloakAngular.login().then(() => {
  //       redirectUri: window.location.origin + state.url
  //     });
  //     this.userService.setisUserLoggedin(this.authenticated);
  //     return this.authenticated;
  //     this.navigateToURL(state.url);
  //   }

  //   return this.authenticated || this.userService?.isUserLoggedinValue;
  // }

  async navigateToURL(URL: string) {
    await this.router.navigateByUrl(URL);
  }
}
