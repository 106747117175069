<head>
  <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
  <meta http-equiv="Pragma" content="no-cache" />
  <meta http-equiv="Expires" content="0" />
</head>

  <div *ngIf="!canEdit" class="container container-md">
    <div class="idlight ms-auto me-auto rounded-1 shadow-2-strong justify-content-center my-3 p-2" style="width: 500px;">
      <form [formGroup]="employerSearchForm" >
        <div *ngIf="!canEdit && userBranch === HQ_STAFF">
          <span class="align-items-center mx-4 my-2 d-flex">
            <label for="searchBranch"><b>Filter branch name</b></label>
            <input formControlName="searchBranch"  class="form-control ms-auto me-1 w-50 rounded pull-right"
            placeholder="Branch name"  autofocus >
            <button mat-raised-button mdbBtn class="btn btn-primary btn-rounded me-1" mdbRipple color="primary"
                  (click)="findBranchesByName()">Search
                  </button>
          </span>
          <span *ngIf="(this.employerSearchForm.value.searchBranch !== ''|| userBranch === HQ_STAFF) && selectedBranch" class="mx-4 align-items-center my-2 d-flex">
              <label for="brancName"><b>Select branch</b></label>
              <select formControlName="brancName" [ngModel]="selectedBranch" class="form-control form-select ms-auto me-1 w-50 rounded pull-right">
              <option
                *ngFor="let branch of (lstBranches)"
                [ngValue]="branch">{{branch.branchName}}
              </option>
            </select>
          </span>
        </div>

        <span *ngIf="!canEdit && (this.employerSearchForm.value.searchBranch !== '' || userBranch !== HQ_STAFF) && selectedBranch" class="align-items-center mx-4 my-2 d-md-flex d-flex">
            <label for="employerName"><b>Employer name</b></label>
              <input type="search" class="form-control ms-auto me-1 w-50 pull-right rounded"
            placeholder="Employer name *" formControlName="employerName"
            (keypress)="global_utility.omit_special_char($event)"/>
              <button mat-raised-button mdbBtn class="btn btn-primary" mdbRipple color="primary"
              (click)="findEmployer()">Filter
              </button>

        </span>
      </form>
    </div>
  </div>
  <!-- <div *ngIf="!canEdit && userBranch != HQ_STAFF" class="form-container center-screen">
     <table class="table table-striped table-borderless table-light table-sm mb-2" style="width: 400px;
      margin: auto;
      text-align: left;
      vertical-align: middle;">
      <tbody>
        <tr style="white-space: nowrap;">
          <td>Employer name</td>
          <td style="width: 200px;">
            <input formControlName="employerName" placeholder="Employer name *" [(ngModel)]="employerName" required (keypress)="omit_special_char($event)">
            <button mat-raised-button class="btn btn-primary btn-rounded ms-1" mdbRipple color="primary"
            (click)="findEmployer()">Filter</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div> -->

  <ng-container class="d-block w-75" >
  <div class="center-screen" *ngIf="lstEmployers.length > 0">
    <table class="table table-striped table-hover table-sm caption-top shadow-1-strong rounded-1 detail-table p-1" style="width: 800px;" >
      <thead class="table-center-header text-nowrap" color="primary">
        <tr>
          <th scope="col"> Employer Number </th>
          <th scope="col"> Employer Name </th>
          <th scope="col"> Branch Serial </th>
          <th scope="col"> Branch Name </th>
          <th style="width: 40px;"> Edit </th>
          <th style="width: 40px;"> Delete </th>
        </tr>
      </thead>
      <tbody >
        <tr *ngFor="let employer of lstEmployers | paginate: { itemsPerPage: pageSize, currentPage: pager.currentPage };let i = index;">
          <td>
            {{employer.tnumber}}
          </td>
          <td>
            {{employer.name}}
          </td>

          <td>
            {{employer.serial}}
          </td>
          <td>
            {{employer.branchName}}
          </td>

          <td>
            <button
              mat-icon-button
              class="btn btn-primary btn-rounded"
              mdbRipple
              color="primary"
              (click)="editEmployer(employer)">
              <i class="fas fa-sm fa-fw fa-pencil"></i>
            </button>
          </td>
          <td>
            <!--removeScorecard(score.id);-->
            <button mat-icon-button
            class="btn btn-primary btn-rounded"
            mdbRipple
            color="primary" (click)="deleteEmployer(employer.id)">
              <i class="fas fa-xs fa-trash-can"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="lms-pager my-2">
      <div *ngIf="pager.pages && pager.pages?.length && pager.pages?.length > 1"
          class="btn-toolbar"
          role="toolbar"
          aria-label="LMS Pager group">
        <div class="btn-group btn-group-sm"
          role="group"
          aria-label="Move Previous group">

          <button type="button"
            class="btn btn-primary rounded-start"
            data-mdb-ripple-color="#ffffff"
            style="background-color:#3f51b5; margin: 0;"
            (click)="setPage(1)"
            [disabled]="pager.currentPage === 1">First</button>
          <button type="button"
            class="btn btn-primary rounded-0"
            data-mdb-ripple-color="#ffffff"
            style="background-color:#3f51b5; margin: 0;"
            (click)="setPage(pager.currentPage - 1)"
            [disabled]="pager.currentPage === 1">Previous</button>
        </div>
        <div class="btn-group btn-group-sm"
          role="group" aria-label="PageNumbers">
          <button *ngFor="let page of pager.pages"
            type="button"
            data-mdb-ripple-color="#7a7676"
            style="background-color:#f5f9ff; margin: 0; color: #7a7676;"
            [ngClass]="{active:pager.currentPage === page}"
            (click)="setPage(page)"
            class="btn btn-outline-secondary page-item number-item  rounded-0">{{page}}</button>
        </div>
        <div class="btn-group btn-group-sm"
          role="group"
          aria-label="Move Next group">
          <button type="button"
            class="btn btn-primary rounded-0"
            data-mdb-ripple-color="#ffffff"
            style="background-color:#3f51b5; margin: 0;"
            (click)="setPage(pager.currentPage + 1)"
            [disabled]="pager.currentPage === pager.totalPages">Next</button>
          <button type="button"
            class="btn btn-primary rounded-end"
            data-mdb-ripple-color="#ffffff"
            style="background-color:#3f51b5;margin: 0;"
            (click)="setPage(pager.totalPages)"
            [disabled]="pager.currentPage === pager.totalPages">Last</button>
        </div>
      </div>
    </div>
    <div class="card-footer center-screen">
      <button *ngIf="!canEdit" mat-raised-button
        class="btn btn-primary btn-rounded me-2" mdbRipple color="primary"
        (click)="newEmployer()">New employer</button>
    </div>
  </div>
  </ng-container>
  <div class="form-container center-screen" *ngIf="canEdit">
    <form [formGroup]="employerDetailsForm" #formDirective="ngForm">
      <div class="card">
        <div class="card-body p-3">
          <table class="table table-striped table-borderless table-sm caption-top" [style.width.px]="650">
            <caption>Employer Details</caption>
            <tbody>
              <tr>
                <td class="aligndetails">
                  <!-- Employer details-->
                  <table class="table table-striped table-bordered table-sm" >
                    <tbody class="field-4column-label">
                      <tr>
                        <td><label> Employer TNR Number </label></td>
                        <td>
                          {{employerDetailsModel.employerTNR}}
                        </td>
                      </tr>
                      <tr>
                        <td><label> Employer Name </label></td>
                        <td>
                          <input class="form-control" formControlName="employerName" placeholder="Employer Name *" toUppercase
                          (keypress)="global_utility.omit_special_char($event) "
                          [(ngModel)]="employerDetailsModel.employerName" required />
                        <div *ngIf="validateEmployerInput && validateEmployerInput.employerName!.errors?.required && submitted" class="error-label">
                          Employer name is required
                        </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Parent Company/Group</td>
                        <td>
                          <input class="form-control" formControlName="parentCompany" [(ngModel)]="employerDetailsModel.parentCompany" placeholder="Parent Company"
                          (keypress)="global_utility.omit_special_char($event)" />
                        </td>
                      </tr>

                      <tr>
                        <td>Email</td>
                        <td>
                          <input class="form-control" formControlName="workEmail" placeholder="Employer Email"
                            [(ngModel)]="employerDetailsModel.workEmail" />
                        </td>
                      </tr>

                      <tr>
                        <td>Telephone 1</td>
                        <td>
                          <input class="form-control" formControlName="tel1" placeholder="Telephone *"
                            [(ngModel)]="employerDetailsModel.tel1" required
                            maxlength="10" (keypress)="global_utility.numberOnly($event)">
                          <div *ngIf="validateEmployerInput && validateEmployerInput.tel1.errors?.required && submitted" class="error-label">
                            Employer telephone is required
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>Telephone 2</td>
                        <td>
                          <input class="form-control" formControlName="tel2" placeholder="Alternate Telephone"
                          maxlength="10" (keypress)="global_utility.numberOnly($event)"
                            [(ngModel)]="employerDetailsModel.tel2">
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td class="aligndetails">
                  <table class="table table-striped table-bordered table-sm">
                    <tbody class="field-4column-label">
                      <tr>
                        <td>Branch Name</td>
                        <td> {{employerDetailsModel.branchName}} </td>
                      </tr>

                      <tr>
                        <td>Pay Frequency</td>
                        <td>
                          <select class="form-control form-select" formControlName="wageFrequency"
                          [(ngModel)]="employerDetailsModel.wageFrequency"
                            (change)="onWageFrequencyChange($event)" required>
                            <option *ngFor="let wage of lstWageFrequency" [value]="wage.value">{{wage.description}}
                            </option>
                          </select>
                          <div *ngIf="validateEmployerInput && validateEmployerInput.wageFrequency.errors?.required && submitted" class="error-label">
                            Wage frequency is required.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Pay Date</td>
                        <td>
                          <select class="form-control form-select" formControlName="payDate" style="width: 80px;"
                            [(ngModel)]="employerDetailsModel.payDate"
                             required>
                            <option *ngFor="let pDate of payDates"
                             [value]="pDate.value">{{pDate.description}}
                            </option>
                          </select>
                          <div *ngIf="validateEmployerInput && validateEmployerInput.payDate.errors?.required && submitted" class="error-label">
                            Pay date is required
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>Shifting Rule</td>
                        <td>
                          <select class="form-control form-select" formControlName="shiftingRule"
                            placeholder="Shifting rule" required
                            [(ngModel)]="employerDetailsModel.shiftingRule">
                            <option *ngFor="let shifing of lstShiftingRule" [value]="shifing.value">{{shifing.description}}
                            </option>
                          </select>
                          <div *ngIf="validateEmployerInput && validateEmployerInput.shiftingRule.errors?.required && submitted" class="error-label">
                            Shifting rule is required
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>Pay Method</td>
                        <td>
                          <select class="form-control form-select" formControlName="paymentMethod"
                          [(ngModel)]="employerDetailsModel.paymentMethod" required>
                            <option *ngFor="let payMethod of lstPayMethod" [value]="payMethod.value">{{payMethod.description}}
                            </option>
                          </select>
                          <div *ngIf="validateEmployerInput && validateEmployerInput.paymentMethod.errors?.required && submitted" class="error-label"
                            class="error-label">
                            Salary pay method is required
                          </div>
                        </td>
                      </tr>


                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <!--  work address-->
                <td class="aligndetails">
                  <table class="table table-striped table-bordered table-sm" >
                    <caption>
                      Work Address Details
                    </caption>

                    <tbody class="field-4column-label">
                      <tr>
                        <td>Work Address 1</td>
                        <td>
                          <input class="form-control" formControlName="workAddressLine1" placeholder="Address Line 1 *" type="text" required
                            [(ngModel)]="employerDetailsModel.workAddressLine1" (keypress)="global_utility.omit_special_char($event)">
                          <div *ngIf="validateEmployerInput && validateEmployerInput.workAddressLine1.errors?.required && submitted"
                            class="error-label">
                            Work Address 1 is required
                          </div>
                        </td>

                      </tr>

                      <tr>
                        <td>Work Address 2</td>
                        <td>
                          <input class="form-control" formControlName="workAddressLine2" placeholder="Address Line 2" type="text"
                            [(ngModel)]="employerDetailsModel.workAddressLine2" (keypress)="global_utility.omit_special_char($event)">
                        </td>
                      </tr>

                      <tr>
                        <td>Work Suburb</td>
                        <td>
                          <input class="form-control" formControlName="workAddressSuburb" placeholder="Address Suburb *" type="text" required
                            [(ngModel)]="employerDetailsModel.workAddressSuburb" (keypress)="global_utility.omit_special_char($event)">
                          <div *ngIf="validateEmployerInput && validateEmployerInput.workAddressSuburb.errors?.required && submitted"
                            class="error-label">
                            Work Suburb is required
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>Work Town/City</td>
                        <td>
                          <input class="form-control" formControlName="workAddressTown" placeholder="Address Town/City *"
                            [(ngModel)]="employerDetailsModel.workAddressTown" type="text"
                            (keypress)="global_utility.omit_special_char($event)"
                            required>
                          <div *ngIf="validateEmployerInput && validateEmployerInput.workAddressTown.errors?.required && submitted"
                            class="error-label">
                            Town / City is required
                          </div>
                        </td>

                      </tr>

                      <tr>
                        <td>Work Area Code</td>
                        <td>
                          <input class="form-control" formControlName="workAddressAreaCode" required placeholder="Address Area Code"
                          required maxlength="4" (keypress)="global_utility.numberOnly($event)"
                            [(ngModel)]="employerDetailsModel.workAddressAreaCode">
                          <!-- <div *ngIf="validateEmployerInput.workAddressAreaCode.errors?.required" class="error-label">
                                Area code is required
                              </div> -->
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </td>

                <!--  postal address-->
                <td class="aligndetails">
                  <table class="table table-striped table-bordered table-sm">
                    <caption>
                          Postal Address Details
                    </caption>

                    <tbody class="field-4column-label">
                      <tr>
                        <td>Postal Address 1</td>
                        <td>
                          <input class="form-control" formControlName="postalAddressLine1" placeholder="Postal Address 1"
                            [(ngModel)]="employerDetailsModel.postalAddressLine1" type="text">
                          <!-- <div *ngIf="validateEmployerInput.postalAddressLine1.errors?.required" class="error-label">
                              Address line 1 is required
                            </div> -->
                        </td>
                      </tr>

                      <tr>
                        <td>Postal Address 2</td>
                        <td>
                          <input class="form-control" formControlName="postalAddressLine2" placeholder="Postal Address 2"
                            [(ngModel)]="employerDetailsModel.postalAddressLine2" type="text">
                        </td>
                      </tr>

                      <tr>
                        <td>Postal Town/City</td>
                        <td>
                          <input class="form-control" formControlName="postalAddressTown" [(ngModel)]="employerDetailsModel.postalAddressTown" placeholder="Postal Address Town/City" type="text"
                            (keypress)="global_utility.omit_special_char($event)">
                          <!-- <div *ngIf="validateEmployerInput.postalAddressTown.errors?.required" class="error-label">
                                Town / City is required
                              </div> -->
                        </td>
                      </tr>

                      <tr>
                        <td>Postal Area Code</td>
                        <td>
                          <input class="form-control" formControlName="postalAddressAreaCode" placeholder="Postal Area Code"
                            [(ngModel)]="employerDetailsModel.postalAddressAreaCode"
                            maxlength="4" (keypress)="global_utility.numberOnly($event)">
                          <!-- <div *ngIf="validateEmployerInput.postalAddressAreaCode.errors?.required" class="error-label">
                                Area code is required
                              </div> -->
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="card-footer center-screen">
            <button *ngIf="!canEdit" mat-raised-button
              class="btn btn-primary btn-rounded me-2"
              mdbRipple color="primary"
              (click)="newEmployer()">New employer</button>
            <button *ngIf="canEdit" mat-raised-button class="btn btn-primary btn-rounded me-2" mdbRipple color="primary"
              (click)="cancelEdit()">Cancel</button>
            <button *ngIf="canEdit" mat-raised-button class="btn btn-primary btn-rounded me-2" mdbRipple color="primary"
              (click)="saveEmployer()">Save employer</button>
          </div>
      </div>
    </form>
  </div>
