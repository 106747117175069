import { CommonModule } from "@angular/common";
import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatButtonModule } from '@angular/material/button';
import { LMSSharedUiModule } from "@lmsSharedUI";

@Component({
  selector: "app-confirm-dialog",
  standalone: true,
  template: `  <app-page-cardhead [PageName]="title" [PageHeadWidth]=600></app-page-cardhead>

  <div class="mt-3 form-container center-screen">
    <p style="font-size: 14px;">
        <b>{{message}}</b>
    </p>
    <div class="m-2 center-screen">
        <button *ngIf="!showClose" mat-raised-button class="btn btn-primary me-2" mdbRipple color="primary" (click)="onDismiss()">Cancel</button>
        <button *ngIf="!showClose" mat-raised-button class="btn btn-primary  me-2" mdbRipple color="primary" (click)="onConfirm()">Yes</button>
        <button *ngIf="showClose" mat-raised-button class="btn btn-primary  me-2" mdbRipple color="primary" (click)="onConfirm()">Close</button>
    </div>
      </div>
`,
  styleUrls: ["./confirm-dialog.component.css"],
  encapsulation: ViewEncapsulation.Emulated,
  imports: [
    CommonModule,
    LMSSharedUiModule,
    MatButtonModule
  ]
})
export class ConfirmDialogComponent {
  title: string;
  message: string;
  showClose: boolean;
  isErrorOccured: boolean;

  autoConfirm: boolean;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
  ) {
    this.title = data.title;
    this.message = data.message;
    this.showClose = data.showClose;
    this.isErrorOccured = data.isErrorOccured;
    this.autoConfirm = data.autoConfirm || false;

    if (this.autoConfirm) {
      this.dialogRef.close(true);
    }
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}

export class ConfirmDialogModel {
  constructor(
    public title: string,
    public message: string,
    public showClose: boolean,
    public isErrorOccured: boolean,
    public autoConfirm: false
  ) { }
}
