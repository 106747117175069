import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { filter, map, Observable, Subject, Subscription, take } from 'rxjs';
import { PreAuthorisationModel } from 'src/app/core/models/pre-authorisation.model';
import { QuoteStoreService } from 'src/app/core/rest-services/quote-store.service';
import { AppUtilityService } from 'src/app/app-utility.service';
import { LMSSharedUiModule } from '../shared-ui.module';
import { MatCommonModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MdbValidationModule } from 'mdb-angular-ui-kit/validation';

@Component({
  selector: 'app-lmsclient-search',
  templateUrl: './lmsclient-search.component.html',
  styleUrls: ['./lmsclient-search.component.css'],
  imports:[
    CommonModule,
    ReactiveFormsModule,
    MdbValidationModule
  ],
  standalone:true
})
export class LmsClientSearchComponent implements OnInit {
  @Input() clientSearchForm: FormGroup;
  @Output() onGetClient: EventEmitter<boolean>;

  @Input() preAuthorisationModel = new PreAuthorisationModel();

  @Output() onViewSearchedClient = new EventEmitter<any>();
  @Input() OverrideDefaultSearch = false;

  @Input() branchSerial: string;
  @Input() resetLoan: string;

  mandaterefform: FormGroup;
  formattedMessage: string;

  //preAuthorisationModel = {} as PreAuthorisationModel;
  preAuthModel$: Observable<PreAuthorisationModel>;
  subscriptions: Subscription[];

  manageEngineReference = '';
  isSearchClient = false;
  hasSearchHistory = false;
  isSubmitted = false;
  AccountHistoryListCount: number;
  tableHistory_config = {
    columns: [
      {
        key: 'quoteId',
        heading: 'Application #'
      },
      {
        key: 'idNumber',
        heading: 'Id Number'
      },
      {
        key: 'firstName',
        heading: 'Name'
      },
      {
        key: 'lastName',
        heading: 'Surname'
      },
      {
        key: 'cellNumber',
        heading: 'Cell Number'
      },
      {
        key: 'status',
        heading: 'Status',
        status: true
      },
      {
        key: 'userName',
        heading: 'User'
      },
      {
        key: 'manageEngineReference',
        heading: 'Manage Engine Reference No.'
      }
    ],
    primary_key_set: ['quoteId'],
    table_data_changer: new Subject<any>(),
    ediTable: {
      add: false,
      edit: true
    }
  };

  @Output() onAccountHistSelect = new EventEmitter<any>();

  constructor(
    public quoteStoreService: QuoteStoreService,
    private fb: FormBuilder,
    public global_utility: AppUtilityService
  ) {
    // const subscription = this.quoteStoreService.getBranchValidQuote(this.clientSearchForm.value.idNumber)
    //   .subscribe({
    //     next: (currentValue) => {
    //     },
    //     error: (returnedError) => {
    //     }
    //   });
    // this.preAuthModel$ = this.quoteStoreService.getBranchValidQuote();
    // this.subscriptions.push(subscription);
  }

  ngOnInit() {
    this.mandaterefform = this.fb.group({
      referenceNumber: '',
    });

    this.clientSearchForm = this.fb.group({
      idNumber: new FormControl('', [Validators.required, Validators.max(13)]),
    });
  }

  get idNumber() {
    return this.clientSearchForm.controls['idNumber'];
  }

  onAnimationEvent($event: any) {
    if (!this.isSearchClient) {
      return;
    }
    // in our example, toState either open or closed
    console.warn(`Animation Trigger: ${$event.triggerName}`);
  }

  //subscriptions: Subscription[] = [];

  getClient() {
    this.isSearchClient = false;
    this.isSubmitted = true;
    this.hasSearchHistory = false;
    const preAuthModel = new PreAuthorisationModel();

    const idNum = this.clientSearchForm?.controls!.idNumber?.value || '';
    const control = this.clientSearchForm?.controls['idNumber'] || undefined;

    if (idNum.length < 13 && control) {
      console.log("Invalid Id Number provided!");
      control?.setErrors({ maxlength: true });
      return;
    }

    if (!this.branchSerial || this.branchSerial.length == 0) {
      control?.setErrors({ wrongBranch: true });
      return;
    }

    if (!!this.onViewSearchedClient && this.OverrideDefaultSearch) {
      this.onViewSearchedClient.emit();
      return;
    }

    this.global_utility.startLoading('Searching client with Id: ' + idNum);
    preAuthModel.idNumber = idNum;
    this.quoteStoreService.getBranchClientQuoteDetails({ idNumber: preAuthModel.idNumber, branchSerial: this.branchSerial })
      .pipe(
        filter(validQ =>
          ['New', 'Pending', 'Active', 'Submitted', 'NuPay'].includes(validQ.loanStatus)),
        take(1),
        map((result) => {
          //next: (result: any) => {
          this.global_utility.stopLoading();
          if (!result || result.length == 0) {
            control?.setErrors({ notfound: true });
            this.isSubmitted = false;
            return;
          }

          //9511165042083
          if (result.length >= 1) {
            const validQ = result.filter((element: PreAuthorisationModel) =>
              element.loanStatus === 'New'
              || element.loanStatus === 'Pending'
              || element.loanStatus === 'Active'
              || element.loanStatus === 'Submitted'
              || element.loanStatus === 'NuPay')[0];

            if (validQ) {
              this.preAuthorisationModel = validQ;
              this.isSearchClient = true;
              this.preAuthorisationModel = { ...validQ };
              //this.quoteStoreService.setQuoteWorkbook(this.preAuthorisationModel);
            }
            else {
              this.hasSearchHistory = true;

              control?.setErrors({ notfound: true });
              this.isSubmitted = false;
            }
          }
        })
      );
    // ,error: (err) => {
    //   this.global_utility.stopLoading();
    //   this.isSubmitted = false;
    //   control?.setErrors({ notfound: true });
    // }
  }

  getClient2() {
    this.isSearchClient = false;
    this.isSubmitted = true;
    this.hasSearchHistory = false;
    const preAuthModel = new PreAuthorisationModel();

    const idNum = this.clientSearchForm?.controls!.idNumber?.value || '';
    const control = this.clientSearchForm?.controls['idNumber'] || undefined;

    if (idNum.length < 13 && control) {
      console.log("Invalid Id Number provided!");
      control?.setErrors({ maxlength: true });
      return;
    }

    if (!this.branchSerial || this.branchSerial.length == 0) {
      control?.setErrors({ wrongBranch: true });
      return;
    }

    if (!!this.onViewSearchedClient && this.OverrideDefaultSearch) {
      this.onViewSearchedClient.emit();
      return;
    }

    this.global_utility.startLoading('Searching client details.');
    preAuthModel.idNumber = idNum;
    this.quoteStoreService.getBranchClientQuoteDetails({ idNumber: preAuthModel.idNumber, branchSerial: this.branchSerial })
      .subscribe({
        next: (result: any) => {
          if (!result || result.length == 0) {
            control?.setErrors({ notfound: true });
            this.isSubmitted = false;
          }
          else {
            //9511165042083
            if (result.length >= 1) {
              const validQ = result.filter((element: PreAuthorisationModel) =>
                element.loanStatus === 'New'
                || element.loanStatus === 'Pending'
                || element.loanStatus === 'Active'
                || element.loanStatus === 'Submitted'
                || element.loanStatus === 'NuPay')[0];

              if (validQ) {
                this.preAuthorisationModel = validQ;
                this.isSearchClient = true;
                this.quoteStoreService.setQuoteWorkbook(this.preAuthorisationModel);
              }
              else {
                this.hasSearchHistory = true;

                control?.setErrors({ notfound: true });
                this.isSubmitted = false;
              }
            }
          }
          this.global_utility.stopLoading();
        },
        error: () => {
          this.global_utility.stopLoading();
          this.isSubmitted = false;
          control?.setErrors({ notfound: true });
        }
      });
  }


  viewSearchedClient(viewQuote: PreAuthorisationModel) {
    this.preAuthorisationModel = viewQuote;
    console.log("PreAuthModel [Searched]: >> ", viewQuote);
    console.log("SearchedQuote status: >> ", viewQuote.loanStatus);


    if (this.onViewSearchedClient) {
      this.onViewSearchedClient.emit(viewQuote);
    }
  }

  resetLoanApplication(preAuthData: PreAuthorisationModel) {
    console.log("preauth data --------", preAuthData);
    console.log("reference number:--------", this.mandaterefform.value.referenceNumber);

    this.global_utility.startLoading('Viewing client.');
    this.quoteStoreService.resetLoanApplication({ quoteId: preAuthData.id, userName: this.global_utility.getGlobalData('user_LoginName'), referenceNumber: this.mandaterefform.value.referenceNumber }).subscribe({
      next: (result: any) => {
        if (result) {
          this.quoteStoreService.setQuoteWorkbook(result);
          this.global_utility.stopLoading();
          this.global_utility.navigateToURL(['/agent']);
        }
      },
      error: () => {
        this.global_utility.stopLoading();
      }
    });
  }

}
