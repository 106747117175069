import { inject } from '@angular/core';
import { KeycloakService } from "keycloak-angular";
import { environment } from 'src/environments/environment';
import { UserService } from '../services/lmsuser.service';

export function initializeKeycloak(
  //keycloak: KeycloakService
) {
  // export interface KeycloakOptions {
  //   config?: string | Keycloak.KeycloakConfig;
  //   initOptions?: Keycloak.KeycloakInitOptions;
  //   enableBearerInterceptor?: boolean;
  //   loadUserProfileAtStartUp?: boolean;
  //   bearerExcludedUrls?: (string | ExcludedUrl)[];
  //   authorizationHeaderName?: string;
  //   bearerPrefix?: string;
  //   updateMinValidity?: number;
  //   shouldAddToken?: (request: HttpRequest<unknown>) => boolean;
  //   shouldUpdateToken?: (request: HttpRequest<unknown>) => boolean;
  // }
    const keycloak = inject(KeycloakService);
    const userSvc = inject(UserService);
    return () =>
      keycloak.init({
        loadUserProfileAtStartUp:true,
        config: {
        url: environment.keycloakHost + '/auth',
        realm: 'LMS',
        clientId: 'web_app',
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html'
      }
    }).then((issuccess) => {
      if(issuccess) {
        if(!keycloak.isLoggedIn){
          //keycloak.login();          
        }        
      }

      return issuccess;
    });
}

