import { Component, OnInit, inject, OnDestroy, ViewEncapsulation, Input, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { GRANTTYPE } from "src/app/core/constants";
import { Subject } from "rxjs";
import { PreAuthorisationModel } from "src/app/core/models/pre-authorisation.model";

@Component({
  selector: 'app-sassa-editor',
  templateUrl: './sassa-editor.component.html',
  styleUrls: ['./sassa-editor.component.css'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  encapsulation: ViewEncapsulation.Emulated,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true })
    }
  ]
})
export class SassaEditorComponent implements OnInit, OnDestroy {
  @Input() controlKey = '';
  parentContainer = inject(ControlContainer);
  @Input() Submitted: boolean = false;
  lstGrantType = GRANTTYPE;

  get parentFormGroup() {
    return this.parentContainer.control as FormGroup;
  }

  get f() {
    return (this.parentFormGroup.get(this.controlKey) as FormGroup);
  }

  ngOnInit() {
    this.parentFormGroup.addControl(this.controlKey
      , new FormGroup({
        grantType: new FormControl(''),
        month1: new FormControl(''),
        month2: new FormControl(''),
        month3: new FormControl(''),
      })
    );
  }
  ngOnDestroy() {
    this.parentFormGroup.removeControl(this.controlKey);
  }
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
