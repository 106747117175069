
<app-page-cardhead [PageName]="'Remove Laundering Scorecard'" [PageHeadWidth]=500 ></app-page-cardhead>

<div class="center-screen">
    <form novalidate>
        <div class="center-screen">
            <br>
            <p align="center" style="color: red; font-size: 16px;">
                <b>Are you sure you want to remove the risk score? This is permanent.</b></p>

            <table id="clientInfo" class="table table-striped table-light table-hover table-sm mb-3 caption-top" style="width:500px">
                <caption>Laundering Score Details</caption>
                <tbody>
                    <tr align="left">
                        <td style="width: 25%;">Parameter Type</td>
                        <td style="width: 25%;">{{riskScorecardInfo.parameterType}}</td>
                    </tr>
                    <tr align="left">
                        <td style="width: 25%;">Description</td>
                        <td style="width: 25%;">{{riskScorecardInfo.description}}</td>
                    </tr>

                    <tr align="left">
                        <td>Score</td>
                        <td>{{riskScorecardInfo.score}}</td>
                    </tr>
                </tbody>
            </table>
            <br>
            <button mat-raised-button class="btn btn-primary btn-rounded" mdbRipple color="primary" (click)="close()"> No </button>
            &nbsp;
            <button mat-raised-button (click)="removeScoreCardConfirmation()"
                color="warn">
                Yes
            </button>

        </div>
    </form>
</div>
