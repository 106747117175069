import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LMSAuthGuard } from 'src/app/core/guard/auth.guard';
import { RatesAndFeesComponent } from "./rates-and-fees.component";

const routes: Routes = [
  { path: '', component: RatesAndFeesComponent, canActivate: [LMSAuthGuard], pathMatch: 'prefix', data: { title: 'Products Parameters' } },
  // { path: 'login', component: LoginComponent},
  // { path: 'register', component: RegisterComponent, canActivate: [AuthGuard]},
  // { path: 'search', component: ClientSearchComponent, canActivate: [AuthGuard]}];
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RatesAndFeesRoutingModule { }
