import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { COMPLIANCEMARITALSTATUS, COMPLIANCENATIONALITY, COMPLIANCEPROVINCES } from '@lmsConstants';
import { ClientDetailsService, QuoteStoreService } from '@lmsrestServices';
import { AppUtilityService } from 'src/app/app-utility.service';
import { PreAuthorisationModel } from 'src/app/core/models/pre-authorisation.model';
import { LMSPageTitleService } from 'src/app/core/services/lmspage-headertitle.service';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor } from '@angular/common';
import { DisableControlDirective } from '../../directives/disableControl.directive';

@Component({
    selector: 'app-agent-compliance-notification-dialog',
    templateUrl: './agent-compliance-notification-dialog.component.html',
    styleUrls: ['./agent-compliance-notification-dialog.component.css'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, DisableControlDirective, NgIf, NgFor, MatButtonModule]
})
export class AgentComplianceNotificationDialogComponent implements OnInit {
  pageName ="Compliance Notification";
  clientDetails: any;
  preAuthorisationModel: PreAuthorisationModel = new PreAuthorisationModel();
  complainceForm: FormGroup;
  submitted = false;
  countryWght: number;
 // employmentWght: number;
//  productWght: number;
 // customerTypeWght: number;

 lstNationality = COMPLIANCENATIONALITY;
 lstProvinces = COMPLIANCEPROVINCES;
 lstMaritalStatus = COMPLIANCEMARITALSTATUS;

  constructor(
    private formBuilder: FormBuilder,
    public quoteStoreService: QuoteStoreService,
    private clientSearch: ClientDetailsService,
   // private complianceDialog: MatDialog
    public dialogRef: MatDialogRef<AgentComplianceNotificationDialogComponent>,
  private lmsPageTitleService: LMSPageTitleService,
  public global_utility: AppUtilityService
  ) {
    this.lmsPageTitleService.setPageTitle('Compliance Notification');

  }
   // public dialogRef: MatDialogRef<AgentComplianceNotificationDialogComponent>
    // @Inject(MAT_DIALOG_DATA) data: any) {
    //   this.clientDetails = data.client
    //  }

  ngOnInit(): void {
    this.preAuthorisationModel = this.clientSearch.clientResult;

    this.preAuthorisationModel.fullNames = this.clientSearch.clientResult.firstName;
    this.preAuthorisationModel.surname = this.clientSearch.clientResult.lastName;
    this.preAuthorisationModel.userName = this.clientSearch.clientResult.createdBy
    this.preAuthorisationModel.quoteId = this.clientSearch.clientResult.id;

    this.complainceForm = this.formBuilder.group({
      //'idNumber': [this.preAuthorisationModel.idNumber, [Validators.required]],
      //'fullNames': [this.preAuthorisationModel.firstName, [Validators.required]],
      'occupation': [this.preAuthorisationModel.occupation, [Validators.required]],
      'maritalStatus': [this.preAuthorisationModel.maritalStatus, [Validators.required]],
      'nationality': [this.preAuthorisationModel.nationality, [Validators.required]],
      //'title': [this.preAuthorisationModel.title, [Validators.required]],

      'homeAdressLine1': [this.preAuthorisationModel.homeAdressLine1, [Validators.required]],
      'homeAdressLine2': [this.preAuthorisationModel.homeAdressLine2],
      'homeAdressSuburb': [this.preAuthorisationModel.homeAdressSuburb, [Validators.required]],
      'homeAdressAreaCode': [this.preAuthorisationModel.homeAdressAreaCode, [Validators.minLength(4)]],
      'homeAdressProvince': [this.preAuthorisationModel.homeAdressProvince, [Validators.required]],
      'homeAdressTown': [this.preAuthorisationModel.homeAdressTown, [Validators.required]],
      'gender': [this.preAuthorisationModel.gender, [Validators.required]]

    });

  }

  onNationalitySelect(event: any) {
    if (event.target.value == 'OTHER') {
      console.log(event.target.value);

      this.clientSearch.findNationalCountries().subscribe(
        (res: any) => {
          this.lstNationality = res;
        })
    } else if (event.target.value == 'South Africa') {
      this.preAuthorisationModel.riskCountryValue = 'South Africa';
      this.preAuthorisationModel.nationality = 'South Africa';
      this.preAuthorisationModel.countryScore = 10 * this.countryWght;
      this.preAuthorisationModel.setTotalScore();
    } else if (event.target.value == "Botswana" ||
      event.target.value == "Lesotho" ||
      event.target.value == "Mozambique" ||
      event.target.value == "Namibia" ||
      event.target.value == "Swaziland" ||
      event.target.value == "Zimbabwe") {
      this.preAuthorisationModel.riskCountryValue = event.target.value;
      this.preAuthorisationModel.nationality = event.target.value
      this.preAuthorisationModel.countryScore = 30 * this.countryWght;
      this.preAuthorisationModel.setTotalScore();
    } else {
      console.log(event.target.value)
      let natCountry = event.target.value;
      for (const country of this.lstNationality) {
        if (event.target.value == country.countryName) {
          this.preAuthorisationModel.nationality = country.countryName;
          natCountry = country.countryName;
          console.log(natCountry, "..natCountry")
          break;
        }
      }
    }
  }

  get validateClientInput() {
    return this.complainceForm.controls;
  }

  submit(){
    this.submitted = true;
    if (this.complainceForm.valid) {
      this.dialogRef.close(this.preAuthorisationModel);
    }
  }

  close(){
    this.dialogRef.close();
  }

}
