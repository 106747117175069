import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { AppUtilityService } from "src/app/app-utility.service";
import { QuoteModel } from 'src/app/core/models/quote-model.model';
import { ReasonCodesModel } from 'src/app/core/models/reason-codes.model';
import { RejectReasonCodesService } from '../reject-reason-codes/reject-reason-codes.service';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MatButtonModule } from '@angular/material/button';
import { NgFor, NgIf } from '@angular/common';
import { DisableControlDirective } from '../../shared/directives/disableControl.directive';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { PageCardheadComponent } from '../../shared/ui/pageheader-card/pageheader-card.component';

@Component({
    selector: 'app-reject-offer',
    templateUrl: './reject-offer.component.html',
    styleUrls: ['./reject-offer.component.css'],
    standalone: true,
    imports: [PageCardheadComponent, FormsModule, ReactiveFormsModule, MdbFormsModule, DisableControlDirective, NgFor, NgIf, MatButtonModule, MdbRippleModule]
})
export class RejectOfferComponent implements OnInit {
  lstReasonCode: Array<any> = [];
  rejectOfferReasonDetailsForm: FormGroup;
  submitted = false;

  rejectOfferInfo: any;

  constructor(
    private formBuilder: FormBuilder,
    public reasonCodesService: RejectReasonCodesService,
    public modalRef: MdbModalRef<RejectOfferComponent>,
  ) {
    this.reasonCodesService.getAllRejectReasonCodes().subscribe((res: any) => {
      this.lstReasonCode = res;
    });
  }

  get rejectOfferReason() {
    return this.rejectOfferReasonDetailsForm.get('rejectOfferReason');
  }

  ngOnInit(): void {
    console.log("RejectOffer model: ", this.rejectOfferInfo);

    this.rejectOfferReasonDetailsForm = this.formBuilder.group({
      id: [this.rejectOfferInfo.quoteId],
      rejectOfferReason: [this.rejectOfferInfo.rejectOfferReason, [Validators.required]]
    });
  }

  saveRejectOfferReason() {
    this.submitted = true;
    let isValidData = this.rejectOfferReasonDetailsForm.valid;

    if (isValidData) {
      this.rejectOfferInfo.quoteId = this.rejectOfferReasonDetailsForm.get("id")?.value;
      this.rejectOfferInfo.rejectOfferReason = this.rejectOfferReasonDetailsForm.get("rejectOfferReason")?.value;
      this.modalRef.close(this.rejectOfferInfo);
    }
  }

  get validateRejectReason() {
    return this.rejectOfferReasonDetailsForm.controls;
  }

  close() {
    this.modalRef.close();
  }
}
