import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PaySlipModel } from 'src/app/core/models/pay-slip-model.model';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DisableControlDirective } from '../../shared/directives/disableControl.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PageCardheadComponent } from '../../shared/ui/pageheader-card/pageheader-card.component';

@Component({
    selector: 'app-payslip-dialog',
    templateUrl: './payslip-dialog.component.html',
    styleUrls: ['./payslip-dialog.component.css'],
    standalone: true,
    imports: [PageCardheadComponent, FormsModule, ReactiveFormsModule, MatDatepickerModule, DisableControlDirective, MatFormFieldModule, NgIf, MatButtonModule]
})

export class PayslipDialogComponent implements OnInit {
  @ViewChild('nettIncome') nettIncome: ElementRef;

  paySlipModel = {} as PaySlipModel;
  payslipForm: FormGroup;
  // grossIncome: string;
  // deductions: string;
  snettIncome: string;
  otherIncome: number = 0;
  submitted: boolean = false;
  payDateError: string;
  invalidDate: boolean = false;
  //calculateError: boolean;

  maxDate = new Date();

  constructor(
    private fb: FormBuilder,
    public toastrService: ToastrService,
    public dialogRef: MatDialogRef<PayslipDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {
    this.paySlipModel.month = data.month;
  }

  ngOnInit(): void {
    this.payslipForm = this.fb.group({
      month: [this.paySlipModel.month, [Validators.required]],
      date: [this.paySlipModel.date, [Validators.required]],
      nettIncome: [this.paySlipModel.nettIncome | 0, [Validators.required, Validators.min(0)]],
      otherIncome: [this.paySlipModel.otherIncome | 0, [Validators.min(0)]]
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  save() {
    this.submitted = true;

    if (this.payslipForm.invalid) {
      this.toastrService.warning('Please enter required payslip details', 'Income details');
    } else {
      return this.dialogRef.close(this.payslipForm.value);
    }

  }
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  isNumberKey(event: any) {
    var charCode = (event.which) ? event.which : event.charCode;
    if ((charCode >= 38 && charCode <= 57) || charCode == 43)
      return true;
    return false;
  }

  get validatePayslipInput() {
    return this.payslipForm.controls;
  }


  // payDateChange(event :any){
  //   this.nettIncome.nativeElement.focus();
  // }

}
