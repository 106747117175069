<div class="modal-content">
  <div class="modal-header">
    <div class="modal-title center-fields">
      <app-page-cardhead [PageName]=title [PageHeadWidth]=650></app-page-cardhead>
    </div>
  </div>

  <div class="modal-body myh-300" >
    <div class="card">
      <div class="card-body center-screen p-1 my-3">
        <div class="container-fluid bd-example-row text-center">
          <p>
            <b style="font-size: 14px;">{{message}}</b>
          </p>
        </div>
      </div>
    <div class="card-footer center-screen">
        <button mat-raised-button class="btn btn-primary btn-rounded m-2"
        mdbRipple color="primary" (click)="close()"> Close </button>
    </div>
  </div>
</div>
</div>
