import { Component, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AreaModel } from 'src/app/core/models/region.model';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { DisableControlDirective } from '../../../shared/directives/disableControl.directive';
import { PageCardheadComponent } from '../../../shared/ui/pageheader-card/pageheader-card.component';

@Component({
    selector: 'app-area-admin-dialog',
    templateUrl: './area-admin-dialog.component.html',
    styleUrls: ['./area-admin-dialog.component.css'],
    standalone: true,
    imports: [PageCardheadComponent, FormsModule, ReactiveFormsModule, DisableControlDirective, NgIf, MatButtonModule, MdbRippleModule]
})
export class AreaAdminDialogComponent {
  areaForm: FormGroup;
  submitted: boolean = false;
  area: any;
  areaModel: AreaModel;

  constructor(
    private dialogRef: MatDialogRef<AreaAdminDialogComponent>,
    private fb: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data: any) {

    this.areaModel = data?.area; // ? data?.area : {name: ''};

  }

  ngOnInit(): void {
    this.submitted = true;

    this.areaForm = this.fb.group({
      region:  [this.areaModel.region],
      area: [this.areaModel.name]
    });
  }

  onRegionSelect(region: any) {
    console.log(region)
  }


  get areas() {
    return this.areaForm.controls["areas"] as FormArray;
  }

  saveArea(){
    if (!this.areaForm.valid) {
      return;
    }

    this.dialogRef.close(this.areaModel);
  }

  close(){
    this.dialogRef.close();
  }

}
