import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core/services/lmsuser.service';
import { LoadingSpinService } from 'src/app/shared/loaders/spinner/loadspinner.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    standalone: true,
    imports: [NgIf]
})
export class HomeLandingComponent implements OnInit {

  isLoggedIn = this.userService.isUserLoggedinValue;

  constructor(
    public userService: UserService,
    public loaderService: LoadingSpinService
  ) { }

  ngOnInit(): void {
    this.loaderService.startLoading('CLMS loading...');
    setTimeout(() => {
      if(this.userService.isUserLoggedinValue) {
        this.loaderService.stopLoading();
      }

      //this.userService.getLMSProfileObs().subscribe((lmsuser: any) => {
        //if (lmsuser && lmsuser.id!) {
          //this.router.navigate(['/lmsuser']);

          //redirect could happen here, but for now just land on beautifull home page.

          //const component = lmsuser!.jobTitle?.component || '/';
          //this.router.navigate([component]);
       // }
    // });
      setTimeout(() => {
        //if(!this.userService.isUserLoggedinValue) {
          this.loaderService.stopLoading();
          //Retry Login?
         //}
      }, 4000);
    }, 2500);
    //this.onUserProfileLoaded();
    //this.userService.onLMSUserProfile();
  }

}
