import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RatesAdnFeesService } from '@lmsrestServices';
import { RemoveLoanTypeDialogComponent } from './remove-loan-type/remove-loan-type.component';
import { trigger, state, transition, animate, style, query, stagger, animation } from '@angular/animations';
import { RatesAndFeesModel } from "src/app/core/models/rates-and-fees-model.model";
import { LMSDialogHandlerService } from "src/app/shared/lmsdialog-handler.service";
import { AppUtilityService } from "src/app/app-utility.service";
import { AddLoanTypeComponent } from "./add-loan-type/add-loan-type.component";
import { MYBENEFIT_TIER, YESNO } from "@lmsConstants";
import { MatButtonModule } from '@angular/material/button';
import { NgFor, NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-rates-and-fees',
    templateUrl: './rates-and-fees.component.html',
    styleUrls: ['./rates-and-fees.component.css'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgFor, NgClass, NgIf, MatButtonModule]
})
export class RatesAndFeesComponent implements OnInit {

  ratesAndFeesForm: FormGroup;
  userProfile: Keycloak.KeycloakProfile = {};
  submitted: boolean = false;
  panelOpenState = false;
  myControl = new FormControl('');
  //RATES AND FEES MODEL
  ratesAndFeesModel = new RatesAndFeesModel();
  lstProductTypes: Array<any> = [];
  //lstProducts: Array<any> = [];
  // product: any;
  lmsUserProfile: any;

  constructor(
    private fb: FormBuilder,
    public ratesAndFeesService: RatesAdnFeesService,
    public global_utility: AppUtilityService,
    public lmsDialogHandler: LMSDialogHandlerService,
    private changeDetector: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.ratesAndFeesForm = this.fb.group({

      //'id': [this.ratesAndFeesModel.id, [Validators.required]],
      'code': [this.ratesAndFeesModel.code, [Validators.required]],
      'description': [this.ratesAndFeesModel.description, [Validators.required]],
      'type': [this.ratesAndFeesModel.type, [Validators.required]],
      'regulatoryType': [this.ratesAndFeesModel.regulatoryType],
      'minimumLoan': [this.ratesAndFeesModel.minimumLoan, [Validators.required]],
      'maximumLoan': [this.ratesAndFeesModel.maximumLoan, [Validators.required]],

      'interestRate1st': [this.ratesAndFeesModel.interestRate1st, [Validators.required]],
      'interestRateOther': [this.ratesAndFeesModel.interestRateOther, [Validators.required]],
      'initFeeBelow1000': [this.ratesAndFeesModel.initFeeBelow1000],
      'initFeeFirst1000': [this.ratesAndFeesModel.initFeeFirst1000],
      'initiationFee': [this.ratesAndFeesModel.initiationFee, [Validators.required]],
      'initiationCap': [this.ratesAndFeesModel.initiationCap, [Validators.required]],
      'includeVatInCalculation': [this.ratesAndFeesModel.includeVatInCalculation],

      'myBenefits': [this.ratesAndFeesModel.myBenefits, [Validators.required]],

      'factor1stLoan': [this.ratesAndFeesModel.factor1stLoan],
      'factorOtherLoan': [this.ratesAndFeesModel.factorOtherLoan],
      'factorUpTo850': [this.ratesAndFeesModel.factorUpTo850],
      'factorUpTo1000': [this.ratesAndFeesModel.factorUpTo1000],
      'initServiceFeeCap': [this.ratesAndFeesModel.initServiceFeeCap, [Validators.required]],
      'term': [this.ratesAndFeesModel.term, [Validators.required]],
      'monthlyServiceFee': [this.ratesAndFeesModel.monthlyServiceFee, [Validators.required]],
      'maxDays': [this.ratesAndFeesModel.maxDays, [Validators.required]],

      'calculationType': [this.ratesAndFeesModel.calculationType],
      'includeInInterestCalc': [this.ratesAndFeesModel.includeInInterestCalc],
      'includeVatInCalc': [this.ratesAndFeesModel.includeVatInCalc],
      'mybenefitsTier': [this.ratesAndFeesModel.mybenefitsTier]
    });

    this.findAllProducts();
  }

  public lstIncludeInCalculation = YESNO;
  public lstMyBenefitTier = MYBENEFIT_TIER;

  public lstRegulatory: Array<any> = [
    { value: "ShortTerm", description: "Short Term" },
    { value: "MediumTerm", description: "Medium Term" },
    { value: "LongTerm", description: "Long Term" },
  ];

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  findAllProducts() {
    this.ratesAndFeesService.findAllProducts().subscribe((result: any) => {
      this.lstProductTypes = result;
      this.ratesAndFeesModel = this.lstProductTypes[0];
    });
  }

  findProductByDescription(data: any) {
    for (let product of this.lstProductTypes) {
      if (product.description == data) {
        this.ratesAndFeesModel = product;
      }
    }
  }

  openAddProductDialog() {
    const dialogRef = this.global_utility.displayDialogData2(AddLoanTypeComponent, {
      backdrop: true, ignoreBackdropClick: true, keyboard: true, nonInvasive: false, animation: false,
      //modalClass: 'modal-dialog modal-lg modal-fluid modal-dialog-centered  d-flex d-flex-md',
      modalClass: 'modal modal-fluid modal-lg modal-dialog modal-dialog-centered  modal-dialog-scrollable',
      containerClass: 'frame'
    });

    dialogRef.onClose.subscribe(result => {
      //result.id = this.ratesAndFeesService.setProductData;
      //result.userName = this.userProfile.username;
      //this.ratesAndFeesService.saveProduct(this.ratesAndFeesService.setProductData).subscribe((result: any) => {
      if (result != null) {
        result.createdBy = this.global_utility.getGlobalData('userDisplay');
        result.createdOn = Date.now();

        this.ratesAndFeesService.saveProduct(result).subscribe((res => {
          this.ratesAndFeesModel = res;
          this.findAllProducts();
          console.log("Loan type created successfully...");
        }));
      }
    });
    // });
  }

  openDeleteProductDialog(rateandFee: any) {
    if (!rateandFee) {
      console.log("Nothing will be removed , nothing selected...");
      return;
    }

    const dialogRef = this.global_utility.displayDialogData2(RemoveLoanTypeDialogComponent, {
      backdrop: true, ignoreBackdropClick: true, keyboard: true, nonInvasive: false, animation: false,
      //modalClass: 'modal-dialog modal-lg modal-fluid modal-dialog-centered  d-flex d-flex-md',
      modalClass: 'modal modal-fluid modal-lg modal-dialog modal-dialog-centered  modal-dialog-scrollable',
      containerClass: 'frame',
      data: { loanTypeData: this.ratesAndFeesModel }
    });

    dialogRef.onClose.subscribe(result => {
      if (result) {
        result.id = this.ratesAndFeesService.setProductData(this.ratesAndFeesModel);
        result.userName = this.global_utility.getGlobalData('userDisplay');

        this.ratesAndFeesService.removeLoanType(this.ratesAndFeesService.getProductData()).subscribe((result: any) => {
          if (result) {
            this.lstProductTypes = result;
            console.log("Product remove successful...");
            // this.findAllProducts();
          }
        });
      }
    });
  }

  saveUpdateLoanType(product: any) {
    this.submitted = true;
    this.ratesAndFeesModel.updatedBy = this.global_utility.getGlobalData('userDisplay');

    //TODO0
    if (this.ratesAndFeesForm.valid) {
      this.ratesAndFeesService.updateProduct(this.ratesAndFeesModel).subscribe((res => {
        if (res) {
          this.ratesAndFeesService.setProductData = res;

          console.log("Product update successful...");
          this.findAllProducts();
        }
      }));
    }
  }

  get validateRatesAndFeesValues() {
    return this.ratesAndFeesForm.controls;
  }

}
