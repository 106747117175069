import { AfterViewInit, Component, HostListener, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter, fromEvent, map, merge, of, Subscription } from 'rxjs';

import { environment } from 'src/environments/environment';
import { AppUtilityService } from "./app-utility.service";
import { QuoteChangedService } from './core/services/changed-service.service';
import { UserService } from "./core/services/lmsuser.service";
import { PageCardheadComponent } from './shared/ui/pageheader-card/pageheader-card.component';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
//import { LMSPageTitleService } from './core/services/lmspage-headertitle.service';
//import { SwitchModeService } from './core/services/switch-mode.service';
//import { LoadingSpinService } from "./_services/loadspinner.service";


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: true,
    imports: [NavbarComponent, NgIf, NgClass, PageCardheadComponent, RouterOutlet, AsyncPipe]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  public global_utilities: AppUtilityService = inject(AppUtilityService);

  networkStatus = false;
  environmentName: string;
  public getScreenWidth: any;
  public getScreenHeight: any;
  subscriptions: Subscription[] = [];
  is_loader_showing = false;
  Page_Title = '';
  isPdfLoaded = false;
  zoom = 'auto';
  pdffileName: string;
  page =1;
  public isLoggedIn = inject(UserService).isUserLoggedin$;

  isOffline: boolean;
  constructor(private changesService: QuoteChangedService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }
  title = 'CLMS-client';

  ngOnInit() {
    // this._checkNetworkStatus();
    this.environmentName = environment.name;

    const pageTitle_sub = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      const rt = this._getChild(this.activatedRoute);
      //let hname = rt.lmsPageHead ? rt.lmsPageHead() : 'LMS Page Header Title';
      //this.lmsPageTitleService.setPageTitle(hname);
      rt.data.subscribe(data => {
        this.Page_Title = data.title;
        setInterval(() => {
          const updated_elsewhere = this.global_utilities.getGlobalData('page_title');
          if (data && updated_elsewhere && data.title != updated_elsewhere) {
            this.Page_Title = updated_elsewhere;
            this.global_utilities.updatePageTitle(updated_elsewhere);
          }
        }, 1000);
      });
    });

    this.subscriptions.push(pageTitle_sub);
  }

  private _getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this._getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  ngAfterViewInit() {
    const loader_control = this.global_utilities.getGlobalData('loading_animation_control');
    const loading_animation_control_sub = loader_control.subscribe(
      (to_show: any) => {
        // Show if the loader is not being shown already
        if (to_show && !this.is_loader_showing) {
          this.is_loader_showing = true;
        }
        // Hide if the loader is being shown and there is no ongoing service call in next few seconds
        if (!to_show && this.is_loader_showing) {
          const ongoing_call_check_interval = setInterval(
            () => {
              const ongoing_call_count = this.global_utilities.getGlobalData('ongoing_request_count');
              if (ongoing_call_count == 0) {
                this.is_loader_showing = false;
                clearInterval(ongoing_call_check_interval);
              }
            }, 1000
          );
        }
      }
    );
    this.subscriptions.push(loading_animation_control_sub);
  }

  // To check internet connection stability
  private _checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    const networkStatusSub = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        if (status) {
          //this.loaderService.startLoading('CLMS ', false);
        } else {
          //this.loaderService.stopLoading();
        }
        console.log('status', status);
        this.networkStatus = status;
      });
    this.subscriptions.push(networkStatusSub);
  }

  ngOnDestroy(): void {
    this.global_utilities.unsubscribeAll(this.subscriptions);
  }

  @HostListener('window: beforeunload', ['$event'])
  public onBeforeUnload(): void {
    this.changesService.NavigationAllowed(true).then(
      (isNavigationAllowed: boolean) => {
        if (event && !isNavigationAllowed) {
          event.preventDefault();
          event.returnValue = false;
        }
      });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }

}
