import { HttpContextToken, HttpEvent, HttpEventType, HttpHandler, HttpHandlerFn, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { Observable, of, throwError, TimeoutError } from 'rxjs';
import { catchError, tap, timeout } from 'rxjs/operators';
import { LoadingSpinService } from "src/app/shared/loaders/spinner/loadspinner.service";
import { ErrorhanderService } from "../services/error-handler.service";

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');
export const DEFAULT_TIMEOUTCTX = new HttpContextToken<number>(() => 150000);

export function TimeoutInterceptorFn(req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
  const reqWithHeader = req.clone({
    headers: req.headers.delete('timeout'),
  })

  if(reqWithHeader.headers.has('timeout')) {
    console.error('TIMEOUT FAIL for', req.url);
  }
  console.warn('Timeout Handled >> ', req.url);
  const timeoutValue = req.context.get(DEFAULT_TIMEOUTCTX);
  return next(reqWithHeader).pipe(timeout(timeoutValue));
}

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  constructor(
    public loaderService: LoadingSpinService,
    public errorService: ErrorhanderService,
    private toastrService: ToastrService,
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeoutValue = Number(req.headers.get('timeout')) || this.defaultTimeout;

    if(req.headers.has('timeout')) {
      req.headers.delete('timeout');
    }

    return next.handle(req).pipe(
      timeout(timeoutValue),
      catchError((returnedError) => {
        //if (!returnedError.url?.endsWith('doLoanCreate')) { }
        if (returnedError instanceof TimeoutError) {
          this.toastrService.warning('Timeout occured', `LMS Request Time-Out`, { positionClass: 'toast-top-center', disableTimeOut: true, closeButton: true, progressAnimation: 'increasing' });
          //this.errorService.handleOffline(returnedError.message, 'Server not responding');
          this.loaderService.stopLoading();

          return throwError(() => {
            const error = new Error(returnedError.message);
            this.errorService.handle(error);
            return error;
          });
        }
        else {
          return of(returnedError);
        }
      })
    );
  }
}
