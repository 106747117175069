import { ZARcurrencyPipe } from 'src/app/shared/pipes/zarcurrency.pipe';
import { InternalloanModel } from "./internalloan.model";


export type ClientType = 'salaried' | 'sassa' | 'pensioner';
export class PreAuthorisationModel {
  id: number;
  quoteId: number;
  idNumber: string;
  cellNumber: string;
  fullNames: string;
  firstName: string;
  lastName: string;
  surname: string;
  nationality: string;
  countryBirth: string;
  maritalStatus: string;
  gender: string;
  occupation: string;
  emailAddress: string;
  workSaturday: boolean;
  doUpdateEmployment = false;


  homeAdressLine1: string;
  homeAdressLine2: string;
  homeAdressSuburb: string;
  homeAdressTown: string;
  homeAdressAreaCode: string;
  homeAdressProvince: string;

  //addressModel: AddressModel = new AddressModel();
  externalLoans: Array<any> = [];
  existingDebtObligations: Array<any> = [];
  internalLoans: InternalloanModel[] = [];

  finSavings: string; // Possible depricated
  accNumber: string; // Possible depricated
  clientNumber: string;
  clientType = 'Salaried';

  loanReason: string;
  loanPurpose?: string;
  loanAmount: number;
  contactPreference: string;

  sourceOfWealth: string;
  sourceOfWealthOther: string;

  marketingOptions: Array<string> = [];
  selectedMarketingOptions: string;

  additionalIncome: Array<string> = [];
  selectedadditionalIncome: string;
  additionalIncomeOther: string;

  industryType: string;
  industryTypeOther: string;
  employer: string;
  employerName?: string;
  employerTNR: number;
  employmentType: string;
  employmentStartDate!: string;
  employmentEndDate: string;
  contractStartDate: string;
  contractEndDate: string;
  wageFrequency!: string;
  grantType: string;
  month1 = 0;
  month2 = 0;
  month3 = 0;
  title: string;
  payslips?: any[];
  payslip1: any;
  payslip2: any;
  payslip3: any;
  employment: any;
  schoolfees: number;
  //bankCharges: number;
  municipalServices: number;
  groceries: number;
  transport: number;
  housing: number;
  //clothing: number;
  insurancePremiums: number;
  phone: number;
  //pocketMoney: number;
  // microLoans: string;
  // hpInstalments: string;
  // currentLoans: string;

  schoolfeesReason: string;
  //bankChargesReason: string;
  municipalServicesReason: string;
  groceriesReason: string;
  transportReason: string;
  housingReason: string;
  //clothingReason: string;
  insurancePremiumsReason: string;
  phoneReason: string;
  //pocketMoneyReason: string;
  // microLoansInfo: string;
  // hpInstalmentsInfo: string;
  // currentLoansInfo: string;

  referenceNumber: string;
  term: number;
  outstandingBalance: number | ZARcurrencyPipe;
  canSettle: boolean;
  instalment: number | ZARcurrencyPipe;
  thirdPartyName: string;
  paidUp: boolean;

  bureauConsent: string;
  canUseInformation: string;
  employerConsent: string;
  underDebtReview: string;
  placedUnderDebtReview!: string | boolean;
  underSequestration: string;
  underAdministration: string;
  placedUnderAdministration!: string | boolean;

  otp: string;
  otpSms: string;
  otpOverride: string;
  consentOtp: string;
  employmentOtp: string;

  status: string;
  clientStatus: string;
  loanStatus: string;

  entityScore = 0;
  productScore = 0;
  customerScore = 0;
  countryScore = 0;
  riskScore: number;
  riskEntityValue: string;
  riskProductValue: string;
  riskCustomerValue: string;
  riskCountryValue: string;

  firstPaymentDate: string;
  payDate: string;
  complianceStatus: string;

  productType: number;
  customerType: number;
  countryBirthType: number;
  entityType: number;

  moneyLaunderingScore: number;

  totalScore = 0;
  userName: any;
  createdBy?: string;
  userBranchSerial: any;
  branchName: string;
  affordability: any;
  excludedAffordability = 0;
  excludedInstallment = 0;
  biometrics: Array<any> = [];
  cellNoExist: string;
  bmUserName: string;
  selectedFinger: string;
  skipReason: string;
  biometricsVerified: boolean;
  verifiedMessage: string;
  incomeExpanded = false;
  setTotalScore() {
    this.totalScore = 0;

    this.totalScore = parseFloat(this.countryScore.toString())
      + parseFloat(this.entityScore.toString())
      + parseFloat(this.productScore.toString())
      + parseFloat(this.customerScore.toString());
  }
}
