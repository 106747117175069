import { MdbRadioModule } from 'mdb-angular-ui-kit/radio';
import { Component, Inject } from '@angular/core';
import {  ReactiveFormsModule } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatRadioModule } from "@angular/material/radio";
import {  PageCardheadComponent } from "@lmsSharedUI";
import { PreAuthorisationModel } from "src/app/core/models/pre-authorisation.model";
import { MatButtonModule } from "@angular/material/button";

@Component({
  selector: 'app-switchdata',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatRadioModule,
    MatButtonModule,
    PageCardheadComponent,
    MdbRadioModule
  ],
  templateUrl: './switchdata.component.html',
  styleUrls: ['./switchdata.component.css'],
})
export class SwitchdataComponent {
  preAuthModel = {} as PreAuthorisationModel;
  quoteWorkBook: any;

  selectedmodel: number;
  constructor(
    public dialogRef: MatDialogRef<SwitchdataComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
    //private clientSearch: ClientDetailsService
  ) {
    this.selectedmodel == 0;
    this.quoteWorkBook = data.quoteWorkBook;
    this.preAuthModel = data.PreAuthModel;
  }

  continueApplication() {
    this.dialogRef.close(this.selectedmodel);
  }
  close() {
    this.dialogRef.close(); //this.postalDetailsForm.value??
  }
}
